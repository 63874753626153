<template>
  <div class="home">
    <div style="width: 100%;position: relative;overflow:hidden">
      <el-carousel class="banner-height" :height="isPhone ? '':'766px'">
        <el-carousel-item v-for="item in (isChinese() ? bannerList : bannerEnList)" :key="item">
          <el-image class='banner-img' :src="item" fit="contain"/>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!--    <el-image class='banner-img' :src="bannerUrl" :fit="'contain'"></el-image>-->

    <item-title :item="showItem[0]"/>
    <div class="item-container">
      <div class="item-container_row" v-for="item in advantageItem" :key="item">
        <div class="item-container_col container-center" v-for="col in item" :key="col">
          <template v-if="isChinese()">
            <el-image class="item-container_icon" :src="col.icon"></el-image>
            <div v-html="col.title" class="item-container_title"></div>
            <div v-html="col.context" class="item-container_context"></div>
          </template>
          <!-- 电脑端 英文版-->
          <template v-else>
            <div class="en-home-container" style="display: flex">
              <div class="img-container" style="display: flex">
                <el-image class="item-container_icon img" :src="col.icon"></el-image>
              </div>
              <div class="content-container">
                <div v-html="col.title" class="item-container_title title"></div>
                <div v-html="col.context" class="item-container_context context"></div>
              </div>
            </div>


          </template>


          <!-- 手机端 -->
          <!-- <el-image class="p_item-container_icon" :src="col.icon"></el-image>
          <div v-html="col.title" class="p_item-container_title"></div> -->

          <div :class="'p_item ' + (col.color ? 'bg-color-' + col.color : '')">
            <img class="p_item-container_icon" :src="col.icon"/>
            <div class="p_item-container_title" v-html="col.title"></div>
          </div>
        </div>
      </div>
    </div>

    <item-title :item="showItem[1]"/>
    <div class="solution-container">
      <div class="solution-item" v-for="item in (isChinese() ? solutionItem: solutionEnItem)" :key="item">
        <el-image class="solution_img" :src="item.img" :fit="'contain'"></el-image>
        <div class="img-mask" @click="toProduct(item)">
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>

    <item-title :item="showItem[2]"/>
    <div class="container-center partner-img">
      <el-image :src="showItem[2].cnt" :fit="'contain'"></el-image>
    </div>


  </div>
</template>

<script>
import ItemTitle from "../components/ItemTitle"
import bus from '@/bus'
import {isChinese} from "@/utils";

export default {
  name: 'home',
  components: {
    ItemTitle
  },
  data() {
    return {
      // bannerUrl: process.env.VUE_APP_OSS_URL + '/home_banner_1.png',
      isPhone: false,
      bannerList: [],

      bannerEnList: [],

      showItem: [],

      advantageItem: [
        [{
          icon: require('../assets/home/cloud_monitor.png'),
          title: this.$t('home.param_4'),
          context: this.$t('home.param_5')
        }, {
          icon: require('../assets/home/smart_operation.png'),
          title: this.$t('home.param_6'),
          context: this.$t('home.param_7')
        }], [{
          icon: require('../assets/home/cloud_op.png'),
          title: this.$t('home.param_8'),
          context: this.$t('home.param_9')
        }, {
          icon: require('../assets/home/vip_sys.png'),
          title: this.$t('home.param_10'),
          context: this.$t('home.param_11')
        }], [{
          icon: require('../assets/home/cloud_mgm.png'),
          title: this.$t('home.param_12'),
          context: this.$t('home.param_13')
        }, {
          icon: require('../assets/home/channel.png'),
          title: this.$t('home.param_14'),
          context: this.$t('home.param_15')
        }], [{
          icon: require('../assets/home/smart_marketing.png'),
          title: this.$t('home.param_16'),
          context: this.$t('home.param_17')
        }, {
          icon: require('../assets/home/friendly_ui.png'),
          title: this.$t('home.param_18'),
          context: this.$t('home.param_19')
        }]
      ],

      solutionItem: [
        {
          linkIdx: 0,
          img: process.env.VUE_APP_OSS_URL + '/home/solution_7.png',
          title: this.$t('home.param_20')
        }, {
          linkIdx: 1,
          img: process.env.VUE_APP_OSS_URL + '/home/solution_8.png',
          title: this.$t('home.param_21')
        }, {
          linkIdx: 6,
          img: process.env.VUE_APP_OSS_URL + '/home/solution_9.png',
          title: this.$t('home.param_22')
        }, {
          linkIdx: 3,
          img: process.env.VUE_APP_OSS_URL + '/home/solution_10.png',
          title: this.$t('home.param_23')
        }, {
          linkIdx: 4,
          img: process.env.VUE_APP_OSS_URL + '/home/solution_11.png',
          title: this.$t('home.param_24')
        }, {
          linkIdx: 5,
          img: process.env.VUE_APP_OSS_URL + '/home/solution_12.png',
          title: this.$t('home.param_25')
        }
      ],
      solutionEnItem: [
        {
          linkIdx: 0,
          img: process.env.VUE_APP_OSS_URL + '/home/en_solution/solution_1.png',
          title: 'Fleet Solutions'
        }, {
          linkIdx: 1,
          img: process.env.VUE_APP_OSS_URL + '/home/en_solution/solution_2.png',
          title: 'Highways Service Area Solutions'
        }, {
          linkIdx: 2,
          img: process.env.VUE_APP_OSS_URL + '/home/en_solution/solution_3.png',
          title: 'Ride Hailing Solutions'
        }, {
          linkIdx: 3,
          img: process.env.VUE_APP_OSS_URL + '/home/en_solution/solution_4.png',
          title: 'Gas Station Solutions'
        }, {
          linkIdx: 4,
          img: process.env.VUE_APP_OSS_URL + '/home/en_solution/solution_5.png',
          title: 'Commercial Real Estate Solutions'
        }, {
          linkIdx: 5,
          img: process.env.VUE_APP_OSS_URL + '/home/en_solution/solution_6.png',
          title: 'Photovoltaic-Energy storage-Charging'
        }
      ]
    }
  },
  created() {
    this.init()
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    bus.on('SCREEN_WIDTH_CHANGE', key => {
      this.isPhone = key <= 600
      if (this.isPhone) {
        this.bannerList = [
          process.env.VUE_APP_OSS_URL + '/home/phone/home_banner.png'
        ]

        this.bannerEnList = [
          process.env.VUE_APP_OSS_URL + '/home/phone/banner_4.png',
          process.env.VUE_APP_OSS_URL + '/home/phone/banner_3.png',
          process.env.VUE_APP_OSS_URL + '/home/phone/banner_2.png',
          process.env.VUE_APP_OSS_URL + '/home/phone/banner_1.png'
        ]


        this.showItem = [
          {
            icon: require('../assets/home/phone/home_advantage.png'),
            title: this.$t('home.param_1')
          }, {
            icon: require('../assets/home/phone/home_solution.png'),
            title: this.$t('home.param_2')
          }, {
            icon: require('../assets/home/phone/home_partner.png'),
            title: this.$t('home.param_3'),
            cnt: isChinese() ? process.env.VUE_APP_OSS_URL + '/home/phone/partner_list_1.png' : process.env.VUE_APP_OSS_URL + '/home/phone/partner_list_en.png'
          }
        ]

        this.advantageItem = [
          [{
            icon: require('../assets/home/cloud_monitor.png'),
            title: this.$t('home.param_4'),
            color: 'EBF4F0'
            // context: '实时状态监控、即时告警通知、<br />可视化趋势呈现'
          }, {
            icon: require('../assets/home/cloud_op.png'),
            title: this.$t('home.param_8'),
            color: 'F4EBEB'
            // context: '桩配置远程管理、设备批量远程升级、<br />全自动定时启停任务'
          }, {
            icon: require('../assets/home/cloud_mgm.png'),
            title: this.$t('home.param_12'),
            color: 'F4F2EB'
            // context: '云端场站功率智能控制、PC端/手机端多形式展现、<br />黑/白名单+停车超时组合管控'
          }, {
            icon: require('../assets/home/smart_marketing.png'),
            title: this.$t('home.param_16'),
            color: 'EBEFF4'
            // context: '预付费/后付费定制结算、优惠券/充送/服务费<br />折扣等营销工具、对接主流互联网充电服务平<br />台共享推广'
          }], [{
            icon: require('../assets/home/smart_operation.png'),
            title: this.$t('home.param_6'),
            color: 'F4F2EB'
            // context: '即时告警通知、智能运维工单系统、巡检到期<br />自动提醒、运维考勤系统'
          }, {
            icon: require('../assets/home/vip_sys.png'),
            title: this.$t('home.param_10'),
            color: 'EBEFF4'
            // context: '针对VIP客户、大企业客户，为其量身定做开发了子<br />系统，管理员可以在子系统进行在线充值、对账，<br />人员车辆管理等操作'
          }, {
            icon: require('../assets/home/channel.png'),
            title: this.$t('home.param_14'),
            color: 'EBF4F0'
            // context: '既可以分享平台的站点到其他互联网平台；<br />也可以将其他运营商平台的站点纳入到系统中来'
          }, {
            icon: require('../assets/home/friendly_ui.png'),
            title: this.$t('home.param_18'),
            color: 'F4EBEB'
            // context: '简单、人性化的交互界面设计，符合用户使用<br />习惯，上手即可懂、即可操作'
          }]
        ]

        this.solutionItem = [
          {
            linkIdx: 0,
            img: process.env.VUE_APP_OSS_URL + '/home/solution_1.png',
            title: this.$t('home.param_20')
          }, {
            linkIdx: 6,
            img: process.env.VUE_APP_OSS_URL + '/home/solution_3.png',
            title: this.$t('home.param_22')
          }, {
            linkIdx: 4,
            img: process.env.VUE_APP_OSS_URL + '/home/solution_5.png',
            title: this.$t('home.param_24')
          }, {
            linkIdx: 1,
            img: process.env.VUE_APP_OSS_URL + '/home/solution_2.png',
            title: this.$t('home.param_21')
          }, {
            linkIdx: 3,
            img: process.env.VUE_APP_OSS_URL + '/home/solution_4.png',
            title: this.$t('home.param_23')
          }, {
            linkIdx: 5,
            img: process.env.VUE_APP_OSS_URL + '/home/solution_6.png',
            title: this.$t('home.param_25')
          }
        ]
      } else {
        this.init()
      }
    })
  },
  methods: {
    isChinese,
    init() {
      this.bannerList = [
        process.env.VUE_APP_OSS_URL + '/home_banner_1.png',
      ]

      this.bannerEnList = [
        process.env.VUE_APP_OSS_URL + '/banner/banner_1.png',
        process.env.VUE_APP_OSS_URL + '/banner/banner_2.png',
        process.env.VUE_APP_OSS_URL + '/banner/banner_3.png',
        process.env.VUE_APP_OSS_URL + '/banner/banner_4.png',
      ]

      this.showItem = [
        {
          icon: require('../assets/home/home_advantage.png'),
          title: this.$t('home.param_1')
        }, {
          icon: require('../assets/home/home_solution.png'),
          title: this.$t('home.param_2')
        }, {
          icon: require('../assets/home/home_partner.png'),
          title: this.$t('home.param_3'),
          cnt: isChinese() ? process.env.VUE_APP_OSS_URL + '/home/partner_list_1.png' : process.env.VUE_APP_OSS_URL + '/home/partner_list_en.png'
        }
      ]

      this.advantageItem = [
        [{
          icon: require('../assets/home/cloud_monitor.png'),
          title: this.$t('home.param_4'),
          context: this.$t('home.param_5')
        }, {
          icon: require('../assets/home/smart_operation.png'),
          title: this.$t('home.param_6'),
          context: this.$t('home.param_7')
        }], [{
          icon: require('../assets/home/cloud_op.png'),
          title: this.$t('home.param_8'),
          context: this.$t('home.param_9')
        }, {
          icon: require('../assets/home/vip_sys.png'),
          title: this.$t('home.param_10'),
          context: this.$t('home.param_11')
        }], [{
          icon: require('../assets/home/cloud_mgm.png'),
          title: this.$t('home.param_12'),
          context: this.$t('home.param_13')
        }, {
          icon: require('../assets/home/channel.png'),
          title: this.$t('home.param_14'),
          context: this.$t('home.param_15')
        }], [{
          icon: require('../assets/home/smart_marketing.png'),
          title: this.$t('home.param_16'),
          context: this.$t('home.param_17')
        }, {
          icon: require('../assets/home/friendly_ui.png'),
          title: this.$t('home.param_18'),
          context: this.$t('home.param_19')
        }]
      ]
    },
    toProduct(item) {
      bus.emit('HOME_SELECT_SOLUTION', JSON.stringify(item))
    },

    phone() {

    },

    web() {

    }
  }
}
</script>

<style  scoped>
.home, .banner-img {
  width: 100%;
}

.en-home-container {
  display: flex !important;
}
.img-container {
  display: flex !important;
}

.item-container {
  width: 100%;
  /* height: 636px; */
  background: #EBF4F0;
  //display: -webkit-inline-box;
  //display: -ms-inline-flexbox;
  //display: inline-flex;
  //flex-direction: column; flex-wrap: wrap; align-items: center;
  /**padding: 40px 0 40px 0;**/
}

.item-container_icon {
  width: 75px;
  height: 75px;
  //float: left;
}

.item-container_title {
  //width: 84px;
  //margin-left: 27px;
  //margin-right: 17px;
  //color: #333;
  //font-size: 21px;
  //float: left;
}

.item-container_context {
  color: #646464;
  font-size: 12px;
  width: 273px;
  float: left;
}

.item-container_row {
  display: flex;
  align-items: flex-start;
  width: 99%;
  //padding: 12px 0;
}

.item-container_col {
  flex: 1;
}

.solution-container {
  margin: 0 auto;
}

.solution_img {
  margin: 14px 12px;
}

.solution-item {
  position: relative;
  display: inline-block;
}

.img-mask {
  position: absolute;
  bottom: 20px;
  margin: 0 12px;
  /* height: 57.2px; */
  background-color: #000;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* font-size: 18.76px; */
  color: #fff;
  display: none;
  cursor: pointer;
}

.solution-item:hover .img-mask {
  display: block;
}

.p_item {
  display: none;
}

.bg-color-EBEFF4 {
  background-color: #EBEFF4 !important;
}

.bg-color-F4F2EB {
  background-color: #F4F2EB !important;
}

.bg-color-F4EBEB {
  background-color: #F4EBEB !important;
}

.bg-color-EBF4F0 {
  background-color: #EBF4F0 !important;
}
</style>