<template>
    <div class="show-item">
      <div class="item-content">
        <span class="item-title-main">{{ isChinese() ? item.title : item.english }}</span>
        <span class="item-title-second" v-if="isChinese()">{{ item.english }}</span>
        <div class="item-bottom_line" :style="{ marginLeft: marginLeft + 'px' }"></div>
      </div>
    </div>
</template>

<script>
import {isChinese} from "../utils";

export default {
    name: 'show-item-left',
  methods: {isChinese},
    props: {
        marginLeft: {
          type: Number,
          default() {
            return 30
          }
        },
        item: {
            type: Object,
            default() {
                return {
                    icon: '',
                    title: ''
                }
            }
        }
    }
}
</script>

<style scoped>
.show-item {
    position: relative;
    height: 1px;
    width: 100%;
    margin: 60px 0;
}

.item-content {
    transform: translateY(-50%);
    position: absolute;
    background-color: #fff;
    font-weight: 500;
    color: #303133;
    font-size: 14px;
}

.item-title-main {
    width: 210px;
    height: 30px;
    font-size: 30px;
    font-family: MF FangHei (Noncommercial);
    font-weight: 400;
    color: #444;
    line-height: 18px;
}

.item-title-second {
    width: 191px;
    height: 19px;
    font-size: 30px;
    font-family: Candara;
    font-weight: 400;
    color: #6C6C6C;
    line-height: 18px;margin-left: 20px;
}

.item-bottom_line {
  float: left;
  width: 30%;
  height: 5px;
  border-top: 3px solid #56BE8E;
  /* margin-left: 10%; */
  /* margin-left: 30px; */
  margin-top: 2px;
}

</style>