<template>
  <div class="product-en-phone-container">
    <!--第一个产品-->
    <div class="container-item">
      <div class="title">Integrated Two Connectors DC Charger</div>
      <div class="type-list">
        <div class="item">TYPE</div>
        <div class="item " :class="pChecked[0] ? 'checked' : 'unchecked'" @click="choose(0)">European standard 60KW</div>
        <div class="item " :class="pChecked[1] ? 'checked' : 'unchecked'" @click="choose(1)">European standard 120kW</div>
      </div>
      <div class="item-content">
        <el-image :src="product_1_url" fit="contain" class="img-1"/>
        <div class="right">
          <div>
            <div class="content-title">Efficient</div>
            <ul>
              <li>Multiple and simultaneous output.</li>
              <li>{{pChecked[0] ? 'Output up to 60kW in total, flexible and upgradable.' : 'Output up to 120kW in total, flexible and upgradable.'}}</li>
              <li>Wide voltage range: 200-1000V DC.</li>
            </ul>
          </div>
          <div>
            <div class="content-title">Safe</div>
            <ul>
              <li>Emergency stop button for urgent situation.</li>
              <li>Multiple protection functions e.g.overvoltage protection, short-circuit protection etc.</li>
              <li>Suitable for both indoor and outdoor use.</li>
            </ul>
          </div>
          <div>
            <div class="content-title">Convenient</div>
            <ul>
              <li>True color screen, high definition.</li>
              <li>Modular design, easy for O&M.</li>
              <li>Easy transportation and installation.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!--第二个产品-->
    <div class="container-item">
      <div class="title">Integrated Three Connectors DC Charger</div>
      <div class="type-list">
        <div class="item">TYPE</div>
        <div class="item" :class="pChecked1[0] ? 'checked' : 'unchecked'" @click="choose(0,'pChecked1')">European standard 22-60kW</div>
        <div class="item" :class="pChecked1[1] ? 'checked' : 'unchecked'" @click="choose(1,'pChecked1')">European standard 22-120KW</div>
      </div>
      <div class="item-content">
        <div class="left">
          <div>
            <div class="content-title">Efficient</div>
            <ul>
              <li>Multiple and simultaneous output.</li>
              <li>{{pChecked1[0] ? 'Output up to 60kW in total, flexible and upgradable' : 'Output up to 120kW in total, flexible and upgradable.'}}</li>
              <li>Wide voltage range: 200-1000V DC.</li>
            </ul>
          </div>
          <div>
            <div class="content-title">Safe</div>
            <ul>
              <li>Emergency stop button for urgent situation.</li>
              <li>Multiple protection functions e.g.overvoltage protection, short-circuit protection etc.</li>
              <li>Suitable for both indoor and outdoor use.</li>
            </ul>
          </div>
          <div>
            <div class="content-title">Convenient</div>
            <ul>
              <li>True color screen, high definition.</li>
              <li>Modular design, easy for O&M.</li>
              <li>Easy transportation and installation.</li>
            </ul>
          </div>
        </div>

        <el-image :src="product_2_url" fit="contain" class="img-1"/>
      </div>
    </div>

    <!--第三个产品-->
    <div class="container-item">
      <div class="title">AC Charger</div>
      <div class="list">
        <div class="item">European standard 7kW</div>
        <div class="item ">European standard 11kW</div>
        <div class="item ">European standard 22kW</div>
      </div>
      <div class="item-content">
        <div class="left">
          <div style="">
            <el-image :src="product_3_url" fit="contain" class="img-3"/>
          </div>

          <div>
            <div class="content-title">Intelligent</div>
            <ul>
              <li>Wireless communication(WiFi).</li>
              <li>OCPP communication. </li>
            </ul>
          </div>
          <div>
            <div class="content-title">Safety</div>
            <ul>
              <li>Residual current protection.</li>
              <li>Surge protection etc. </li>
            </ul>
          </div>
          <div>
            <div class="content-title">Flexible</div>
            <ul>
              <li>Type 2 charging cable.</li>
              <li>RFlD authentication.</li>
              <li>Wall-mount/Floor-stand installation.</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div style="display: flex;justify-content: flex-end;margin-right:10px">
            <el-image :src="product_4_url" fit="contain" class="img-4"/>
          </div>
          <div>
            <div class="content-title">Intelligent</div>
            <ul>
              <li>Wireless communication(WiFi).</li>
              <li>OCPP communication protocol with CMS. </li>
            </ul>
          </div>
          <div>
            <div class="content-title">Safety</div>
            <ul>
              <li>Residual current protection.</li>
              <li>Surge protection etc. </li>
            </ul>
          </div>
          <div>
            <div class="content-title">Flexible</div>
            <ul>
              <li>Universal Type 2 socket, Type 2 charging cable optional.</li>
              <li>RWall-mount/Floor-stand installation.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'ProductEnPhone',
  data() {
    return {
      pChecked: [true, false],
      pChecked1: [true, false],
      product_1_url: process.env.VUE_APP_OSS_URL + '/product/phone/product_1.png',
      product_2_url: process.env.VUE_APP_OSS_URL + '/product/phone/product_2.png',
      product_3_url: process.env.VUE_APP_OSS_URL + '/product/phone/product_3.png',
      product_4_url: process.env.VUE_APP_OSS_URL + '/product/phone/product_4.png',

    }
  },
  methods: {
    choose(val, type = 'pChecked') {
      if (this[type][val]) return
      this[type].fill(false)
      this[type][val] = true
    }
  },
  mounted() {
    // document.body.style.fontSize = 'calc(100vw / 18.75)'
  }
}
</script>

<style scoped>
.product-en-phone-container {
  padding: 4vw;
  margin: auto;
}

.title {
  font-weight: 400;
  font-size: 3.5vw;
  color: #333333;
  margin-top: 5.87vw;
}

.title::before {
  content: '';
  width: 3.33vw;
  height: 3.33vw;
  display: inline-block;
  margin-right: 2.67vw;
  background: #56BE8E;
  border-radius: 50%;
}

.type-list {
  display: flex;
  margin-top: 4.4vw;
}

.type-list .item {
  font-weight: 300;
  font-size: 2vw;
  padding: 1.87vw;
}

.type-list .item:first-child {

  font-weight: 300;
  font-size: 2.5vw;
  color: #FFFFFF;
  background: #56BE8E;
  border-radius: 2.13vw;
  text-align: center;
}

.type-list .item:not(:first-child) {
  margin-left: 2.67vw;
}

.checked {
  font-weight: 300;
  font-size: 1.87vw;
  color: #56BE8E;
  border: 0.13vw solid #56BE8E;
}

.unchecked {
  border: 0.13vw solid #D2D2D2;
  font-weight: 300;
  font-size: 1.87vw;
  color: #747474;
}

.item-content {
  display: flex;
  align-items: flex-start;
  margin-top: 5.33vw;
  //border-bottom: 0.13vw solid #E5E5E5;
}

.container-item:not(:last-child) {
  border-bottom: 0.13vw solid #E5E5E5;
}

.img-1 {
  width: 23.33vw;
  height: 42.67vw;
}

.right {
  margin-left: 8.27vw;
}

.right .content-title, .left .content-title {
  font-weight: 400;
  font-size: 3vw;
  color: #333333;
}

ul {
  padding-left: 3vw;
}

li {
  font-weight: 400;
  font-size: 2.5vw;
  color: #656565;
  line-height: 24px;
}


.list {
  display: flex;
  justify-content: flex-start;
  margin-top: 2.67vw;
}

.list .item {
  font-weight: 300;
  font-size: 2vw;
  color: #747474;
  padding: 1.87vw;
  border: 0.13vw solid #D2D2D2;
}

.list .item:nth-child(2) {
  margin-left: 2vw;
}
.list .item:last-child {
  margin-left: auto;
}


.img-3 {
  width: 17.33vw;
  height: 36.27vw;
}

.img-4 {
  width: 10.8vw;
  height: 35.47vw;
}
.left,.right{
  flex: 1;
}


</style>
