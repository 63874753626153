export default {
    companyName: 'Copyright of Shanghai Dingchong New Energy Technology Co., Ltd.',
    companyICP: 'Su ICPB 15008760-3',
    companyName_1: 'HT-Icharge Technology Co., Ltd',
    address: 'No. 168 Jiaotong North Road, Wujiang District, Suzhou City, Jiangsu Province',
    telPhone: 'Email: icharge@hengtonggroup.com.cn',
    record: 'Registration number'
}


