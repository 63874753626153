<template>
  <div class="story">
    <el-image class='banner-img' :src="isChinese() ? bannerUrl : bannerEnUrl" :fit="'contain'"></el-image>

    <div class="story-container">
      <div class="story-left">
        <div class="nav-menu-item" v-for="menu in navMenu" :key="menu" @click="selectMenu(menu)">
          <div :class="'menu-item-main menu-dis ' + (menu.active ? 'color-active' : 'color-333')">
            <div class="menu-item_title">{{ menu.title }}</div>
          </div>
          <div class="item-bottom_line" v-if="menu.active">
            <i class="item-bottom_line-inner"></i>
          </div>
          <div class="item-bottom_line_o" v-if="menu.active">
          </div>
        </div>
      </div>

      <div class="story-right">
        <div class="solution-title" v-html="activeMenu.cnt.title"></div>

        <template v-if="isChinese()">
          <div class="solution-img container-center">
            <el-image :src="activeMenu.cnt.img" :fit="'contain'"></el-image>
          </div>

          <div class="solution-p">
            <p v-html="p" v-for="p in activeMenu.cnt.pList" :key="p"></p>
          </div>
        </template>

        <template v-else>
          <!--Ride Hailing Solutions-->
          <template v-if="['Ride Hailing Solutions'].includes(activeMenu.title)">
            <div class="solution-p">
              <p v-html="activeMenu.cnt.pList[0]"></p>
              <p v-html="activeMenu.cnt.pList[1]"></p>
              <!--              <p v-html="p" v-for="p in activeMenu.cnt.pList" :key="p"></p>-->
            </div>
            <div style="width: 91%;margin:auto;display: flex">
              <div style="flex:1;margin-right:40px">
                <p v-html="activeMenu.cnt.pList[2]"></p>
                <p v-html="activeMenu.cnt.pList[3]"></p>
              </div>
              <el-image :src="activeMenu.cnt.img" :fit="'contain'" style="flex:0.5"/>
            </div>
          </template>
          <template v-else-if="['Gas Station Solutions'].includes(activeMenu.title)">
            <div class="solution-p">
              <p v-html="activeMenu.cnt.pList[0]"></p>
            </div>
            <div style="width: 91%;margin:auto;display: flex">
              <div style="flex:1;margin-right:40px">
                <p v-html="activeMenu.cnt.pList[1]"></p>
                <p v-html="activeMenu.cnt.pList[2]"></p>
              </div>
              <el-image :src="activeMenu.cnt.img" :fit="'contain'" style="flex:0.5"/>
            </div>
          </template>
          <template v-else>
            <div class="solution-p">
              <p v-html="p" v-for="p in activeMenu.cnt.pList" :key="p"></p>
            </div>
            <div class="solution-img container-center" style="width: 91%;margin:auto;justify-content: flex-start">
              <el-image :src="activeMenu.cnt.img" :fit="'contain'"></el-image>
            </div>
          </template>

        </template>

      </div>
    </div>

    <div class="p_story-container">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item v-for="menu in navMenu" :key="menu" :title="menu.title" :name="menu.title">
          <div class="solution-title" v-html="menu.cnt.pTitle"></div>

          <div class="solution-img">
            <el-image :src="menu.cnt.pImg" :fit="'contain'"></el-image>
          </div>

          <div class="solution-p">
            <p v-html="p" v-for="p in menu.cnt.pList" :key="p"></p>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import bus from '@/bus'
import {isChinese} from "@/utils";

let menuList = [
  {
    title: '公交场站解决方案',
    active: true,
    cnt: {
      title: '公交群充案例——<br />太原公交场站、邯郸公交场站、十堰公交场站、咸阳公交场站等',
      pTitle: '公交群充案例——太原公交场站、<br />邯郸公交场站、十堰公交场站、咸阳公交场站等',
      img: process.env.VUE_APP_OSS_URL + '/solution/gongjiao.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/gongjiao.png',
      // cntHeader: '公交车、企业班车、旅游班车、通勤车等路线较固定、集中大功率充电的场景。该类场景充电特点为夜间集中充电，白天根据运行需求可进行快速补电。',
      // solution: {

      // },
      // cntFooter: '已有的成功案例包括：太原公交场站、邯郸公交场站、咸阳公交场站、十堰公交场站、温江公交场站、广州珍宝公交场站等，单公交场站日充电量达十几万度。',
      pList: [
        '公交车、企业班车、旅游班车、通勤车等路线较固定、集中大功率充电的场景。该类场景充电特点为夜间集中充电，白天根据运行需求可进行快速补电。',
        '<strong>产品方案：</strong>推荐机型包括一机双枪、分体多枪、充电弓；',
        '<strong>平台方案：</strong>推荐SaaS服务直接接入平台，将充电平台与公交平台打通，有效统计车辆充电行驶里程变化，提前预警；为场站运营商提供充电平台管理员账号，配置公交自用功能包括：充电设备大屏实时监控、订单统计、告警管理、充电卡管理、车辆管理等，同时为公交场站对外开放提供社会分时运营方案；为公交公司等大企业客户提供企业管理平台账号，可设置组织架构、设置车辆对应的组织、路线、车队等信息、查看订单统计报表、操作在线充值等；',
        '<strong>运营方案：</strong>为商户提供可视化营收数据图表、营收数据报表，通过充电SOC限制和管理后台定时充电功能可有效避开电费高峰时段；管理后台启停减少充电工人员成本；离线卡充电，紧急情况下不影响公交正常运营；',
        '<strong>运维方案：</strong>移动端桩管家实时故障告警推送，定期巡检提醒，在线创建运维工单，帮助运维人员快速发现问题、解决问题；',
        '<strong>施工方案：</strong>根据现有停靠站或枢纽站设计满足停车需求的车位及辅助设施，设计白天大电流快速补电、夜间小电流均充的配电布局；',
        '已有的成功案例包括：太原公交场站、邯郸公交场站、咸阳公交场站、十堰公交场站、温江公交场站、广州珍宝公交场站等，公交场站日充电量达几十万度。'
      ]
    }
  }, {
    title: '物流场站解决方案',
    active: false,
    cnt: {
      title: '物流车充电案例——宿州新安速物流充电站、<br />乐山沿森物流园区充电站、雅安交建无水港物流分拨中心充电站等',
      pTitle: '物流车充电案例——宿州新安速物流充电站、<br />乐山沿森物流园区充电站、<br />雅安交建无水港物流分拨中心充电站等',
      img: process.env.VUE_APP_OSS_URL + '/solution/wuliu.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/wuliu.png',
      pList: [
        '新能源物流车具有跨省与省、城与城的长距离出行特点，需要满足大功率快速充电的需求，才能使物流车货物快速流转。',
        '<strong>产品方案：</strong>推荐机型包括一体式双枪直流充电机；',
        '<strong>平台方案：</strong>推荐SaaS服务直接接入平台，将充电平台与物流平台打通，根据车辆运行路线灵活选择充电站点及充电时间；充电平台为场站运营商提供商户管理员账号，配置功能包括：设备监控、订单统计、告警管理、车辆管理、对账管理等；可配置车辆VIN码绑定企业授信账户，实现即插即充；',
        '<strong>运营方案：</strong>为商户提供智能化充电管理服务，包括：充电设备智能监控、可视化营收数据图表、多维运营数据分析报表；',
        '<strong>运维方案：</strong>移动端桩管家实时故障告警推送，定期巡检提醒，在线创建运维工单，帮助运维人员快速发现问题、解决问题；',
        '<strong>施工方案：</strong>根据我司专业的场地选择思路，结合主要种类车辆设计相应分类停车位，灵活配置快慢充以满足该区域特征的车辆使用。',
        '已有的成功案例包括：安徽宿州新安速物流充电站、淮南市大通区快递物流园充电站、乐山沿森物流园区充电站、雅安交建无水港物流分拨中心充电站等。',
      ]
    }
  }, {
    title: '企事业单位解决方案',
    active: false,
    cnt: {
      title: '企事业单位充电站案例——阿里滨江中心充电站、<br />南充交投市委充电站、北京通州区政府充电站、苏州总工会充电站等',
      pTitle: '企事业单位充电站案例——阿里滨江中心充电站、<br />南充交投市委充电站、<br />北京通州区政府充电站、苏州总工会充电站等',
      img: process.env.VUE_APP_OSS_URL + '/solution/shiye.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/shiye.png',
      pList: [
        '企事业单位充电站，需满足企业内部员工车辆充电的需求外，还可对外开放给园区内其他企业员工车辆或满足社会车辆的充电需求。',
        '<strong>产品方案：</strong>推荐机型包括一机双枪直流充电桩，小功率交流桩；',
        '<strong>平台方案：</strong>推荐SaaS服务直接接入平台，充电平台提供商户管理员账号，配置功能包括：设备监控、订单统计、告警管理、商户会员管理、场站管理等；为对外开放提供运营方案，用户可通过任我充小程序线上结算，商户自己的收款账户可直接收款，T+0到账；提供互联互通对接服务，通过第三方引流，最大化场站利用率；',
        '<strong>运营方案：</strong>智能化充电管理和运营服务，包括：充电设备智能监控、充电用户数据分析、可视化营收数据图表、营销活动需要的相关功能等；',
        '<strong>运维方案：</strong>移动端桩管家实时故障告警推送，定期巡检提醒，在线创建运维工单，帮助运维人员快速发现问题、解决问题；',
        '<strong>施工方案：</strong>根据场内现有停车位与电房位置合理选择充电位，根据车辆使用习惯设计合理的充配电布局。',
        '已有的成功案例包括：阿里滨江中心充电站、北京通州区政府充电站、南充交投市委充电站、南充交投市政府充电站、南充交投市经信局充电站、苏州总工会充电站、宜宾市第二人民医院长宁分院充电站等。',
      ]
    }
  }, {
    title: '公共场站解决方案',
    active: false,
    cnt: {
      title: '公共充电站案例——成都五桂桥充电站、<br />成都望江宾馆充电站、合肥玉兰大道充电站、上海莘朱路得翠园路充电站等',
      pTitle: '公共充电站案例——成都五桂桥充电站、<br />成都望江宾馆充电站、合肥玉兰大道充电站、<br />上海莘朱路得翠园路充电站等',
      img: process.env.VUE_APP_OSS_URL + '/solution/public.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/public.png',
      pList: [
        '公共充电站，主要是满足C端车辆包括个人新能源车主、新能源网约车、出租车、中小型新能源货车等及时补充电量，其特点为场站客户多为散客，用户倾向于到常去的固定场站补电，对距离近、价格便宜或优惠活动较多的场站更加青睐。',
        '<strong>产品方案：</strong>推荐机型包括一机双枪直流充电桩，小功率交流桩；',
        '<strong>平台方案：</strong>推荐SaaS服务直接接入平台，为商户账号配置功能包括：设备监控、订单统计、告警管理、场站管理、用户管理、卡和车辆管理、活动和券管理等；C端用户可通过任我充小程序线上结算，支持充值到商户会员账户、即充即退，商户自己的收款账户可通过配置直付通直接收款，T+0到账；根据商户的需求，提供互联互通对接服务，通过第三方引流，提升场站利用率；',
        '<strong>运营方案：</strong>智能化充电和运营服务，包括：充电设备智能监控、可视化充电数据分析、停充超时收费功能、用户端显示场站周边服务、营销活动需要的相关功能等；',
        '<strong>运维方案：</strong>移动端桩管家实时故障告警推送，定期巡检提醒，在线创建运维工单，帮助运维人员快速发现问题、解决问题；',
        '<strong>施工方案：</strong>根据我司专业的场地选择思路，以结合停车管理系统为主的运营思路设计工程方案。',
        '已有的成功案例包括：上海莘朱路得翠园路充电站、鼎充上海朱戴路充电站、成都望江宾馆充电站、任我充成都五桂桥充电站、合肥玉兰大道充电站、任我充成都武侯区大合仓充电站等。',
      ]
    }
  }, {
    title: '商业住宅小区解决方案',
    active: false,
    cnt: {
      title: '商业住宅小区充电站案例——<br />安泰国际广场充电站、龙湖天街站、孝义嘉宇凤凰城充电站等',
      pTitle: '商业住宅小区充电站案例——安泰国际广场充电站、<br />龙湖天街站、孝义嘉宇凤凰城充电站等',
      img: process.env.VUE_APP_OSS_URL + '/solution/zhuzhai.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/zhuzhai.png',
      pList: [
        '商业住宅小区充电站，主要是满足于商业街消费者、小区业主中新能源车主的充电需求，在此基础上亦有对外开放盈利的需求，商 业区充电要求在1-3个小时内快速完成补电；住宅小区一般在夜间慢慢补电。',
        '<strong>产品方案：</strong>商业区推荐机型为一机双枪直流充电桩，住宅小区推荐小功率交流桩；',
        '<strong>平台方案：</strong>推荐SaaS服务直接接入平台，充电平台提供功能包括：设备实时监控、订单统计、告警管理、场站管理、用户管理、对账管理、报表、在线开票等；用户可通过任我充小程序、任我充APP扫码启动充电、支持一号多充、可自主操作停止充电、支付订单、在线申请开票、查看实时充电订单和历史订单等；',
        '<strong>运营方案：</strong>提供智能化充电管理服务和运营服务，包括：充电设备智能监控、可视化充电数据分析、与停车管理系统无缝对接等；',
        '<strong>运维方案：</strong>移动端桩管家实时故障告警推送，定期巡检提醒，在线创建运维工单，帮助运维人员快速发现问题、解决问题；',
        '<strong>施工方案：</strong>根据商业街或小区配电特点，结合停车管理系统为主的运营思路设计工程方案。',
        '已有的成功案例包括：安泰国际广场充电站、安泰名筑小区充电站、龙湖天街站、孝义嘉宇凤凰城充电站等。',
      ]
    }
  }, {
    title: '出租车/网约车解决方案',
    active: false,
    cnt: {
      title: '出租车/网约车充电方案',
      pTitle: '出租车/网约车充电方案',
      img: process.env.VUE_APP_OSS_URL + '/solution/wangyue.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/wangyue.png',
      pList: [
        '新能源网约车、出租车车主是公共场站的主要客户，快速补电不影响运营是其主要诉求。',
        '<strong>产品方案：</strong>推荐机型为一机双枪直流充电桩；',
        '<strong>平台方案：</strong>推荐SaaS服务直接接入平台，提供：充电设备实施监控、不同网约车企不同合约价结算、订单查询统计、互联互通引流、对账管理等；出租车或网约车可通过任我充小程序、任我充APP扫码选择使用企业授信账户开启订单；',
        '<strong>运营方案：</strong>提供智能化充电管理服务和运营服务，包括：充电设备智能监控、可视化充电数据分析、多维运营数据分析报表、与市级平台对接，让新能源出租车、网约车车主获得政府补贴；',
        '<strong>运维方案：</strong>智能运维系统，移动端桩管家实时故障告警推送，定期巡检提醒，在线创建运维工单，帮助运维人员快速发现问题、解决问题；',
        '<strong>施工方案：</strong>根据我司专业的场地选择思路，以结合停车管理系统为主的运营思路设计工程方案。',
        '已有的成功案例包括：上海莘朱路得翠园路充电站、鼎充上海朱戴路充电站、成都望江宾馆充电站、任我充成都五桂桥充电站、合肥玉兰大道充电站、任我充成都武侯区大合仓充电站等。',
      ]
    }
  }, {
    title: '大巴车解决方案',
    active: false,
    cnt: {
      title: '大巴车充电方案——苏州金龙唯亭充电站',
      pTitle: '大巴车充电方案——苏州金龙唯亭充电站',
      img: process.env.VUE_APP_OSS_URL + '/solution/bar.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/bar.png',
      pList: [
        '为企业或园区提供班车服务、旅游线路服务等的大巴车服务商，场站多为企业客户服务，企业客户有预付费和后付费等不同的结算 方式。',
        '<strong>产品方案：</strong>推荐机型为一机双枪直流充电桩；',
        '<strong>平台方案：</strong>推荐SaaS服务直接接入平台，导入车辆VIN码信息，实现即插即充，可设置企业预付费或后付费结算模式，以及设置预付费合约价、后付费结算价；推荐企业客户管理平台，企业客户可自行查看充电订单、下载充电报表、在线充值、设置授信账户、管理充电卡和车辆信息等；',
        '<strong>运营方案：</strong>智能化充电管理服务和运营服务，包括：充电设备实时监控、可视化充电数据分析、多维运营数据报表、对账管理；',
        '<strong>运维方案：</strong>智能运维系统，移动端桩管家实时故障告警推送，定期巡检提醒，在线创建运维工单，帮助运维人员快速发现问题、解决问题；',
        '<strong>施工方案：</strong>根据我司专业的场地选择思路，以结合停车管理系统为主的运营思路设计工程方案。',
        '已有的成功案例包括：苏州金龙唯亭充电站等。',
      ]
    }
  }]

// 英文
let enMenuList = [
  {
    title: 'Fleet Solutions',
    active: true,
    cnt: {
      title: 'Fleet Solutions',
      pTitle: 'Fleet Solutions',
      img: process.env.VUE_APP_OSS_URL + '/solution/gongjiao_en.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/gongjiao_en.png',
      // cntHeader: '公交车、企业班车、旅游班车、通勤车等路线较固定、集中大功率充电的场景。该类场景充电特点为夜间集中充电，白天根据运行需求可进行快速补电。',
      // solution: {

      // },
      // cntFooter: '已有的成功案例包括：太原公交场站、邯郸公交场站、咸阳公交场站、十堰公交场站、温江公交场站、广州珍宝公交场站等，单公交场站日充电量达十几万度。',
      pList: [
        'With decades of experience, HT-Icharge understands the unique charging requirements of electric. We work with you to customize solutions that maximize operational performance.',
        'HT-Icharge has established cooperative relations with a number of electric bus operating companies and logistics companies to provide fast and efficient charging services.',
        '<strong>Intelligent operation management</strong><br/>Through big data analysis and mining, establish a data center that serves the entire intelligent fleet charging platform, providing data analysis from multiple aspects such as operation, maintenance, finance, and quality, to improve operational efficiency and reduce enterprise costs.',
        '<strong>Intelligent operation without human intervention</strong><br/>Realize unmanned intelligent operation in monitoring,scheduling, safety supervision and early warning,decision-making, and line planning.',
        '<strong>Efficient operation</strong><br/>The charging platform interacts with the fleet operation scheduling system to achieve fully automated operation and management of intelligent bus scheduling and charging.',
      ]
    }
  }, {
    title: 'Highways Service Area Solutions',
    active: false,
    cnt: {
      title: 'Highways Service Area Solutions',
      pTitle: 'Highways Service Area Solutions',
      img: process.env.VUE_APP_OSS_URL + '/solution/wuliu_en.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/wuliu_en.png',
      pList: [
        'At the highway service area, electric vehicle drivers find a convenient oasis for recharging their vehicles. Drivers simply plug in their vehicles, select the desired charging level, and let the technology do its work.',
        '<strong>Convenience</strong><br/>The scenario highlights the ease of use for electric vehicle drivers. The charging stations are conveniently located and equipped with modern technology, making the charging process straightforward and efficient. Drivers can simply plug in their vehicles and let the technology handle the charging, while they enjoy the amenities of the service area.',
        '<strong>Comprehensive Charging Network</strong><br/>The highway service area demonstrates the completeness of the charging network along the highway. The charging stations are strategically placed to cover various points along the route, ensuring that electric vehicle drivers can find charging facilities wherever they need them. This comprehensive network reduces the anxiety of running out of charge during long-distance travel and enhances the reliability of electric vehicles as a viable transportation option.',
        '<strong>Seamless Experience</strong><br/>The entire charging process is designed to be seamless, minimizing any inconvenience for drivers. The infrastructure is set up to handle the charging efficiently, allowing drivers to continue their journey without significant delays. This seamless experience enhances the overall satisfaction of electric vehicle owners using the highway service area for charging.',
      ]
    }
  }, {
    title: 'Ride Hailing Solutions',
    active: false,
    cnt: {
      title: 'Ride Hailing Solutions',
      pTitle: 'Ride Hailing Solutions',
      img: process.env.VUE_APP_OSS_URL + '/solution/shiye_en.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/shiye_en.png',
      pList: [
        'Looking for a convenient and fast charging solution for your electric vehicle? Look no further! Our new high-power charging stations are the perfect solution for eco-conscious commuters. Whether you\'re a busy driver on the go or a tourist exploring the area, we\'ve got you covered.',
        'Located in prime locations throughout scenic areas, industrial parks, and commercial centers, our charging stations offer a convenient and worry-free way to power up your vehicle. With our high-power charging station, you can enjoy fast charging that gets you back on the road in no time.',
        '<strong>Intelligent charging management</strong><br/>Our charging management system is capable of real-time monitoring of vehicle battery status, automatically adjusting charging current and voltage, ensuring charging safety and battery life while achieving fast charging of the vehicle.',
        '<strong>Efficient operation</strong><br/>Equipped with high-power DC charging equipment to quickly recharge vehicles; Establish a data center that serves the entire charging platform, capture and provide data analysis from various aspects such as operation, maintenance,finance, and quality, and improve operational efficiency.',
      ]
    }
  }, {
    title: 'Gas Station Solutions',
    active: false,
    cnt: {
      title: 'Gas Station Solutions',
      pTitle: 'Gas Station Solutions',
      img: process.env.VUE_APP_OSS_URL + '/solution/public_en.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/public_en.png',
      pList: [
        'At low cost to them, partners like Chinese National Petroleum Corp, China Petrochemeical Corporation attract new and repeat EV driving customers by hosting HT-Icharge fast charging stations in their gas stations.',
        '<strong>Standardized construction</strong><br/>According to the diversified and distributed characteristics of the power system, the parallel operation and mutual independence of the charging network and distribution network ensure the safety of station lines. The company formulates comprehensive enterprise standards, site selection standards, station construction standards, and acceptance standards to standardize the station construction process from a systemic perspective.',
        '<strong>Intelligent warning fireproof cable</strong><br/>By using the warning host of the smart cable and the monitoring module in the smart cable, the temperature of the cable itself and the intermediate joint can be monitored. At the same time, the temperature of the cable laying environment and fire situation along the way can also be monitored; Through the flat or 3D visualization interface of intelligent monitoring software, real-time temperature and operating status of the internal and external lines of the building can be monitored.',
      ]
    }
  }, {
    title: 'Commercial real estate solutions',
    active: false,
    cnt: {
      title: 'Commercial real estate solutions',
      pTitle: 'Commercial real estate solutions',
      img: process.env.VUE_APP_OSS_URL + '/solution/zhuzhai_en.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/zhuzhai_en.png',
      pList: [
        'The charging in residential and commercial real estate scenarios has the characteristics of long parking time, high demand for charging during peak periods, and multiple related parties. The charging cloud platform combines charging services with parking management and safety monitoring systems, providing integrated management services for vehicles, charging piles and parking spaces',
        '<strong>Low cost construction</strong><br/>Real estate companies usually have a large number of parking lots and open spaces, which can be fully utilized to install charging piles, thereby reducing the cost of charging services.',
        '<strong>Convenient charging</strong><br/>The charging stations within real estate properties allow owners to charge more conveniently without the need to search for public or private charging stations, providing charging services for electric vehicle users.',
        '<strong>Increase real estate value</strong><br/>With the popularity and increasing demand for electric vehicles, real estate properties with charging stations will become more attractive and increase the value of the property.',
        '<strong>Advertising revenue</strong><br/>Billboards can be set up on charging stations to generate advertising revenue.'
      ]
    }
  }, {
    title: 'Photovoltaic-Energy storage-Charging',
    active: false,
    cnt: {
      title: 'Photovoltaic-Energy storage-Charging',
      pTitle: 'Photovoltaic-Energy storage-Charging',
      img: process.env.VUE_APP_OSS_URL + '/solution/wangyue_en.png',
      pImg: process.env.VUE_APP_OSS_URL + '/solution/phone/wangyue_en.png',
      pList: [
        'The system is composed of solar panels, energy storage systems and DC charging piles. The whole system is managed by the intelligent micro-grid energy management system, which meets the daily electricity consumption and vehicle charging needs of the charging station, and truly realizes the functions of peak shaving.',
        '<strong>Increase power capacity</strong><br/>Solve the problem of insufficient distribution capacity in charging stations and achieve sustainable economic benefits through photovoltaic power generation.',
        '<strong>Green electricity energy-saving</strong><br/>Based on photovoltaic, energy storage, and charging integrated systems, the use of traditional energy is reduced, polluted gas emissions are reduced, and energy utilization efficiency is improved.',
        '<strong>Intelligent power distribution</strong><br/>Through energy storage systems, the functions of energy storage and configuration optimization can be fully utilized, including valley charging and peak discharging, further reducing charging costs. Additionally, off grid operation mode can be adopted in case of power grid failure, allowing new energy vehicles to charge in emergency.',
        '<strong>EMS</strong><br/>The Energy Management System(EMS) allocates the energy of each unit in the system according to the optimal principle, and can achieve functions such as equipment monitoring, energy statistical analysis, energy management, energy storage scheduling, event alarms, and table management.'
      ]
    }
  }]

export default {
  name: 'story',
  data() {
    return {
      bannerUrl: process.env.VUE_APP_OSS_URL + '/story_banner.png',
      bannerEnUrl: process.env.VUE_APP_OSS_URL + '/solution/story_banner_en.png',

      activeMenu: isChinese() ? menuList[0] : enMenuList[0],
      navMenu: isChinese() ? menuList : enMenuList,

      activeName: ''
    }
  },
  methods: {
    isChinese,
    selectMenu(menu) {
      this.navMenu.forEach(m => m.active = false)
      menu.active = true
      this.activeMenu = menu

      this.activeName = menu.title
    }
  },
  mounted() {
    bus.on('HOME_SELECT_SOLUTION', key => {
      this.selectMenu(this.navMenu[JSON.parse(key).linkIdx])
    })
    // eslint-disable-next-line no-unused-vars
    bus.on('SCREEN_WIDTH_CHANGE', key => {
      let isPhone = key <= 600
      if (isPhone) {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/phone/story_banner_1.png'
        this.bannerEnUrl = process.env.VUE_APP_OSS_URL + '/solution/phone/story_banner_en.png'
        // this.navMenu = menuList.map(menu => {
        //   menu.cnt.title = menu.cnt.pTitle
        //   menu.cnt.img = menu.cnt.pImg
        //   return menu
        // })
      } else {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/story_banner.png'
        this.bannerEnUrl = process.env.VUE_APP_OSS_URL + '/solution/story_banner_en.png'
      }
    })
  }
}
</script>

<style scoped>
.story, .banner-img {
  width: 100%;
}

.story-container {
  margin-top: -4px !important; /* 有偏差 */
  /* width: 80%; */
  margin: 0 auto;
  position: relative;
}

.story-left {
  /* width: 255px; */
  float: left;
}

.nav-menu-item {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.menu-item-main {
  white-space: normal;
  text-align: left;
}

.menu-item_title {
  text-align: right;
  /* font-size: 24px; */
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.item-bottom_line {
  position: absolute;
  border-color: inherit;
  background-color: #56BE8E;
  height: 2px;
  bottom: 11px;
  left: 0;
  right: 0;
  margin-right: 0px;
}

.item-bottom_line-inner {
  transition-delay: 0ms;
  border-width: 0px;
  width: 0%;
  display: block;
  border-style: solid;
  border-color: inherit;
  -webkit-transition: .15s ease-out;
  transition: .15s ease-out;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 0;
}

.item-bottom_line_o {
  left: -16px;
  border-radius: 50%;
  border: 2px solid;
  border-color: #56BE8E;
  position: relative;
  z-index: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #FFF;
  -webkit-transition: .15s ease-out;
  transition: .15s ease-out;
}

/* .menu-dis {
  margin-top: 60px;
} */

.color-333 {
  color: #333;
}

.color-active {
  color: #56BE8E;
}

.story-right {
  /* padding: 60px 20px;
  margin-left: 300px; */
  border-left: 2px solid #E5E5E5;
}

.solution-title {
  /* font-size: 28px; */
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333;
  /* line-height: 48px; */
  text-align: center;
}

/* .solution-img {
  margin: 50px 0 70px;
} */

.solution-p {
  width: 91%;
  margin: 0 auto;
}

.solution-p
p {
  margin: 30px 0;
}

p {
//text-indent: 2em; text-align: justify; text-justify: inter-ideograph; /* IE */ margin: 0;
  line-height: 2em;
}

.p_story-container {
  display: none;
  width: 94%;
  margin: 0 auto;
}
</style>
