import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import messages from './index'
// import {locale} from "element-plus";
// const language = (
//     (navigator.language ? navigator.language : navigator.userLanguage) || "zh"
// ).toLowerCase();

const language = localStorage.getItem('lang') || 'zh'
const i18n = createI18n({
    fallbackLocale: 'ch',
    globalInjection:true,
    legacy: false, // you must specify 'legacy: false' option
    locale: language,
    messages,
});

export default i18n