<template>
    <div class="show-item margin-top-70">
        <el-divider class="item-line">
        <el-image class="item-icon" :src="item.icon"></el-image>

        <span class="item-title">{{ item.title }}</span>
        <div class="item-bottom_line"></div>
        </el-divider>
    </div>
</template>

<script>
export default {
    name: 'show-item',
    props: {
        item: {
            type: Object,
            default() {
                return {
                    icon: '',
                    title: ''
                }
            }
        }
    }
}
</script>

<style scoped>

.show-item {
  width: 100%;
  height: 62px;
}

.item-line {
  height: 2px;
}

.item-icon {
  width: 62px;
  height: 62px;
}

.item-title {
  color: #333;
  font-size: 25px;
  line-height: 62px;
  display: inline-block;
  overflow: hidden;
  margin-left: 22px;
}

.item-bottom_line {
  float: right;
  width: 30%;
  margin-right: 17%;
  height: 5px;
  border-top: 3px solid #56BE8E;
  margin-top: -10px;
}

.margin-top-70 {
  margin-top: 70px;
}

</style>