<template>
  <div class="product">
    <el-image class='banner-img' :src="bannerUrl" :fit="'contain'"></el-image>

    <div class="prod-container" v-for="(prod, index) in prodItem" :key="prod">
      <div class="product-item container-center" v-if="index === 0">
        <div class="img-container">
          <el-image class="one-body-img" :src="prod.img"></el-image>
        </div>
        <div class="margin-left-107">
          <div class="prod-item-title container-center-col">
            <span class="title-point"></span>
            <span class="margin-left-46">{{ prod.title }}</span>
          </div>
          
          <div class="prod-item-info margin-left-64 margin-top-53">
            <el-row>
              <div class="type-tips container-center">提供机型</div>
              <div class="type-item container-center margin-left-20 margin-right-15" v-for="type in prod.type" :key="type">{{ type }}</div>
            </el-row>

            <div v-html="prod.desc" class="item-desc margin-top-39"></div>
          </div>
        </div>
      </div>

      <div class="product-item container-center" v-else>
        <div :class="index === 4 ? 'width-100' : 'width-auto'">
          <div class="prod-item-title container-center">
            <span class="title-point"></span>
            <span class="margin-left-46">{{ prod.title }}</span>
          </div>
          
          <div class="prod-item-info margin-top-53">
            <el-row class="container-center">
              <div class="type-tips container-center">提供机型</div>
              <div class="type-item container-center margin-left-20 margin-right-15" v-for="type in prod.type" :key="type">{{ type }}</div>
            </el-row>

            <div v-html="prod.desc" class="item-desc margin-top-39 container-center"></div>
          </div>

          <el-image :class="'margin-top-20 prod-item-img-' + index" :src="prod.img" v-if='index != 4'></el-image>
          <div class="brow-img-container container-center margin-top-20" v-else>
            <el-image :class="'prod-item-img-' + index" :src="prod.img"></el-image>
          </div>
        </div>
      </div>

      <div class="divider-container container-center" v-if="index != (prodItem.length - 1)">
        <el-divider></el-divider>
      </div>
    </div>

    <!-- 手机端 -->
    <div class="p_prod-container">
      <!-- <div style="border: 1px solid red;hight:20px;"></div> -->
      <div class="prod-item" v-for="(prod, index) in prodItem" :key="prod">
        <el-card class="one-img-container" shadow="hover" v-if="index === 0">
          <el-row :gutter="24">
            <el-col :span="8">
              <div class="img-container">
                <el-image class="one-body-img" :src="prod.img"></el-image>
              </div>
            </el-col>
            <el-col class="img-context" :span="16">
              <div class="prod-item-title container-center-col">
                <span class="title-point"></span>
                <span class="margin-left-46">{{ prod.title }}</span>
              </div>
              
              <div class="prod-item-info margin-left-64 margin-top-53">
                <div v-html="prod.pDesc" class="item-desc margin-top-39"></div>
              </div>
            </el-col>
          </el-row>

          <el-row class="container-center">
            <div class="type-tips container-center">提供机型</div>
            <div class="type-item container-center margin-left-20 margin-right-15" v-for="type in prod.type" :key="type">{{ type }}</div>
          </el-row>
        </el-card>

        <el-card shadow="hover" v-else>
          <div class="prod-item-title container-center">
            <span class="title-point"></span>
            <span class="margin-left-46">{{ prod.title }}</span>
          </div>
          
          <div class="prod-item-info margin-top-53">
            <p v-html="prod.pDesc" class="item-desc margin-top-39 container-center"></p>
            
            <el-row class="container-center" v-if="index === 4">
              <div class="type-tips container-center">提供机型</div>
              <div class="type-item container-center margin-left-20 margin-right-15" v-for="type in prod.type" :key="type">{{ type }}</div>
            </el-row>
          </div>

          <el-image :class="'margin-top-20 prod-item-img-' + index" :src="prod.img" v-if='index !== 4'></el-image>

          <el-row class="container-center" v-if="index !== 4">
            <div class="type-tips container-center">提供机型</div>
            <div class="type-item container-center margin-left-20 margin-right-15" v-for="type in prod.type" :key="type">{{ type }}</div>
          </el-row>
        </el-card>
        <div class="brow-img-container container-center" v-if='index === 4'>
          <el-image :class="'prod-item-img-' + index" :src="prod.img"></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/bus'
export default {
  name: 'product',
  methods: {},
  data() {
    return {
        bannerUrl: process.env.VUE_APP_OSS_URL + '/product_banner_1.png',
        prodItem: [
          {
            img: process.env.VUE_APP_OSS_URL + '/product/one_body_1.png',
            title: '一体式双枪直流充电机',
            type: ['60kW-80kW双枪', '80kW单枪', '160kW-240kW双枪', '320kW-360kW双枪'],
            desc: '本产品主要用于电动汽车的快速充电，将充电、计费、支付、通信、\
            <br />数据收集等功能集成一个整体，人性化的界面显示和操作导引，让<br />用户简单、快捷完成充电过程',
            pDesc: '本产品主要用于电动汽车的快速充电，将充电、计费、支付、通信、\
            数据收集等功能集成一个整体，人性化的界面显示和操作导引，让用户简单、快捷完成充电过程'
          }, {
            img: process.env.VUE_APP_OSS_URL + '/product/divided_body_1.png',
            title: '分体式直流充电机',
            type: ['320kW-400kW', '200kW', '160kW-200kW', '160kW-240kW'],
            desc: '本产品主要用于对电动汽车进行快速充电的多个充电终端，进行智能化的功率分配控制。\
            <br />白天可以满足客户大功率快充补电需求，夜间最大可以一拖十群充小功率补电，无需移车。',
            pDesc: '本产品主要用于对电动汽车进行快速充电的多个充电终端，进行智能化的功率分配控制。\
            白天可以满足客户大功率快充补电需求，夜间最大可以一拖十群充小功率补电，无需移车。'
          }, {
            img: process.env.VUE_APP_OSS_URL + '/product/terminal_1.png',
            title: '充电终端',
            type: ['80A', '125A', '200A', '250A'],
            desc: '本产品系列为分体式直流充电机充电终端，主要用于电动汽车的快速充电，将充电、计费、支付、通信、\
             <br />数据收集等功能集成一个整体，人性化的界面显示和操作导引，让用户简单、快捷完成充电过程。',
            pDesc: '本产品系列为分体式直流充电机充电终端，主要用于电动汽车的快速充电，将充电、计费、支付、通信、\
             数据收集等功能集成一个整体，人性化的界面显示和操作导引，让用户简单、快捷完成充电过程。'
          }, {
            img: process.env.VUE_APP_OSS_URL + '/product/ac_evse_1.png',
            title: '交流充电机',
            type: ['7kW', '14kW', '80kW'],
            desc: '本产品主要用于电动汽车的慢速和中速充电，集成了充电、计费、支付、通信、数据收集等功能，提\
            <br />供人性化的界面显示和操作导引，具有占地少，投资小的特点，适合家用、商用等停车场充电以及特<br />殊车型交流充电。',
            pDesc: '本产品主要用于电动汽车的慢速和中速充电，集成了充电、计费、支付、通信、数据收集等功能，提\
            供人性化的界面显示和操作导引，具有占地少，投资小的特点，适合家用、商用等停车场充电以及特殊车型交流充电。'
          },
          // {
          //   img: process.env.VUE_APP_OSS_URL + '/product/charger_bow.png',
          //   title: '充电弓',
          //   type: ['300kW', '400kW'],
          //   desc: '本产品主要用于电动汽车的快速充电，将传统插枪充电模式改成车顶部自动对接充电，可实现最大1000A\
          //   <br />电流快速补电。本产品配套鼎充充电主机柜使用，也可同时选配附带一把枪或者两把枪充电。',
          //   pDesc: '本产品主要用于电动汽车的快速充电，将传统插枪充电模式改成车顶部自动对接充电，可实现最大1000A\
          //   电流快速补电。本产品配套鼎充充电主机柜使用，也可同时选配附带一把枪或者两把枪充电。'
          // }
        ]
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    bus.on('SCREEN_WIDTH_CHANGE', key => {
      let isPhone = key <= 600
      if (isPhone) {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/phone/product_banner_1.png'
      } else {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/product_banner_1.png'
      }
    })
  }
}
</script>

<style scoped>
.product, .banner-img {
  width: 100%;
}

.product-item {
  margin-top: 30px;
}

.one-body-img {
  width: 263px;
  height: 398px;
}

.prod-item-img-1 {
  width: 1246px;
  height: 353px;
}

.prod-item-img-2 {
  width: 1168px;
  height: 452px;
}

.prod-item-img-3 {
  width: 910px;
  height: 396px;
}

.prod-item-img-4 {
  width: 815px;
  height: 541px;
}

.title-point {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: #56BE8E;
  border-radius: 50%;
}

.prod-item-title {
  font-size: 36px;
  color: #333;
}

.type-tips {
  background: #56BE8E;
  border-radius: 20px;
  color: #FFF;
  font-size: 16px;
  line-height: 14px;
  font-weight: bold;
  padding: 8px 14px;
}

.type-item {
  border: 1px solid #D2D2D2;
  font-size: 14px;
  font-weight: bold;
  color: #747474;
  line-height: 14px;
  padding: 12px 11px;
}

.item-desc {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #656565;
  line-height: 30px;
}

.el-divider--horizontal {
  height: 2px;
  width: 76%;
}

.brow-img-container {
  width: 100%;
  height: 590px;
  background: #CECECE;
}

.divider-container {
  margin: 20px 0;
}

.p_prod-container {
  display: none;
  width: 100%;
  background-color: #EAEAEA;
  padding: 2px 0;
}
.prod-item {
  width: 98%;
  margin: 10px auto;
}
</style>