let geo = [
    {
        "code": "110000",
        "name": "北京",
        "fullname": "北京市",
        "character": "B",
        "pinyin": "bei jing",
        "lng": 116.40717,
        "lat": 39.90469,
        "municipality": null,
        "children": [
            {
                "code": "110000",
                "name": "北京",
                "fullname": "北京市",
                "character": "B",
                "pinyin": "bei jing",
                "lng": 116.40717,
                "lat": 39.90469,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "120000",
        "name": "天津",
        "fullname": "天津市",
        "character": "T",
        "pinyin": "tian jin",
        "lng": 117.19937,
        "lat": 39.0851,
        "municipality": null,
        "children": [
            {
                "code": "120000",
                "name": "天津",
                "fullname": "天津市",
                "character": "T",
                "pinyin": "tian jin",
                "lng": 117.19937,
                "lat": 39.0851,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "130000",
        "name": "河北",
        "fullname": "河北省",
        "character": "H",
        "pinyin": "he bei",
        "lng": 114.46979,
        "lat": 38.03599,
        "municipality": null,
        "children": [
            {
                "code": "130100",
                "name": "石家庄",
                "fullname": "石家庄市",
                "character": "S",
                "pinyin": "shi jia zhuang",
                "lng": 114.5143,
                "lat": 38.04276,
                "provinceCode": null,
                
            },
            {
                "code": "130200",
                "name": "唐山",
                "fullname": "唐山市",
                "character": "T",
                "pinyin": "tang shan",
                "lng": 118.18058,
                "lat": 39.63048,
                "provinceCode": null,
                
            },
            {
                "code": "130300",
                "name": "秦皇岛",
                "fullname": "秦皇岛市",
                "character": "Q",
                "pinyin": "qin huang dao",
                "lng": 119.59964,
                "lat": 39.93545,
                "provinceCode": null,
                
            },
            {
                "code": "130400",
                "name": "邯郸",
                "fullname": "邯郸市",
                "character": "H",
                "pinyin": "han dan",
                "lng": 114.53918,
                "lat": 36.62556,
                "provinceCode": null,
                
            },
            {
                "code": "130500",
                "name": "邢台",
                "fullname": "邢台市",
                "character": "X",
                "pinyin": "xing tai",
                "lng": 114.50443,
                "lat": 37.07055,
                "provinceCode": null,
                
            },
            {
                "code": "130600",
                "name": "保定",
                "fullname": "保定市",
                "character": "B",
                "pinyin": "bao ding",
                "lng": 115.46459,
                "lat": 38.87396,
                "provinceCode": null,
                
            },
            {
                "code": "130700",
                "name": "张家口",
                "fullname": "张家口市",
                "character": "Z",
                "pinyin": "zhang jia kou",
                "lng": 114.88755,
                "lat": 40.82444,
                "provinceCode": null,
                
            },
            {
                "code": "130800",
                "name": "承德",
                "fullname": "承德市",
                "character": "C",
                "pinyin": "cheng de",
                "lng": 117.9634,
                "lat": 40.9515,
                "provinceCode": null,
                
            },
            {
                "code": "130900",
                "name": "沧州",
                "fullname": "沧州市",
                "character": "C",
                "pinyin": "cang zhou",
                "lng": 116.83869,
                "lat": 38.30441,
                "provinceCode": null,
                
            },
            {
                "code": "131000",
                "name": "廊坊",
                "fullname": "廊坊市",
                "character": "L",
                "pinyin": "lang fang",
                "lng": 116.68376,
                "lat": 39.53775,
                "provinceCode": null,
                
            },
            {
                "code": "131100",
                "name": "衡水",
                "fullname": "衡水市",
                "character": "H",
                "pinyin": "heng shui",
                "lng": 115.67054,
                "lat": 37.73886,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "140000",
        "name": "山西",
        "fullname": "山西省",
        "character": "S",
        "pinyin": "shan xi",
        "lng": 112.56272,
        "lat": 37.87343,
        "municipality": null,
        "children": [
            {
                "code": "140100",
                "name": "太原",
                "fullname": "太原市",
                "character": "T",
                "pinyin": "tai yuan",
                "lng": 112.55067,
                "lat": 37.87059,
                "provinceCode": null,
                
            },
            {
                "code": "140200",
                "name": "大同",
                "fullname": "大同市",
                "character": "D",
                "pinyin": "da tong",
                "lng": 113.30001,
                "lat": 40.07637,
                "provinceCode": null,
                
            },
            {
                "code": "140300",
                "name": "阳泉",
                "fullname": "阳泉市",
                "character": "Y",
                "pinyin": "yang quan",
                "lng": 113.58047,
                "lat": 37.85668,
                "provinceCode": null,
                
            },
            {
                "code": "140400",
                "name": "长治",
                "fullname": "长治市",
                "character": "C",
                "pinyin": "chang zhi",
                "lng": 113.11649,
                "lat": 36.19581,
                "provinceCode": null,
                
            },
            {
                "code": "140500",
                "name": "晋城",
                "fullname": "晋城市",
                "character": "J",
                "pinyin": "jin cheng",
                "lng": 112.85113,
                "lat": 35.49039,
                "provinceCode": null,
                
            },
            {
                "code": "140600",
                "name": "朔州",
                "fullname": "朔州市",
                "character": "S",
                "pinyin": "shuo zhou",
                "lng": 112.43286,
                "lat": 39.33155,
                "provinceCode": null,
                
            },
            {
                "code": "140700",
                "name": "晋中",
                "fullname": "晋中市",
                "character": "J",
                "pinyin": "jin zhong",
                "lng": 112.75278,
                "lat": 37.68702,
                "provinceCode": null,
                
            },
            {
                "code": "140800",
                "name": "运城",
                "fullname": "运城市",
                "character": "Y",
                "pinyin": "yun cheng",
                "lng": 111.00699,
                "lat": 35.02628,
                "provinceCode": null,
                
            },
            {
                "code": "140900",
                "name": "忻州",
                "fullname": "忻州市",
                "character": "X",
                "pinyin": "xin zhou",
                "lng": 112.73418,
                "lat": 38.4167,
                "provinceCode": null,
                
            },
            {
                "code": "141000",
                "name": "临汾",
                "fullname": "临汾市",
                "character": "L",
                "pinyin": "lin fen",
                "lng": 111.51962,
                "lat": 36.08822,
                "provinceCode": null,
                
            },
            {
                "code": "141100",
                "name": "吕梁",
                "fullname": "吕梁市",
                "character": "L",
                "pinyin": "lv liang",
                "lng": 111.14165,
                "lat": 37.51934,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "150000",
        "name": "内蒙古",
        "fullname": "内蒙古自治区",
        "character": "N",
        "pinyin": "nei meng gu",
        "lng": 111.76522,
        "lat": 40.81733,
        "municipality": null,
        "children": [
            {
                "code": "150100",
                "name": "呼和浩特",
                "fullname": "呼和浩特市",
                "character": "H",
                "pinyin": "hu he hao te",
                "lng": 111.75199,
                "lat": 40.84149,
                "provinceCode": null,
                
            },
            {
                "code": "150200",
                "name": "包头",
                "fullname": "包头市",
                "character": "B",
                "pinyin": "bao tou",
                "lng": 109.84021,
                "lat": 40.65781,
                "provinceCode": null,
                
            },
            {
                "code": "150300",
                "name": "乌海",
                "fullname": "乌海市",
                "character": "W",
                "pinyin": "wu hai",
                "lng": 106.79546,
                "lat": 39.65384,
                "provinceCode": null,
                
            },
            {
                "code": "150400",
                "name": "赤峰",
                "fullname": "赤峰市",
                "character": "C",
                "pinyin": "chi feng",
                "lng": 118.88894,
                "lat": 42.2586,
                "provinceCode": null,
                
            },
            {
                "code": "150500",
                "name": "通辽",
                "fullname": "通辽市",
                "character": "T",
                "pinyin": "tong liao",
                "lng": 122.24469,
                "lat": 43.65247,
                "provinceCode": null,
                
            },
            {
                "code": "150600",
                "name": "鄂尔多斯",
                "fullname": "鄂尔多斯市",
                "character": "E",
                "pinyin": "e er duo si",
                "lng": 109.78087,
                "lat": 39.60845,
                "provinceCode": null,
                
            },
            {
                "code": "150700",
                "name": "呼伦贝尔",
                "fullname": "呼伦贝尔市",
                "character": "H",
                "pinyin": "hu lun bei er",
                "lng": 119.76584,
                "lat": 49.21163,
                "provinceCode": null,
                
            },
            {
                "code": "150800",
                "name": "巴彦淖尔",
                "fullname": "巴彦淖尔市",
                "character": "B",
                "pinyin": "ba yan nao er",
                "lng": 107.38773,
                "lat": 40.74317,
                "provinceCode": null,
                
            },
            {
                "code": "150900",
                "name": "乌兰察布",
                "fullname": "乌兰察布市",
                "character": "W",
                "pinyin": "wu lan cha bu",
                "lng": 113.13376,
                "lat": 40.99391,
                "provinceCode": null,
                
            },
            {
                "code": "152200",
                "name": "兴安",
                "fullname": "兴安盟",
                "character": "X",
                "pinyin": "xing an",
                "lng": 122.03818,
                "lat": 46.08208,
                "provinceCode": null,
                
            },
            {
                "code": "152500",
                "name": "锡林郭勒",
                "fullname": "锡林郭勒盟",
                "character": "X",
                "pinyin": "xi lin guo le",
                "lng": 116.04775,
                "lat": 43.9332,
                "provinceCode": null,
                
            },
            {
                "code": "152900",
                "name": "阿拉善",
                "fullname": "阿拉善盟",
                "character": "A",
                "pinyin": "a la shan",
                "lng": 105.72898,
                "lat": 38.85153,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "210000",
        "name": "辽宁",
        "fullname": "辽宁省",
        "character": "L",
        "pinyin": "liao ning",
        "lng": 123.42925,
        "lat": 41.83571,
        "municipality": null,
        "children": [
            {
                "code": "210100",
                "name": "沈阳",
                "fullname": "沈阳市",
                "character": "S",
                "pinyin": "shen yang",
                "lng": 123.4631,
                "lat": 41.67718,
                "provinceCode": null,
                
            },
            {
                "code": "210200",
                "name": "大连",
                "fullname": "大连市",
                "character": "D",
                "pinyin": "da lian",
                "lng": 121.61476,
                "lat": 38.91369,
                "provinceCode": null,
                
            },
            {
                "code": "210300",
                "name": "鞍山",
                "fullname": "鞍山市",
                "character": "A",
                "pinyin": "an shan",
                "lng": 122.9946,
                "lat": 41.10777,
                "provinceCode": null,
                
            },
            {
                "code": "210400",
                "name": "抚顺",
                "fullname": "抚顺市",
                "character": "F",
                "pinyin": "fu shun",
                "lng": 123.95722,
                "lat": 41.87971,
                "provinceCode": null,
                
            },
            {
                "code": "210500",
                "name": "本溪",
                "fullname": "本溪市",
                "character": "B",
                "pinyin": "ben xi",
                "lng": 123.76686,
                "lat": 41.29413,
                "provinceCode": null,
                
            },
            {
                "code": "210600",
                "name": "丹东",
                "fullname": "丹东市",
                "character": "D",
                "pinyin": "dan dong",
                "lng": 124.35601,
                "lat": 39.9998,
                "provinceCode": null,
                
            },
            {
                "code": "210700",
                "name": "锦州",
                "fullname": "锦州市",
                "character": "J",
                "pinyin": "jin zhou",
                "lng": 121.12703,
                "lat": 41.09515,
                "provinceCode": null,
                
            },
            {
                "code": "210800",
                "name": "营口",
                "fullname": "营口市",
                "character": "Y",
                "pinyin": "ying kou",
                "lng": 122.2349,
                "lat": 40.66683,
                "provinceCode": null,
                
            },
            {
                "code": "210900",
                "name": "阜新",
                "fullname": "阜新市",
                "character": "F",
                "pinyin": "fu xin",
                "lng": 121.67011,
                "lat": 42.02166,
                "provinceCode": null,
                
            },
            {
                "code": "211000",
                "name": "辽阳",
                "fullname": "辽阳市",
                "character": "L",
                "pinyin": "liao yang",
                "lng": 123.23736,
                "lat": 41.26809,
                "provinceCode": null,
                
            },
            {
                "code": "211100",
                "name": "盘锦",
                "fullname": "盘锦市",
                "character": "P",
                "pinyin": "pan jin",
                "lng": 122.07078,
                "lat": 41.11996,
                "provinceCode": null,
                
            },
            {
                "code": "211200",
                "name": "铁岭",
                "fullname": "铁岭市",
                "character": "T",
                "pinyin": "tie ling",
                "lng": 123.84241,
                "lat": 42.2862,
                "provinceCode": null,
                
            },
            {
                "code": "211300",
                "name": "朝阳",
                "fullname": "朝阳市",
                "character": "C",
                "pinyin": "chao yang",
                "lng": 120.4508,
                "lat": 41.57347,
                "provinceCode": null,
                
            },
            {
                "code": "211400",
                "name": "葫芦岛",
                "fullname": "葫芦岛市",
                "character": "H",
                "pinyin": "hu lu dao",
                "lng": 120.83699,
                "lat": 40.711,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "220000",
        "name": "吉林",
        "fullname": "吉林省",
        "character": "J",
        "pinyin": "ji lin",
        "lng": 125.3268,
        "lat": 43.89616,
        "municipality": null,
        "children": [
            {
                "code": "220100",
                "name": "长春",
                "fullname": "长春市",
                "character": "C",
                "pinyin": "chang chun",
                "lng": 125.32357,
                "lat": 43.81602,
                "provinceCode": null,
                
            },
            {
                "code": "220200",
                "name": "吉林",
                "fullname": "吉林市",
                "character": "J",
                "pinyin": "ji lin",
                "lng": 126.54944,
                "lat": 43.83784,
                "provinceCode": null,
                
            },
            {
                "code": "220300",
                "name": "四平",
                "fullname": "四平市",
                "character": "S",
                "pinyin": "si ping",
                "lng": 124.35036,
                "lat": 43.16646,
                "provinceCode": null,
                
            },
            {
                "code": "220400",
                "name": "辽源",
                "fullname": "辽源市",
                "character": "L",
                "pinyin": "liao yuan",
                "lng": 125.14368,
                "lat": 42.88805,
                "provinceCode": null,
                
            },
            {
                "code": "220500",
                "name": "通化",
                "fullname": "通化市",
                "character": "T",
                "pinyin": "tong hua",
                "lng": 125.9399,
                "lat": 41.72829,
                "provinceCode": null,
                
            },
            {
                "code": "220600",
                "name": "白山",
                "fullname": "白山市",
                "character": "B",
                "pinyin": "bai shan",
                "lng": 126.42443,
                "lat": 41.9408,
                "provinceCode": null,
                
            },
            {
                "code": "220700",
                "name": "松原",
                "fullname": "松原市",
                "character": "S",
                "pinyin": "song yuan",
                "lng": 124.82515,
                "lat": 45.1411,
                "provinceCode": null,
                
            },
            {
                "code": "220800",
                "name": "白城",
                "fullname": "白城市",
                "character": "B",
                "pinyin": "bai cheng",
                "lng": 122.83871,
                "lat": 45.6196,
                "provinceCode": null,
                
            },
            {
                "code": "222400",
                "name": "延边",
                "fullname": "延边朝鲜族自治州",
                "character": "Y",
                "pinyin": "yan bian",
                "lng": 129.5091,
                "lat": 42.89119,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "230000",
        "name": "黑龙江",
        "fullname": "黑龙江省",
        "character": "H",
        "pinyin": "hei long jiang",
        "lng": 126.66285,
        "lat": 45.74208,
        "municipality": null,
        "children": [
            {
                "code": "230100",
                "name": "哈尔滨",
                "fullname": "哈尔滨市",
                "character": "H",
                "pinyin": "ha er bin",
                "lng": 126.5358,
                "lat": 45.80216,
                "provinceCode": null,
                
            },
            {
                "code": "230200",
                "name": "齐齐哈尔",
                "fullname": "齐齐哈尔市",
                "character": "Q",
                "pinyin": "qi qi ha er",
                "lng": 123.91796,
                "lat": 47.35431,
                "provinceCode": null,
                
            },
            {
                "code": "230300",
                "name": "鸡西",
                "fullname": "鸡西市",
                "character": "J",
                "pinyin": "ji xi",
                "lng": 130.96954,
                "lat": 45.29524,
                "provinceCode": null,
                
            },
            {
                "code": "230400",
                "name": "鹤岗",
                "fullname": "鹤岗市",
                "character": "H",
                "pinyin": "he gang",
                "lng": 130.29785,
                "lat": 47.34989,
                "provinceCode": null,
                
            },
            {
                "code": "230500",
                "name": "双鸭山",
                "fullname": "双鸭山市",
                "character": "S",
                "pinyin": "shuang ya shan",
                "lng": 131.1591,
                "lat": 46.64658,
                "provinceCode": null,
                
            },
            {
                "code": "230600",
                "name": "大庆",
                "fullname": "大庆市",
                "character": "D",
                "pinyin": "da qing",
                "lng": 125.10307,
                "lat": 46.58758,
                "provinceCode": null,
                
            },
            {
                "code": "230700",
                "name": "伊春",
                "fullname": "伊春市",
                "character": "Y",
                "pinyin": "yi chun",
                "lng": 128.84049,
                "lat": 47.72752,
                "provinceCode": null,
                
            },
            {
                "code": "230800",
                "name": "佳木斯",
                "fullname": "佳木斯市",
                "character": "J",
                "pinyin": "jia mu si",
                "lng": 130.31882,
                "lat": 46.79977,
                "provinceCode": null,
                
            },
            {
                "code": "230900",
                "name": "七台河",
                "fullname": "七台河市",
                "character": "Q",
                "pinyin": "qi tai he",
                "lng": 131.00306,
                "lat": 45.77065,
                "provinceCode": null,
                
            },
            {
                "code": "231000",
                "name": "牡丹江",
                "fullname": "牡丹江市",
                "character": "M",
                "pinyin": "mu dan jiang",
                "lng": 129.63244,
                "lat": 44.55269,
                "provinceCode": null,
                
            },
            {
                "code": "231100",
                "name": "黑河",
                "fullname": "黑河市",
                "character": "H",
                "pinyin": "hei he",
                "lng": 127.52852,
                "lat": 50.24523,
                "provinceCode": null,
                
            },
            {
                "code": "231200",
                "name": "绥化",
                "fullname": "绥化市",
                "character": "S",
                "pinyin": "sui hua",
                "lng": 126.96932,
                "lat": 46.65246,
                "provinceCode": null,
                
            },
            {
                "code": "232700",
                "name": "大兴安岭",
                "fullname": "大兴安岭地区",
                "character": "D",
                "pinyin": "da xing an ling",
                "lng": 124.59216,
                "lat": 51.92398,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "310000",
        "name": "上海",
        "fullname": "上海市",
        "character": "S",
        "pinyin": "shang hai",
        "lng": 121.4737,
        "lat": 31.23037,
        "municipality": null,
        "children": [
            {
                "code": "310000",
                "name": "上海",
                "fullname": "上海市",
                "character": "S",
                "pinyin": "shang hai",
                "lng": 121.4737,
                "lat": 31.23037,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "320000",
        "name": "江苏",
        "fullname": "江苏省",
        "character": "J",
        "pinyin": "jiang su",
        "lng": 118.76295,
        "lat": 32.06071,
        "municipality": null,
        "children": [
            {
                "code": "320100",
                "name": "南京",
                "fullname": "南京市",
                "character": "N",
                "pinyin": "nan jing",
                "lng": 118.79647,
                "lat": 32.05838,
                "provinceCode": null,
                
            },
            {
                "code": "320200",
                "name": "无锡",
                "fullname": "无锡市",
                "character": "W",
                "pinyin": "wu xi",
                "lng": 120.31237,
                "lat": 31.49099,
                "provinceCode": null,
                
            },
            {
                "code": "320300",
                "name": "徐州",
                "fullname": "徐州市",
                "character": "X",
                "pinyin": "xu zhou",
                "lng": 117.28577,
                "lat": 34.2044,
                "provinceCode": null,
                
            },
            {
                "code": "320400",
                "name": "常州",
                "fullname": "常州市",
                "character": "C",
                "pinyin": "chang zhou",
                "lng": 119.97365,
                "lat": 31.81072,
                "provinceCode": null,
                
            },
            {
                "code": "320500",
                "name": "苏州",
                "fullname": "苏州市",
                "character": "S",
                "pinyin": "su zhou",
                "lng": 120.58319,
                "lat": 31.29834,
                "provinceCode": null,
                
            },
            {
                "code": "320600",
                "name": "南通",
                "fullname": "南通市",
                "character": "N",
                "pinyin": "nan tong",
                "lng": 120.89371,
                "lat": 31.97958,
                "provinceCode": null,
                
            },
            {
                "code": "320700",
                "name": "连云港",
                "fullname": "连云港市",
                "character": "L",
                "pinyin": "lian yun gang",
                "lng": 119.22295,
                "lat": 34.59669,
                "provinceCode": null,
                
            },
            {
                "code": "320800",
                "name": "淮安",
                "fullname": "淮安市",
                "character": "H",
                "pinyin": "huai an",
                "lng": 119.01595,
                "lat": 33.61016,
                "provinceCode": null,
                
            },
            {
                "code": "320900",
                "name": "盐城",
                "fullname": "盐城市",
                "character": "Y",
                "pinyin": "yan cheng",
                "lng": 120.16164,
                "lat": 33.34951,
                "provinceCode": null,
                
            },
            {
                "code": "321000",
                "name": "扬州",
                "fullname": "扬州市",
                "character": "Y",
                "pinyin": "yang zhou",
                "lng": 119.41269,
                "lat": 32.39358,
                "provinceCode": null,
                
            },
            {
                "code": "321100",
                "name": "镇江",
                "fullname": "镇江市",
                "character": "Z",
                "pinyin": "zhen jiang",
                "lng": 119.425,
                "lat": 32.18959,
                "provinceCode": null,
                
            },
            {
                "code": "321200",
                "name": "泰州",
                "fullname": "泰州市",
                "character": "T",
                "pinyin": "tai zhou",
                "lng": 119.92554,
                "lat": 32.45546,
                "provinceCode": null,
                
            },
            {
                "code": "321300",
                "name": "宿迁",
                "fullname": "宿迁市",
                "character": "S",
                "pinyin": "su qian",
                "lng": 118.27549,
                "lat": 33.96193,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "330000",
        "name": "浙江",
        "fullname": "浙江省",
        "character": "Z",
        "pinyin": "zhe jiang",
        "lng": 120.1536,
        "lat": 30.26555,
        "municipality": null,
        "children": [
            {
                "code": "330100",
                "name": "杭州",
                "fullname": "杭州市",
                "character": "H",
                "pinyin": "hang zhou",
                "lng": 120.15515,
                "lat": 30.27415,
                "provinceCode": null,
                
            },
            {
                "code": "330200",
                "name": "宁波",
                "fullname": "宁波市",
                "character": "N",
                "pinyin": "ning bo",
                "lng": 121.55027,
                "lat": 29.87386,
                "provinceCode": null,
                
            },
            {
                "code": "330300",
                "name": "温州",
                "fullname": "温州市",
                "character": "W",
                "pinyin": "wen zhou",
                "lng": 120.69939,
                "lat": 27.99492,
                "provinceCode": null,
                
            },
            {
                "code": "330400",
                "name": "嘉兴",
                "fullname": "嘉兴市",
                "character": "J",
                "pinyin": "jia xing",
                "lng": 120.7555,
                "lat": 30.74501,
                "provinceCode": null,
                
            },
            {
                "code": "330500",
                "name": "湖州",
                "fullname": "湖州市",
                "character": "H",
                "pinyin": "hu zhou",
                "lng": 120.08805,
                "lat": 30.89305,
                "provinceCode": null,
                
            },
            {
                "code": "330600",
                "name": "绍兴",
                "fullname": "绍兴市",
                "character": "S",
                "pinyin": "shao xing",
                "lng": 120.5802,
                "lat": 30.03033,
                "provinceCode": null,
                
            },
            {
                "code": "330700",
                "name": "金华",
                "fullname": "金华市",
                "character": "J",
                "pinyin": "jin hua",
                "lng": 119.64759,
                "lat": 29.07812,
                "provinceCode": null,
                
            },
            {
                "code": "330800",
                "name": "衢州",
                "fullname": "衢州市",
                "character": "Q",
                "pinyin": "qu zhou",
                "lng": 118.87419,
                "lat": 28.93592,
                "provinceCode": null,
                
            },
            {
                "code": "330900",
                "name": "舟山",
                "fullname": "舟山市",
                "character": "Z",
                "pinyin": "zhou shan",
                "lng": 122.20778,
                "lat": 29.98539,
                "provinceCode": null,
                
            },
            {
                "code": "331000",
                "name": "台州",
                "fullname": "台州市",
                "character": "T",
                "pinyin": "tai zhou",
                "lng": 121.42056,
                "lat": 28.65611,
                "provinceCode": null,
                
            },
            {
                "code": "331100",
                "name": "丽水",
                "fullname": "丽水市",
                "character": "L",
                "pinyin": "li shui",
                "lng": 119.92293,
                "lat": 28.4672,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "340000",
        "name": "安徽",
        "fullname": "安徽省",
        "character": "A",
        "pinyin": "an hui",
        "lng": 117.28565,
        "lat": 31.86157,
        "municipality": null,
        "children": [
            {
                "code": "340100",
                "name": "合肥",
                "fullname": "合肥市",
                "character": "H",
                "pinyin": "he fei",
                "lng": 117.22901,
                "lat": 31.82057,
                "provinceCode": null,
                
            },
            {
                "code": "340200",
                "name": "芜湖",
                "fullname": "芜湖市",
                "character": "W",
                "pinyin": "wu hu",
                "lng": 118.43313,
                "lat": 31.35246,
                "provinceCode": null,
                
            },
            {
                "code": "340300",
                "name": "蚌埠",
                "fullname": "蚌埠市",
                "character": "B",
                "pinyin": "beng bu",
                "lng": 117.38932,
                "lat": 32.91548,
                "provinceCode": null,
                
            },
            {
                "code": "340400",
                "name": "淮南",
                "fullname": "淮南市",
                "character": "H",
                "pinyin": "huai nan",
                "lng": 116.9998,
                "lat": 32.62549,
                "provinceCode": null,
                
            },
            {
                "code": "340500",
                "name": "马鞍山",
                "fullname": "马鞍山市",
                "character": "M",
                "pinyin": "ma an shan",
                "lng": 118.50611,
                "lat": 31.67067,
                "provinceCode": null,
                
            },
            {
                "code": "340600",
                "name": "淮北",
                "fullname": "淮北市",
                "character": "H",
                "pinyin": "huai bei",
                "lng": 116.79834,
                "lat": 33.95479,
                "provinceCode": null,
                
            },
            {
                "code": "340700",
                "name": "铜陵",
                "fullname": "铜陵市",
                "character": "T",
                "pinyin": "tong ling",
                "lng": 117.81232,
                "lat": 30.94486,
                "provinceCode": null,
                
            },
            {
                "code": "340800",
                "name": "安庆",
                "fullname": "安庆市",
                "character": "A",
                "pinyin": "an qing",
                "lng": 117.06354,
                "lat": 30.54294,
                "provinceCode": null,
                
            },
            {
                "code": "341000",
                "name": "黄山",
                "fullname": "黄山市",
                "character": "H",
                "pinyin": "huang shan",
                "lng": 118.33866,
                "lat": 29.71517,
                "provinceCode": null,
                
            },
            {
                "code": "341100",
                "name": "滁州",
                "fullname": "滁州市",
                "character": "C",
                "pinyin": "chu zhou",
                "lng": 118.31683,
                "lat": 32.30181,
                "provinceCode": null,
                
            },
            {
                "code": "341200",
                "name": "阜阳",
                "fullname": "阜阳市",
                "character": "F",
                "pinyin": "fu yang",
                "lng": 115.81495,
                "lat": 32.88963,
                "provinceCode": null,
                
            },
            {
                "code": "341300",
                "name": "宿州",
                "fullname": "宿州市",
                "character": "S",
                "pinyin": "su zhou",
                "lng": 116.96391,
                "lat": 33.64614,
                "provinceCode": null,
                
            },
            {
                "code": "341500",
                "name": "六安",
                "fullname": "六安市",
                "character": "L",
                "pinyin": "liu an",
                "lng": 116.52324,
                "lat": 31.73488,
                "provinceCode": null,
                
            },
            {
                "code": "341600",
                "name": "亳州",
                "fullname": "亳州市",
                "character": "B",
                "pinyin": "bo zhou",
                "lng": 115.77931,
                "lat": 33.84461,
                "provinceCode": null,
                
            },
            {
                "code": "341700",
                "name": "池州",
                "fullname": "池州市",
                "character": "C",
                "pinyin": "chi zhou",
                "lng": 117.49142,
                "lat": 30.66469,
                "provinceCode": null,
                
            },
            {
                "code": "341800",
                "name": "宣城",
                "fullname": "宣城市",
                "character": "X",
                "pinyin": "xuan cheng",
                "lng": 118.75866,
                "lat": 30.94078,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "350000",
        "name": "福建",
        "fullname": "福建省",
        "character": "F",
        "pinyin": "fu jian",
        "lng": 119.29659,
        "lat": 26.09982,
        "municipality": null,
        "children": [
            {
                "code": "350100",
                "name": "福州",
                "fullname": "福州市",
                "character": "F",
                "pinyin": "fu zhou",
                "lng": 119.29647,
                "lat": 26.07421,
                "provinceCode": null,
                
            },
            {
                "code": "350200",
                "name": "厦门",
                "fullname": "厦门市",
                "character": "X",
                "pinyin": "xia men",
                "lng": 118.08948,
                "lat": 24.47951,
                "provinceCode": null,
                
            },
            {
                "code": "350300",
                "name": "莆田",
                "fullname": "莆田市",
                "character": "P",
                "pinyin": "pu tian",
                "lng": 119.00771,
                "lat": 25.454,
                "provinceCode": null,
                
            },
            {
                "code": "350400",
                "name": "三明",
                "fullname": "三明市",
                "character": "S",
                "pinyin": "san ming",
                "lng": 117.63922,
                "lat": 26.26385,
                "provinceCode": null,
                
            },
            {
                "code": "350500",
                "name": "泉州",
                "fullname": "泉州市",
                "character": "Q",
                "pinyin": "quan zhou",
                "lng": 118.67587,
                "lat": 24.87389,
                "provinceCode": null,
                
            },
            {
                "code": "350600",
                "name": "漳州",
                "fullname": "漳州市",
                "character": "Z",
                "pinyin": "zhang zhou",
                "lng": 117.64725,
                "lat": 24.51347,
                "provinceCode": null,
                
            },
            {
                "code": "350700",
                "name": "南平",
                "fullname": "南平市",
                "character": "N",
                "pinyin": "nan ping",
                "lng": 118.12043,
                "lat": 27.33175,
                "provinceCode": null,
                
            },
            {
                "code": "350800",
                "name": "龙岩",
                "fullname": "龙岩市",
                "character": "L",
                "pinyin": "long yan",
                "lng": 117.01722,
                "lat": 25.07504,
                "provinceCode": null,
                
            },
            {
                "code": "350900",
                "name": "宁德",
                "fullname": "宁德市",
                "character": "N",
                "pinyin": "ning de",
                "lng": 119.54819,
                "lat": 26.66571,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "360000",
        "name": "江西",
        "fullname": "江西省",
        "character": "J",
        "pinyin": "jiang xi",
        "lng": 115.91004,
        "lat": 28.67417,
        "municipality": null,
        "children": [
            {
                "code": "360100",
                "name": "南昌",
                "fullname": "南昌市",
                "character": "N",
                "pinyin": "nan chang",
                "lng": 115.85794,
                "lat": 28.68202,
                "provinceCode": null,
                
            },
            {
                "code": "360200",
                "name": "景德镇",
                "fullname": "景德镇市",
                "character": "J",
                "pinyin": "jing de zhen",
                "lng": 117.17839,
                "lat": 29.26869,
                "provinceCode": null,
                
            },
            {
                "code": "360300",
                "name": "萍乡",
                "fullname": "萍乡市",
                "character": "P",
                "pinyin": "ping xiang",
                "lng": 113.85427,
                "lat": 27.62289,
                "provinceCode": null,
                
            },
            {
                "code": "360400",
                "name": "九江",
                "fullname": "九江市",
                "character": "J",
                "pinyin": "jiu jiang",
                "lng": 116.00146,
                "lat": 29.70548,
                "provinceCode": null,
                
            },
            {
                "code": "360500",
                "name": "新余",
                "fullname": "新余市",
                "character": "X",
                "pinyin": "xin yu",
                "lng": 114.91713,
                "lat": 27.81776,
                "provinceCode": null,
                
            },
            {
                "code": "360600",
                "name": "鹰潭",
                "fullname": "鹰潭市",
                "character": "Y",
                "pinyin": "ying tan",
                "lng": 117.06919,
                "lat": 28.26019,
                "provinceCode": null,
                
            },
            {
                "code": "360700",
                "name": "赣州",
                "fullname": "赣州市",
                "character": "G",
                "pinyin": "gan zhou",
                "lng": 114.93476,
                "lat": 25.83109,
                "provinceCode": null,
                
            },
            {
                "code": "360800",
                "name": "吉安",
                "fullname": "吉安市",
                "character": "J",
                "pinyin": "ji an",
                "lng": 114.99376,
                "lat": 27.11382,
                "provinceCode": null,
                
            },
            {
                "code": "360900",
                "name": "宜春",
                "fullname": "宜春市",
                "character": "Y",
                "pinyin": "yi chun",
                "lng": 114.41612,
                "lat": 27.81443,
                "provinceCode": null,
                
            },
            {
                "code": "361000",
                "name": "抚州",
                "fullname": "抚州市",
                "character": "F",
                "pinyin": "fu zhou",
                "lng": 116.35809,
                "lat": 27.94781,
                "provinceCode": null,
                
            },
            {
                "code": "361100",
                "name": "上饶",
                "fullname": "上饶市",
                "character": "S",
                "pinyin": "shang rao",
                "lng": 117.94357,
                "lat": 28.45463,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "370000",
        "name": "山东",
        "fullname": "山东省",
        "character": "S",
        "pinyin": "shan dong",
        "lng": 117.02076,
        "lat": 36.66826,
        "municipality": null,
        "children": [
            {
                "code": "370100",
                "name": "济南",
                "fullname": "济南市",
                "character": "J",
                "pinyin": "ji nan",
                "lng": 117.12009,
                "lat": 36.65184,
                "provinceCode": null,
                
            },
            {
                "code": "370200",
                "name": "青岛",
                "fullname": "青岛市",
                "character": "Q",
                "pinyin": "qing dao",
                "lng": 120.38299,
                "lat": 36.06623,
                "provinceCode": null,
                
            },
            {
                "code": "370300",
                "name": "淄博",
                "fullname": "淄博市",
                "character": "Z",
                "pinyin": "zi bo",
                "lng": 118.0548,
                "lat": 36.8131,
                "provinceCode": null,
                
            },
            {
                "code": "370400",
                "name": "枣庄",
                "fullname": "枣庄市",
                "character": "Z",
                "pinyin": "zao zhuang",
                "lng": 117.32196,
                "lat": 34.81071,
                "provinceCode": null,
                
            },
            {
                "code": "370500",
                "name": "东营",
                "fullname": "东营市",
                "character": "D",
                "pinyin": "dong ying",
                "lng": 118.67466,
                "lat": 37.43365,
                "provinceCode": null,
                
            },
            {
                "code": "370600",
                "name": "烟台",
                "fullname": "烟台市",
                "character": "Y",
                "pinyin": "yan tai",
                "lng": 121.44801,
                "lat": 37.46353,
                "provinceCode": null,
                
            },
            {
                "code": "370700",
                "name": "潍坊",
                "fullname": "潍坊市",
                "character": "W",
                "pinyin": "wei fang",
                "lng": 119.16176,
                "lat": 36.70686,
                "provinceCode": null,
                
            },
            {
                "code": "370800",
                "name": "济宁",
                "fullname": "济宁市",
                "character": "J",
                "pinyin": "ji ning",
                "lng": 116.58724,
                "lat": 35.41459,
                "provinceCode": null,
                
            },
            {
                "code": "370900",
                "name": "泰安",
                "fullname": "泰安市",
                "character": "T",
                "pinyin": "tai an",
                "lng": 117.0884,
                "lat": 36.19994,
                "provinceCode": null,
                
            },
            {
                "code": "371000",
                "name": "威海",
                "fullname": "威海市",
                "character": "W",
                "pinyin": "wei hai",
                "lng": 122.12171,
                "lat": 37.51348,
                "provinceCode": null,
                
            },
            {
                "code": "371100",
                "name": "日照",
                "fullname": "日照市",
                "character": "R",
                "pinyin": "ri zhao",
                "lng": 119.52719,
                "lat": 35.41646,
                "provinceCode": null,
                
            },
            {
                "code": "371300",
                "name": "临沂",
                "fullname": "临沂市",
                "character": "L",
                "pinyin": "lin yi",
                "lng": 118.35646,
                "lat": 35.10465,
                "provinceCode": null,
                
            },
            {
                "code": "371400",
                "name": "德州",
                "fullname": "德州市",
                "character": "D",
                "pinyin": "de zhou",
                "lng": 116.35927,
                "lat": 37.4355,
                "provinceCode": null,
                
            },
            {
                "code": "371500",
                "name": "聊城",
                "fullname": "聊城市",
                "character": "L",
                "pinyin": "liao cheng",
                "lng": 115.98549,
                "lat": 36.45702,
                "provinceCode": null,
                
            },
            {
                "code": "371600",
                "name": "滨州",
                "fullname": "滨州市",
                "character": "B",
                "pinyin": "bin zhou",
                "lng": 117.97279,
                "lat": 37.38211,
                "provinceCode": null,
                
            },
            {
                "code": "371700",
                "name": "菏泽",
                "fullname": "菏泽市",
                "character": "H",
                "pinyin": "he ze",
                "lng": 115.48115,
                "lat": 35.23363,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "410000",
        "name": "河南",
        "fullname": "河南省",
        "character": "H",
        "pinyin": "he nan",
        "lng": 113.75322,
        "lat": 34.76571,
        "municipality": null,
        "children": [
            {
                "code": "410100",
                "name": "郑州",
                "fullname": "郑州市",
                "character": "Z",
                "pinyin": "zheng zhou",
                "lng": 113.62493,
                "lat": 34.74725,
                "provinceCode": null,
                
            },
            {
                "code": "410200",
                "name": "开封",
                "fullname": "开封市",
                "character": "K",
                "pinyin": "kai feng",
                "lng": 114.30731,
                "lat": 34.79726,
                "provinceCode": null,
                
            },
            {
                "code": "410300",
                "name": "洛阳",
                "fullname": "洛阳市",
                "character": "L",
                "pinyin": "luo yang",
                "lng": 112.45361,
                "lat": 34.61812,
                "provinceCode": null,
                
            },
            {
                "code": "410400",
                "name": "平顶山",
                "fullname": "平顶山市",
                "character": "P",
                "pinyin": "ping ding shan",
                "lng": 113.19241,
                "lat": 33.76609,
                "provinceCode": null,
                
            },
            {
                "code": "410500",
                "name": "安阳",
                "fullname": "安阳市",
                "character": "A",
                "pinyin": "an yang",
                "lng": 114.3931,
                "lat": 36.09771,
                "provinceCode": null,
                
            },
            {
                "code": "410600",
                "name": "鹤壁",
                "fullname": "鹤壁市",
                "character": "H",
                "pinyin": "he bi",
                "lng": 114.29745,
                "lat": 35.747,
                "provinceCode": null,
                
            },
            {
                "code": "410700",
                "name": "新乡",
                "fullname": "新乡市",
                "character": "X",
                "pinyin": "xin xiang",
                "lng": 113.92675,
                "lat": 35.30323,
                "provinceCode": null,
                
            },
            {
                "code": "410800",
                "name": "焦作",
                "fullname": "焦作市",
                "character": "J",
                "pinyin": "jiao zuo",
                "lng": 113.24201,
                "lat": 35.21563,
                "provinceCode": null,
                
            },
            {
                "code": "410900",
                "name": "濮阳",
                "fullname": "濮阳市",
                "character": "P",
                "pinyin": "pu yang",
                "lng": 115.02932,
                "lat": 35.76189,
                "provinceCode": null,
                
            },
            {
                "code": "411000",
                "name": "许昌",
                "fullname": "许昌市",
                "character": "X",
                "pinyin": "xu chang",
                "lng": 113.85233,
                "lat": 34.0357,
                "provinceCode": null,
                
            },
            {
                "code": "411100",
                "name": "漯河",
                "fullname": "漯河市",
                "character": "L",
                "pinyin": "luo he",
                "lng": 114.01681,
                "lat": 33.58149,
                "provinceCode": null,
                
            },
            {
                "code": "411200",
                "name": "三门峡",
                "fullname": "三门峡市",
                "character": "S",
                "pinyin": "san men xia",
                "lng": 111.2003,
                "lat": 34.77261,
                "provinceCode": null,
                
            },
            {
                "code": "411300",
                "name": "南阳",
                "fullname": "南阳市",
                "character": "N",
                "pinyin": "nan yang",
                "lng": 112.52851,
                "lat": 32.99073,
                "provinceCode": null,
                
            },
            {
                "code": "411400",
                "name": "商丘",
                "fullname": "商丘市",
                "character": "S",
                "pinyin": "shang qiu",
                "lng": 115.65635,
                "lat": 34.41427,
                "provinceCode": null,
                
            },
            {
                "code": "411500",
                "name": "信阳",
                "fullname": "信阳市",
                "character": "X",
                "pinyin": "xin yang",
                "lng": 114.09279,
                "lat": 32.14714,
                "provinceCode": null,
                
            },
            {
                "code": "411600",
                "name": "周口",
                "fullname": "周口市",
                "character": "Z",
                "pinyin": "zhou kou",
                "lng": 114.69695,
                "lat": 33.62583,
                "provinceCode": null,
                
            },
            {
                "code": "411700",
                "name": "驻马店",
                "fullname": "驻马店市",
                "character": "Z",
                "pinyin": "zhu ma dian",
                "lng": 114.02299,
                "lat": 33.01142,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "420000",
        "name": "湖北",
        "fullname": "湖北省",
        "character": "H",
        "pinyin": "hu bei",
        "lng": 114.34234,
        "lat": 30.54539,
        "municipality": null,
        "children": [
            {
                "code": "420100",
                "name": "武汉",
                "fullname": "武汉市",
                "character": "W",
                "pinyin": "wu han",
                "lng": 114.30525,
                "lat": 30.59276,
                "provinceCode": null,
                
            },
            {
                "code": "420200",
                "name": "黄石",
                "fullname": "黄石市",
                "character": "H",
                "pinyin": "huang shi",
                "lng": 115.0389,
                "lat": 30.19953,
                "provinceCode": null,
                
            },
            {
                "code": "420300",
                "name": "十堰",
                "fullname": "十堰市",
                "character": "S",
                "pinyin": "shi yan",
                "lng": 110.79801,
                "lat": 32.62918,
                "provinceCode": null,
                
            },
            {
                "code": "420500",
                "name": "宜昌",
                "fullname": "宜昌市",
                "character": "Y",
                "pinyin": "yi chang",
                "lng": 111.28642,
                "lat": 30.69186,
                "provinceCode": null,
                
            },
            {
                "code": "420600",
                "name": "襄阳",
                "fullname": "襄阳市",
                "character": "X",
                "pinyin": "xiang yang",
                "lng": 112.12255,
                "lat": 32.009,
                "provinceCode": null,
                
            },
            {
                "code": "420700",
                "name": "鄂州",
                "fullname": "鄂州市",
                "character": "E",
                "pinyin": "e zhou",
                "lng": 114.89495,
                "lat": 30.39085,
                "provinceCode": null,
                
            },
            {
                "code": "420800",
                "name": "荆门",
                "fullname": "荆门市",
                "character": "J",
                "pinyin": "jing men",
                "lng": 112.19945,
                "lat": 31.03546,
                "provinceCode": null,
                
            },
            {
                "code": "420900",
                "name": "孝感",
                "fullname": "孝感市",
                "character": "X",
                "pinyin": "xiao gan",
                "lng": 113.91645,
                "lat": 30.92483,
                "provinceCode": null,
                
            },
            {
                "code": "421000",
                "name": "荆州",
                "fullname": "荆州市",
                "character": "J",
                "pinyin": "jing zhou",
                "lng": 112.24069,
                "lat": 30.33479,
                "provinceCode": null,
                
            },
            {
                "code": "421100",
                "name": "黄冈",
                "fullname": "黄冈市",
                "character": "H",
                "pinyin": "huang gang",
                "lng": 114.87238,
                "lat": 30.45347,
                "provinceCode": null,
                
            },
            {
                "code": "421200",
                "name": "咸宁",
                "fullname": "咸宁市",
                "character": "X",
                "pinyin": "xian ning",
                "lng": 114.32245,
                "lat": 29.84126,
                "provinceCode": null,
                
            },
            {
                "code": "421300",
                "name": "随州",
                "fullname": "随州市",
                "character": "S",
                "pinyin": "sui zhou",
                "lng": 113.38262,
                "lat": 31.69013,
                "provinceCode": null,
                
            },
            {
                "code": "422800",
                "name": "恩施",
                "fullname": "恩施土家族苗族自治州",
                "character": "E",
                "pinyin": "en shi",
                "lng": 109.48817,
                "lat": 30.27217,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "430000",
        "name": "湖南",
        "fullname": "湖南省",
        "character": "H",
        "pinyin": "hu nan",
        "lng": 112.9834,
        "lat": 28.11266,
        "municipality": null,
        "children": [
            {
                "code": "430100",
                "name": "长沙",
                "fullname": "长沙市",
                "character": "C",
                "pinyin": "chang sha",
                "lng": 112.93886,
                "lat": 28.22778,
                "provinceCode": null,
                
            },
            {
                "code": "430200",
                "name": "株洲",
                "fullname": "株洲市",
                "character": "Z",
                "pinyin": "zhu zhou",
                "lng": 113.13396,
                "lat": 27.82767,
                "provinceCode": null,
                
            },
            {
                "code": "430300",
                "name": "湘潭",
                "fullname": "湘潭市",
                "character": "X",
                "pinyin": "xiang tan",
                "lng": 112.94411,
                "lat": 27.82975,
                "provinceCode": null,
                
            },
            {
                "code": "430400",
                "name": "衡阳",
                "fullname": "衡阳市",
                "character": "H",
                "pinyin": "heng yang",
                "lng": 112.57195,
                "lat": 26.89324,
                "provinceCode": null,
                
            },
            {
                "code": "430500",
                "name": "邵阳",
                "fullname": "邵阳市",
                "character": "S",
                "pinyin": "shao yang",
                "lng": 111.4677,
                "lat": 27.2389,
                "provinceCode": null,
                
            },
            {
                "code": "430600",
                "name": "岳阳",
                "fullname": "岳阳市",
                "character": "Y",
                "pinyin": "yue yang",
                "lng": 113.12919,
                "lat": 29.35728,
                "provinceCode": null,
                
            },
            {
                "code": "430700",
                "name": "常德",
                "fullname": "常德市",
                "character": "C",
                "pinyin": "chang de",
                "lng": 111.69854,
                "lat": 29.03158,
                "provinceCode": null,
                
            },
            {
                "code": "430800",
                "name": "张家界",
                "fullname": "张家界市",
                "character": "Z",
                "pinyin": "zhang jia jie",
                "lng": 110.47839,
                "lat": 29.11667,
                "provinceCode": null,
                
            },
            {
                "code": "430900",
                "name": "益阳",
                "fullname": "益阳市",
                "character": "Y",
                "pinyin": "yi yang",
                "lng": 112.35516,
                "lat": 28.55391,
                "provinceCode": null,
                
            },
            {
                "code": "431000",
                "name": "郴州",
                "fullname": "郴州市",
                "character": "C",
                "pinyin": "chen zhou",
                "lng": 113.01485,
                "lat": 25.77063,
                "provinceCode": null,
                
            },
            {
                "code": "431100",
                "name": "永州",
                "fullname": "永州市",
                "character": "Y",
                "pinyin": "yong zhou",
                "lng": 111.61225,
                "lat": 26.42034,
                "provinceCode": null,
                
            },
            {
                "code": "431200",
                "name": "怀化",
                "fullname": "怀化市",
                "character": "H",
                "pinyin": "huai hua",
                "lng": 110.0016,
                "lat": 27.56974,
                "provinceCode": null,
                
            },
            {
                "code": "431300",
                "name": "娄底",
                "fullname": "娄底市",
                "character": "L",
                "pinyin": "lou di",
                "lng": 111.99458,
                "lat": 27.69728,
                "provinceCode": null,
                
            },
            {
                "code": "433100",
                "name": "湘西",
                "fullname": "湘西土家族苗族自治州",
                "character": "X",
                "pinyin": "xiang xi",
                "lng": 109.73893,
                "lat": 28.31173,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "440000",
        "name": "广东",
        "fullname": "广东省",
        "character": "G",
        "pinyin": "guang dong",
        "lng": 113.26627,
        "lat": 23.13171,
        "municipality": null,
        "children": [
            {
                "code": "440100",
                "name": "广州",
                "fullname": "广州市",
                "character": "G",
                "pinyin": "guang zhou",
                "lng": 113.26436,
                "lat": 23.12908,
                "provinceCode": null,
                
            },
            {
                "code": "440200",
                "name": "韶关",
                "fullname": "韶关市",
                "character": "S",
                "pinyin": "shao guan",
                "lng": 113.59723,
                "lat": 24.81039,
                "provinceCode": null,
                
            },
            {
                "code": "440300",
                "name": "深圳",
                "fullname": "深圳市",
                "character": "S",
                "pinyin": "shen zhen",
                "lng": 114.05956,
                "lat": 22.54286,
                "provinceCode": null,
                
            },
            {
                "code": "440500",
                "name": "汕头",
                "fullname": "汕头市",
                "character": "S",
                "pinyin": "shan tou",
                "lng": 116.68221,
                "lat": 23.3535,
                "provinceCode": null,
                
            },
            {
                "code": "440600",
                "name": "佛山",
                "fullname": "佛山市",
                "character": "F",
                "pinyin": "fo shan",
                "lng": 113.12192,
                "lat": 23.02185,
                "provinceCode": null,
                
            },
            {
                "code": "440700",
                "name": "江门",
                "fullname": "江门市",
                "character": "J",
                "pinyin": "jiang men",
                "lng": 113.08161,
                "lat": 22.57865,
                "provinceCode": null,
                
            },
            {
                "code": "440800",
                "name": "湛江",
                "fullname": "湛江市",
                "character": "Z",
                "pinyin": "zhan jiang",
                "lng": 110.35894,
                "lat": 21.27134,
                "provinceCode": null,
                
            },
            {
                "code": "440900",
                "name": "茂名",
                "fullname": "茂名市",
                "character": "M",
                "pinyin": "mao ming",
                "lng": 110.92523,
                "lat": 21.66329,
                "provinceCode": null,
                
            },
            {
                "code": "441200",
                "name": "肇庆",
                "fullname": "肇庆市",
                "character": "Z",
                "pinyin": "zhao qing",
                "lng": 112.46528,
                "lat": 23.0469,
                "provinceCode": null,
                
            },
            {
                "code": "441300",
                "name": "惠州",
                "fullname": "惠州市",
                "character": "H",
                "pinyin": "hui zhou",
                "lng": 114.41679,
                "lat": 23.11075,
                "provinceCode": null,
                
            },
            {
                "code": "441400",
                "name": "梅州",
                "fullname": "梅州市",
                "character": "M",
                "pinyin": "mei zhou",
                "lng": 116.12264,
                "lat": 24.28844,
                "provinceCode": null,
                
            },
            {
                "code": "441500",
                "name": "汕尾",
                "fullname": "汕尾市",
                "character": "S",
                "pinyin": "shan wei",
                "lng": 115.37514,
                "lat": 22.78566,
                "provinceCode": null,
                
            },
            {
                "code": "441600",
                "name": "河源",
                "fullname": "河源市",
                "character": "H",
                "pinyin": "he yuan",
                "lng": 114.70065,
                "lat": 23.74365,
                "provinceCode": null,
                
            },
            {
                "code": "441700",
                "name": "阳江",
                "fullname": "阳江市",
                "character": "Y",
                "pinyin": "yang jiang",
                "lng": 111.98256,
                "lat": 21.85829,
                "provinceCode": null,
                
            },
            {
                "code": "441800",
                "name": "清远",
                "fullname": "清远市",
                "character": "Q",
                "pinyin": "qing yuan",
                "lng": 113.05615,
                "lat": 23.68201,
                "provinceCode": null,
                
            },
            {
                "code": "441900",
                "name": "东莞",
                "fullname": "东莞市",
                "character": "D",
                "pinyin": "dong guan",
                "lng": 113.75179,
                "lat": 23.02067,
                "provinceCode": null,
                
            },
            {
                "code": "442000",
                "name": "中山",
                "fullname": "中山市",
                "character": "Z",
                "pinyin": "zhong shan",
                "lng": 113.3926,
                "lat": 22.51595,
                "provinceCode": null,
                
            },
            {
                "code": "445100",
                "name": "潮州",
                "fullname": "潮州市",
                "character": "C",
                "pinyin": "chao zhou",
                "lng": 116.62296,
                "lat": 23.6567,
                "provinceCode": null,
                
            },
            {
                "code": "445200",
                "name": "揭阳",
                "fullname": "揭阳市",
                "character": "J",
                "pinyin": "jie yang",
                "lng": 116.37271,
                "lat": 23.54972,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "450000",
        "name": "广西",
        "fullname": "广西壮族自治区",
        "character": "G",
        "pinyin": "guang xi",
        "lng": 108.32754,
        "lat": 22.81521,
        "municipality": null,
        "children": [
            {
                "code": "450600",
                "name": "防城港",
                "fullname": "防城港市",
                "character": "F",
                "pinyin": "fang cheng gang",
                "lng": 108.35472,
                "lat": 21.68713,
                "provinceCode": null,
                
            },
            {
                "code": "450700",
                "name": "钦州",
                "fullname": "钦州市",
                "character": "Q",
                "pinyin": "qin zhou",
                "lng": 108.65431,
                "lat": 21.9797,
                "provinceCode": null,
                
            },
            {
                "code": "450800",
                "name": "贵港",
                "fullname": "贵港市",
                "character": "G",
                "pinyin": "gui gang",
                "lng": 109.59764,
                "lat": 23.11306,
                "provinceCode": null,
                
            },
            {
                "code": "450900",
                "name": "玉林",
                "fullname": "玉林市",
                "character": "Y",
                "pinyin": "yu lin",
                "lng": 110.18098,
                "lat": 22.65451,
                "provinceCode": null,
                
            },
            {
                "code": "451000",
                "name": "百色",
                "fullname": "百色市",
                "character": "B",
                "pinyin": "bai se",
                "lng": 106.61838,
                "lat": 23.90216,
                "provinceCode": null,
                
            },
            {
                "code": "451100",
                "name": "贺州",
                "fullname": "贺州市",
                "character": "H",
                "pinyin": "he zhou",
                "lng": 111.56655,
                "lat": 24.40346,
                "provinceCode": null,
                
            },
            {
                "code": "451200",
                "name": "河池",
                "fullname": "河池市",
                "character": "H",
                "pinyin": "he chi",
                "lng": 108.0854,
                "lat": 24.69291,
                "provinceCode": null,
                
            },
            {
                "code": "451300",
                "name": "来宾",
                "fullname": "来宾市",
                "character": "L",
                "pinyin": "lai bin",
                "lng": 109.22238,
                "lat": 23.7521,
                "provinceCode": null,
                
            },
            {
                "code": "451400",
                "name": "崇左",
                "fullname": "崇左市",
                "character": "C",
                "pinyin": "chong zuo",
                "lng": 107.36485,
                "lat": 22.37895,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "460000",
        "name": "海南",
        "fullname": "海南省",
        "character": "H",
        "pinyin": "hai nan",
        "lng": 110.34863,
        "lat": 20.01997,
        "municipality": null,
        "children": [
            {
                "code": "460100",
                "name": "海口",
                "fullname": "海口市",
                "character": "H",
                "pinyin": "hai kou",
                "lng": 110.19989,
                "lat": 20.04422,
                "provinceCode": null,
                
            },
            {
                "code": "460200",
                "name": "三亚",
                "fullname": "三亚市",
                "character": "S",
                "pinyin": "san ya",
                "lng": 109.51209,
                "lat": 18.25248,
                "provinceCode": null,
                
            },
            {
                "code": "460300",
                "name": "三沙",
                "fullname": "三沙市",
                "character": "S",
                "pinyin": "san sha",
                "lng": 112.33356,
                "lat": 16.83272,
                "provinceCode": null,
                
            },
            {
                "code": "460400",
                "name": "儋州",
                "fullname": "儋州市",
                "character": "D",
                "pinyin": "dan zhou",
                "lng": 109.58069,
                "lat": 19.52093,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "500000",
        "name": "重庆",
        "fullname": "重庆市",
        "character": "C",
        "pinyin": "chong qing",
        "lng": 106.55073,
        "lat": 29.56471,
        "municipality": null,
        "children": [
            {
                "code": "500000",
                "name": "重庆",
                "fullname": "重庆市",
                "character": "C",
                "pinyin": "chong qing",
                "lng": 106.55073,
                "lat": 29.56471,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "510000",
        "name": "四川",
        "fullname": "四川省",
        "character": "S",
        "pinyin": "si chuan",
        "lng": 104.07572,
        "lat": 30.65089,
        "municipality": null,
        "children": [
            {
                "code": "510100",
                "name": "成都",
                "fullname": "成都市",
                "character": "C",
                "pinyin": "cheng du",
                "lng": 104.06476,
                "lat": 30.5702,
                "provinceCode": null,
                
            },
            {
                "code": "510300",
                "name": "自贡",
                "fullname": "自贡市",
                "character": "Z",
                "pinyin": "zi gong",
                "lng": 104.77844,
                "lat": 29.3392,
                "provinceCode": null,
                
            },
            {
                "code": "510400",
                "name": "攀枝花",
                "fullname": "攀枝花市",
                "character": "P",
                "pinyin": "pan zhi hua",
                "lng": 101.71872,
                "lat": 26.58228,
                "provinceCode": null,
                
            },
            {
                "code": "510500",
                "name": "泸州",
                "fullname": "泸州市",
                "character": "L",
                "pinyin": "lu zhou",
                "lng": 105.44257,
                "lat": 28.8717,
                "provinceCode": null,
                
            },
            {
                "code": "510600",
                "name": "德阳",
                "fullname": "德阳市",
                "character": "D",
                "pinyin": "de yang",
                "lng": 104.3979,
                "lat": 31.12679,
                "provinceCode": null,
                
            },
            {
                "code": "510700",
                "name": "绵阳",
                "fullname": "绵阳市",
                "character": "M",
                "pinyin": "mian yang",
                "lng": 104.6796,
                "lat": 31.46751,
                "provinceCode": null,
                
            },
            {
                "code": "510800",
                "name": "广元",
                "fullname": "广元市",
                "character": "G",
                "pinyin": "guang yuan",
                "lng": 105.84357,
                "lat": 32.43549,
                "provinceCode": null,
                
            },
            {
                "code": "510900",
                "name": "遂宁",
                "fullname": "遂宁市",
                "character": "S",
                "pinyin": "sui ning",
                "lng": 105.59273,
                "lat": 30.53286,
                "provinceCode": null,
                
            },
            {
                "code": "511000",
                "name": "内江",
                "fullname": "内江市",
                "character": "N",
                "pinyin": "nei jiang",
                "lng": 105.05844,
                "lat": 29.58015,
                "provinceCode": null,
                
            },
            {
                "code": "511100",
                "name": "乐山",
                "fullname": "乐山市",
                "character": "L",
                "pinyin": "le shan",
                "lng": 103.76539,
                "lat": 29.55221,
                "provinceCode": null,
                
            },
            {
                "code": "511300",
                "name": "南充",
                "fullname": "南充市",
                "character": "N",
                "pinyin": "nan chong",
                "lng": 106.11073,
                "lat": 30.83731,
                "provinceCode": null,
                
            },
            {
                "code": "511400",
                "name": "眉山",
                "fullname": "眉山市",
                "character": "M",
                "pinyin": "mei shan",
                "lng": 103.84851,
                "lat": 30.07563,
                "provinceCode": null,
                
            },
            {
                "code": "511500",
                "name": "宜宾",
                "fullname": "宜宾市",
                "character": "Y",
                "pinyin": "yi bin",
                "lng": 104.6417,
                "lat": 28.7513,
                "provinceCode": null,
                
            },
            {
                "code": "511600",
                "name": "广安",
                "fullname": "广安市",
                "character": "G",
                "pinyin": "guang an",
                "lng": 106.63322,
                "lat": 30.45596,
                "provinceCode": null,
                
            },
            {
                "code": "511700",
                "name": "达州",
                "fullname": "达州市",
                "character": "D",
                "pinyin": "da zhou",
                "lng": 107.46791,
                "lat": 31.20864,
                "provinceCode": null,
                
            },
            {
                "code": "511800",
                "name": "雅安",
                "fullname": "雅安市",
                "character": "Y",
                "pinyin": "ya an",
                "lng": 103.0424,
                "lat": 30.01053,
                "provinceCode": null,
                
            },
            {
                "code": "511900",
                "name": "巴中",
                "fullname": "巴中市",
                "character": "B",
                "pinyin": "ba zhong",
                "lng": 106.74733,
                "lat": 31.86715,
                "provinceCode": null,
                
            },
            {
                "code": "512000",
                "name": "资阳",
                "fullname": "资阳市",
                "character": "Z",
                "pinyin": "zi yang",
                "lng": 104.62798,
                "lat": 30.12859,
                "provinceCode": null,
                
            },
            {
                "code": "513200",
                "name": "阿坝",
                "fullname": "阿坝藏族羌族自治州",
                "character": "A",
                "pinyin": "a ba",
                "lng": 102.22477,
                "lat": 31.8994,
                "provinceCode": null,
                
            },
            {
                "code": "513300",
                "name": "甘孜",
                "fullname": "甘孜藏族自治州",
                "character": "G",
                "pinyin": "gan zi",
                "lng": 101.96254,
                "lat": 30.04932,
                "provinceCode": null,
                
            },
            {
                "code": "513400",
                "name": "凉山",
                "fullname": "凉山彝族自治州",
                "character": "L",
                "pinyin": "liang shan",
                "lng": 102.26746,
                "lat": 27.88164,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "520000",
        "name": "贵州",
        "fullname": "贵州省",
        "character": "G",
        "pinyin": "gui zhou",
        "lng": 106.70722,
        "lat": 26.5982,
        "municipality": null,
        "children": [
            {
                "code": "520100",
                "name": "贵阳",
                "fullname": "贵阳市",
                "character": "G",
                "pinyin": "gui yang",
                "lng": 106.63024,
                "lat": 26.64702,
                "provinceCode": null,
                
            },
            {
                "code": "520200",
                "name": "六盘水",
                "fullname": "六盘水市",
                "character": "L",
                "pinyin": "liu pan shui",
                "lng": 104.83023,
                "lat": 26.59336,
                "provinceCode": null,
                
            },
            {
                "code": "520300",
                "name": "遵义",
                "fullname": "遵义市",
                "character": "Z",
                "pinyin": "zun yi",
                "lng": 106.92723,
                "lat": 27.72545,
                "provinceCode": null,
                
            },
            {
                "code": "520400",
                "name": "安顺",
                "fullname": "安顺市",
                "character": "A",
                "pinyin": "an shun",
                "lng": 105.9462,
                "lat": 26.25367,
                "provinceCode": null,
                
            },
            {
                "code": "520500",
                "name": "毕节",
                "fullname": "毕节市",
                "character": "B",
                "pinyin": "bi jie",
                "lng": 105.30504,
                "lat": 27.29847,
                "provinceCode": null,
                
            },
            {
                "code": "520600",
                "name": "铜仁",
                "fullname": "铜仁市",
                "character": "T",
                "pinyin": "tong ren",
                "lng": 109.18099,
                "lat": 27.69066,
                "provinceCode": null,
                
            },
            {
                "code": "522300",
                "name": "黔西南",
                "fullname": "黔西南布依族苗族自治州",
                "character": "Q",
                "pinyin": "qian xi nan",
                "lng": 104.90437,
                "lat": 25.08988,
                "provinceCode": null,
                
            },
            {
                "code": "522600",
                "name": "黔东南",
                "fullname": "黔东南苗族侗族自治州",
                "character": "Q",
                "pinyin": "qian dong nan",
                "lng": 107.98416,
                "lat": 26.58364,
                "provinceCode": null,
                
            },
            {
                "code": "522700",
                "name": "黔南",
                "fullname": "黔南布依族苗族自治州",
                "character": "Q",
                "pinyin": "qian nan",
                "lng": 107.52226,
                "lat": 26.25427,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "530000",
        "name": "云南",
        "fullname": "云南省",
        "character": "Y",
        "pinyin": "yun nan",
        "lng": 102.70973,
        "lat": 25.0453,
        "municipality": null,
        "children": [
            {
                "code": "530100",
                "name": "昆明",
                "fullname": "昆明市",
                "character": "K",
                "pinyin": "kun ming",
                "lng": 102.83322,
                "lat": 24.87966,
                "provinceCode": null,
                
            },
            {
                "code": "530300",
                "name": "曲靖",
                "fullname": "曲靖市",
                "character": "Q",
                "pinyin": "qu jing",
                "lng": 103.79625,
                "lat": 25.49002,
                "provinceCode": null,
                
            },
            {
                "code": "530400",
                "name": "玉溪",
                "fullname": "玉溪市",
                "character": "Y",
                "pinyin": "yu xi",
                "lng": 102.54714,
                "lat": 24.3518,
                "provinceCode": null,
                
            },
            {
                "code": "530500",
                "name": "保山",
                "fullname": "保山市",
                "character": "B",
                "pinyin": "bao shan",
                "lng": 99.16181,
                "lat": 25.11205,
                "provinceCode": null,
                
            },
            {
                "code": "530600",
                "name": "昭通",
                "fullname": "昭通市",
                "character": "Z",
                "pinyin": "zhao tong",
                "lng": 103.7168,
                "lat": 27.33817,
                "provinceCode": null,
                
            },
            {
                "code": "530700",
                "name": "丽江",
                "fullname": "丽江市",
                "character": "L",
                "pinyin": "li jiang",
                "lng": 100.2271,
                "lat": 26.85648,
                "provinceCode": null,
                
            },
            {
                "code": "530800",
                "name": "普洱",
                "fullname": "普洱市",
                "character": "P",
                "pinyin": "pu er",
                "lng": 100.96624,
                "lat": 22.82521,
                "provinceCode": null,
                
            },
            {
                "code": "530900",
                "name": "临沧",
                "fullname": "临沧市",
                "character": "L",
                "pinyin": "lin cang",
                "lng": 100.08884,
                "lat": 23.88426,
                "provinceCode": null,
                
            },
            {
                "code": "532300",
                "name": "楚雄",
                "fullname": "楚雄彝族自治州",
                "character": "C",
                "pinyin": "chu xiong",
                "lng": 101.52767,
                "lat": 25.04495,
                "provinceCode": null,
                
            },
            {
                "code": "532500",
                "name": "红河",
                "fullname": "红河哈尼族彝族自治州",
                "character": "H",
                "pinyin": "hong he",
                "lng": 103.3756,
                "lat": 23.36422,
                "provinceCode": null,
                
            },
            {
                "code": "532600",
                "name": "文山",
                "fullname": "文山壮族苗族自治州",
                "character": "W",
                "pinyin": "wen shan",
                "lng": 104.21504,
                "lat": 23.39849,
                "provinceCode": null,
                
            },
            {
                "code": "532800",
                "name": "西双版纳",
                "fullname": "西双版纳傣族自治州",
                "character": "X",
                "pinyin": "xi shuang ban na",
                "lng": 100.79739,
                "lat": 22.00749,
                "provinceCode": null,
                
            },
            {
                "code": "532900",
                "name": "大理",
                "fullname": "大理白族自治州",
                "character": "D",
                "pinyin": "da li",
                "lng": 100.26764,
                "lat": 25.60648,
                "provinceCode": null,
                
            },
            {
                "code": "533100",
                "name": "德宏",
                "fullname": "德宏傣族景颇族自治州",
                "character": "D",
                "pinyin": "de hong",
                "lng": 98.58486,
                "lat": 24.43232,
                "provinceCode": null,
                
            },
            {
                "code": "533300",
                "name": "怒江",
                "fullname": "怒江傈僳族自治州",
                "character": "N",
                "pinyin": "nu jiang",
                "lng": 98.8567,
                "lat": 25.81763,
                "provinceCode": null,
                
            },
            {
                "code": "533400",
                "name": "迪庆",
                "fullname": "迪庆藏族自治州",
                "character": "D",
                "pinyin": "di qing",
                "lng": 99.70305,
                "lat": 27.81908,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "540000",
        "name": "西藏",
        "fullname": "西藏自治区",
        "character": "X",
        "pinyin": "xi zang",
        "lng": 91.11748,
        "lat": 29.64725,
        "municipality": null,
        "children": [
            {
                "code": "540100",
                "name": "拉萨",
                "fullname": "拉萨市",
                "character": "L",
                "pinyin": "la sa",
                "lng": 91.1145,
                "lat": 29.64415,
                "provinceCode": null,
                
            },
            {
                "code": "540200",
                "name": "日喀则",
                "fullname": "日喀则市",
                "character": "R",
                "pinyin": "ri ka ze",
                "lng": 88.88116,
                "lat": 29.26705,
                "provinceCode": null,
                
            },
            {
                "code": "540300",
                "name": "昌都",
                "fullname": "昌都市",
                "character": "C",
                "pinyin": "chang du",
                "lng": 97.17225,
                "lat": 31.14073,
                "provinceCode": null,
                
            },
            {
                "code": "540400",
                "name": "林芝",
                "fullname": "林芝市",
                "character": "L",
                "pinyin": "lin zhi",
                "lng": 94.36155,
                "lat": 29.64895,
                "provinceCode": null,
                
            },
            {
                "code": "540500",
                "name": "山南",
                "fullname": "山南市",
                "character": "S",
                "pinyin": "shan nan",
                "lng": 91.77313,
                "lat": 29.23705,
                "provinceCode": null,
                
            },
            {
                "code": "540600",
                "name": "那曲",
                "fullname": "那曲市",
                "character": "N",
                "pinyin": "na qu",
                "lng": 92.05136,
                "lat": 31.47614,
                "provinceCode": null,
                
            },
            {
                "code": "542500",
                "name": "阿里",
                "fullname": "阿里地区",
                "character": "A",
                "pinyin": "a li",
                "lng": 81.1454,
                "lat": 30.40051,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "610000",
        "name": "陕西",
        "fullname": "陕西省",
        "character": "S",
        "pinyin": "shan xi",
        "lng": 108.95424,
        "lat": 34.26486,
        "municipality": null,
        "children": [
            {
                "code": "610100",
                "name": "西安",
                "fullname": "西安市",
                "character": "X",
                "pinyin": "xi an",
                "lng": 108.93984,
                "lat": 34.34127,
                "provinceCode": null,
                
            },
            {
                "code": "610200",
                "name": "铜川",
                "fullname": "铜川市",
                "character": "T",
                "pinyin": "tong chuan",
                "lng": 108.94515,
                "lat": 34.89673,
                "provinceCode": null,
                
            },
            {
                "code": "610300",
                "name": "宝鸡",
                "fullname": "宝鸡市",
                "character": "B",
                "pinyin": "bao ji",
                "lng": 107.23732,
                "lat": 34.36194,
                "provinceCode": null,
                
            },
            {
                "code": "610400",
                "name": "咸阳",
                "fullname": "咸阳市",
                "character": "X",
                "pinyin": "xian yang",
                "lng": 108.70929,
                "lat": 34.32932,
                "provinceCode": null,
                
            },
            {
                "code": "610500",
                "name": "渭南",
                "fullname": "渭南市",
                "character": "W",
                "pinyin": "wei nan",
                "lng": 109.51015,
                "lat": 34.49997,
                "provinceCode": null,
                
            },
            {
                "code": "610600",
                "name": "延安",
                "fullname": "延安市",
                "character": "Y",
                "pinyin": "yan an",
                "lng": 109.48978,
                "lat": 36.58529,
                "provinceCode": null,
                
            },
            {
                "code": "610700",
                "name": "汉中",
                "fullname": "汉中市",
                "character": "H",
                "pinyin": "han zhong",
                "lng": 107.02377,
                "lat": 33.06761,
                "provinceCode": null,
                
            },
            {
                "code": "610800",
                "name": "榆林",
                "fullname": "榆林市",
                "character": "Y",
                "pinyin": "yu lin",
                "lng": 109.73458,
                "lat": 38.2852,
                "provinceCode": null,
                
            },
            {
                "code": "610900",
                "name": "安康",
                "fullname": "安康市",
                "character": "A",
                "pinyin": "an kang",
                "lng": 109.02932,
                "lat": 32.68486,
                "provinceCode": null,
                
            },
            {
                "code": "611000",
                "name": "商洛",
                "fullname": "商洛市",
                "character": "S",
                "pinyin": "shang luo",
                "lng": 109.94041,
                "lat": 33.87036,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "620000",
        "name": "甘肃",
        "fullname": "甘肃省",
        "character": "G",
        "pinyin": "gan su",
        "lng": 103.82634,
        "lat": 36.05942,
        "municipality": null,
        "children": [
            {
                "code": "620100",
                "name": "兰州",
                "fullname": "兰州市",
                "character": "L",
                "pinyin": "lan zhou",
                "lng": 103.83417,
                "lat": 36.06138,
                "provinceCode": null,
                
            },
            {
                "code": "620200",
                "name": "嘉峪关",
                "fullname": "嘉峪关市",
                "character": "J",
                "pinyin": "jia yu guan",
                "lng": 98.28971,
                "lat": 39.77194,
                "provinceCode": null,
                
            },
            {
                "code": "620300",
                "name": "金昌",
                "fullname": "金昌市",
                "character": "J",
                "pinyin": "jin chang",
                "lng": 102.18759,
                "lat": 38.52006,
                "provinceCode": null,
                
            },
            {
                "code": "620400",
                "name": "白银",
                "fullname": "白银市",
                "character": "B",
                "pinyin": "bai yin",
                "lng": 104.13773,
                "lat": 36.5447,
                "provinceCode": null,
                
            },
            {
                "code": "620500",
                "name": "天水",
                "fullname": "天水市",
                "character": "T",
                "pinyin": "tian shui",
                "lng": 105.72486,
                "lat": 34.58085,
                "provinceCode": null,
                
            },
            {
                "code": "620600",
                "name": "武威",
                "fullname": "武威市",
                "character": "W",
                "pinyin": "wu wei",
                "lng": 102.63797,
                "lat": 37.9282,
                "provinceCode": null,
                
            },
            {
                "code": "620700",
                "name": "张掖",
                "fullname": "张掖市",
                "character": "Z",
                "pinyin": "zhang ye",
                "lng": 100.44981,
                "lat": 38.92592,
                "provinceCode": null,
                
            },
            {
                "code": "620800",
                "name": "平凉",
                "fullname": "平凉市",
                "character": "P",
                "pinyin": "ping liang",
                "lng": 106.6653,
                "lat": 35.54303,
                "provinceCode": null,
                
            },
            {
                "code": "620900",
                "name": "酒泉",
                "fullname": "酒泉市",
                "character": "J",
                "pinyin": "jiu quan",
                "lng": 98.49394,
                "lat": 39.73255,
                "provinceCode": null,
                
            },
            {
                "code": "621000",
                "name": "庆阳",
                "fullname": "庆阳市",
                "character": "Q",
                "pinyin": "qing yang",
                "lng": 107.64292,
                "lat": 35.70978,
                "provinceCode": null,
                
            },
            {
                "code": "621100",
                "name": "定西",
                "fullname": "定西市",
                "character": "D",
                "pinyin": "ding xi",
                "lng": 104.62524,
                "lat": 35.58113,
                "provinceCode": null,
                
            },
            {
                "code": "621200",
                "name": "陇南",
                "fullname": "陇南市",
                "character": "L",
                "pinyin": "long nan",
                "lng": 104.92166,
                "lat": 33.401,
                "provinceCode": null,
                
            },
            {
                "code": "622900",
                "name": "临夏",
                "fullname": "临夏回族自治州",
                "character": "L",
                "pinyin": "lin xia",
                "lng": 103.21091,
                "lat": 35.60122,
                "provinceCode": null,
                
            },
            {
                "code": "623000",
                "name": "甘南",
                "fullname": "甘南藏族自治州",
                "character": "G",
                "pinyin": "gan nan",
                "lng": 102.91102,
                "lat": 34.98327,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "630000",
        "name": "青海",
        "fullname": "青海省",
        "character": "Q",
        "pinyin": "qing hai",
        "lng": 101.78011,
        "lat": 36.62087,
        "municipality": null,
        "children": [
            {
                "code": "630100",
                "name": "西宁",
                "fullname": "西宁市",
                "character": "X",
                "pinyin": "xi ning",
                "lng": 101.77782,
                "lat": 36.61729,
                "provinceCode": null,
                
            },
            {
                "code": "630200",
                "name": "海东",
                "fullname": "海东市",
                "character": "H",
                "pinyin": "hai dong",
                "lng": 102.40173,
                "lat": 36.48209,
                "provinceCode": null,
                
            },
            {
                "code": "632200",
                "name": "海北",
                "fullname": "海北藏族自治州",
                "character": "H",
                "pinyin": "hai bei",
                "lng": 100.90096,
                "lat": 36.95454,
                "provinceCode": null,
                
            },
            {
                "code": "632300",
                "name": "黄南",
                "fullname": "黄南藏族自治州",
                "character": "H",
                "pinyin": "huang nan",
                "lng": 102.01507,
                "lat": 35.51991,
                "provinceCode": null,
                
            },
            {
                "code": "632500",
                "name": "海南",
                "fullname": "海南藏族自治州",
                "character": "H",
                "pinyin": "hai nan",
                "lng": 100.62037,
                "lat": 36.28663,
                "provinceCode": null,
                
            },
            {
                "code": "632600",
                "name": "果洛",
                "fullname": "果洛藏族自治州",
                "character": "G",
                "pinyin": "guo luo",
                "lng": 100.24475,
                "lat": 34.47141,
                "provinceCode": null,
                
            },
            {
                "code": "632700",
                "name": "玉树",
                "fullname": "玉树藏族自治州",
                "character": "Y",
                "pinyin": "yu shu",
                "lng": 97.0065,
                "lat": 33.00528,
                "provinceCode": null,
                
            },
            {
                "code": "632800",
                "name": "海西",
                "fullname": "海西蒙古族藏族自治州",
                "character": "H",
                "pinyin": "hai xi",
                "lng": 97.37122,
                "lat": 37.3771,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "640000",
        "name": "宁夏",
        "fullname": "宁夏回族自治区",
        "character": "N",
        "pinyin": "ning xia",
        "lng": 106.25867,
        "lat": 38.47117,
        "municipality": null,
        "children": [
            {
                "code": "640100",
                "name": "银川",
                "fullname": "银川市",
                "character": "Y",
                "pinyin": "yin chuan",
                "lng": 106.23248,
                "lat": 38.48644,
                "provinceCode": null,
                
            },
            {
                "code": "640200",
                "name": "石嘴山",
                "fullname": "石嘴山市",
                "character": "S",
                "pinyin": "shi zui shan",
                "lng": 106.38418,
                "lat": 38.9841,
                "provinceCode": null,
                
            },
            {
                "code": "640300",
                "name": "吴忠",
                "fullname": "吴忠市",
                "character": "W",
                "pinyin": "wu zhong",
                "lng": 106.19879,
                "lat": 37.99755,
                "provinceCode": null,
                
            },
            {
                "code": "640400",
                "name": "固原",
                "fullname": "固原市",
                "character": "G",
                "pinyin": "gu yuan",
                "lng": 106.24259,
                "lat": 36.0158,
                "provinceCode": null,
                
            },
            {
                "code": "640500",
                "name": "中卫",
                "fullname": "中卫市",
                "character": "Z",
                "pinyin": "zhong wei",
                "lng": 105.19676,
                "lat": 37.50026,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "650000",
        "name": "新疆",
        "fullname": "新疆维吾尔自治区",
        "character": "X",
        "pinyin": "xin jiang",
        "lng": 87.6271,
        "lat": 43.79343,
        "municipality": null,
        "children": [
            {
                "code": "650100",
                "name": "乌鲁木齐",
                "fullname": "乌鲁木齐市",
                "character": "W",
                "pinyin": "wu lu mu qi",
                "lng": 87.61688,
                "lat": 43.82663,
                "provinceCode": null,
                
            },
            {
                "code": "650200",
                "name": "克拉玛依",
                "fullname": "克拉玛依市",
                "character": "K",
                "pinyin": "ke la ma yi",
                "lng": 84.88927,
                "lat": 45.57999,
                "provinceCode": null,
                
            },
            {
                "code": "650400",
                "name": "吐鲁番",
                "fullname": "吐鲁番市",
                "character": "T",
                "pinyin": "tu lu fan",
                "lng": 89.18954,
                "lat": 42.9513,
                "provinceCode": null,
                
            },
            {
                "code": "650500",
                "name": "哈密",
                "fullname": "哈密市",
                "character": "H",
                "pinyin": "ha mi",
                "lng": 93.51538,
                "lat": 42.81855,
                "provinceCode": null,
                
            },
            {
                "code": "652300",
                "name": "昌吉",
                "fullname": "昌吉回族自治州",
                "character": "C",
                "pinyin": "chang ji",
                "lng": 87.30822,
                "lat": 44.01117,
                "provinceCode": null,
                
            },
            {
                "code": "652700",
                "name": "博州",
                "fullname": "博尔塔拉蒙古自治州",
                "character": "B",
                "pinyin": "bo zhou",
                "lng": 82.06665,
                "lat": 44.90597,
                "provinceCode": null,
                
            },
            {
                "code": "652800",
                "name": "巴州",
                "fullname": "巴音郭楞蒙古自治州",
                "character": "B",
                "pinyin": "ba zhou",
                "lng": 86.14517,
                "lat": 41.76404,
                "provinceCode": null,
                
            },
            {
                "code": "652900",
                "name": "阿克苏",
                "fullname": "阿克苏地区",
                "character": "A",
                "pinyin": "a ke su",
                "lng": 80.26008,
                "lat": 41.16842,
                "provinceCode": null,
                
            },
            {
                "code": "653000",
                "name": "克州",
                "fullname": "克孜勒苏柯尔克孜自治州",
                "character": "K",
                "pinyin": "ke zhou",
                "lng": 76.16661,
                "lat": 39.7153,
                "provinceCode": null,
                
            },
            {
                "code": "653100",
                "name": "喀什",
                "fullname": "喀什地区",
                "character": "K",
                "pinyin": "ka shi",
                "lng": 75.98976,
                "lat": 39.47042,
                "provinceCode": null,
                
            },
            {
                "code": "653200",
                "name": "和田",
                "fullname": "和田地区",
                "character": "H",
                "pinyin": "he tian",
                "lng": 79.92247,
                "lat": 37.11431,
                "provinceCode": null,
                
            },
            {
                "code": "654000",
                "name": "伊犁",
                "fullname": "伊犁哈萨克自治州",
                "character": "Y",
                "pinyin": "yi li",
                "lng": 81.32416,
                "lat": 43.91689,
                "provinceCode": null,
                
            },
            {
                "code": "654200",
                "name": "塔城",
                "fullname": "塔城地区",
                "character": "T",
                "pinyin": "ta cheng",
                "lng": 82.98046,
                "lat": 46.74532,
                "provinceCode": null,
                
            },
            {
                "code": "654300",
                "name": "阿勒泰",
                "fullname": "阿勒泰地区",
                "character": "A",
                "pinyin": "a le tai",
                "lng": 88.14023,
                "lat": 47.84564,
                "provinceCode": null,
                
            }
        ]
    },
    {
        "code": "710000",
        "name": "台湾",
        "fullname": "台湾省",
        "character": "T",
        "pinyin": "tai wan",
        "lng": 121.520076,
        "lat": 25.030724,
        "municipality": null,
        "children": [
            {
                "code": "710100",
                "name": "台北",
                "fullname": "台北市",
                "character": "T",
                "pinyin": "tai bei",
                "lng": 121.520076,
                "lat": 25.030724,
                "provinceCode": null,
                
            },
            {
                "code": "710200",
                "name": "高雄",
                "fullname": "高雄市",
                "character": "G",
                "pinyin": "gao xiong",
                "lng": 120.306839,
                "lat": 22.630576,
                "provinceCode": null,
                
            },
            {
                "code": "710300",
                "name": "台南",
                "fullname": "台南市",
                "character": "T",
                "pinyin": "tai nan",
                "lng": 120.187817,
                "lat": 22.998601,
                "provinceCode": null,
                
            },
            {
                "code": "710400",
                "name": "台中",
                "fullname": "台中市",
                "character": "T",
                "pinyin": "tai zhong",
                "lng": 120.679882,
                "lat": 24.143171,
                "provinceCode": null,
                
            },
            {
                "code": "710600",
                "name": "南投",
                "fullname": "南投县",
                "character": "N",
                "pinyin": "nan tou",
                "lng": 120.670008,
                "lat": 23.919619,
                "provinceCode": null,
                
            },
            {
                "code": "710700",
                "name": "基隆",
                "fullname": "基隆市",
                "character": "J",
                "pinyin": "ji long",
                "lng": 121.741526,
                "lat": 25.122105,
                "provinceCode": null,
                
            },
            {
                "code": "710800",
                "name": "新竹",
                "fullname": "新竹市",
                "character": "X",
                "pinyin": "xin zhu",
                "lng": 120.990745,
                "lat": 24.784924,
                "provinceCode": null,
                
            },
            {
                "code": "710900",
                "name": "嘉义",
                "fullname": "嘉义市",
                "character": "J",
                "pinyin": "jia yi",
                "lng": 120.472462,
                "lat": 23.485079,
                "provinceCode": null,
                
            },
            {
                "code": "711100",
                "name": "新北",
                "fullname": "新北市",
                "character": "X",
                "pinyin": "xin bei",
                "lng": 121.663675,
                "lat": 25.1853,
                "provinceCode": null,
                
            },
            {
                "code": "711200",
                "name": "宜兰",
                "fullname": "宜兰县",
                "character": "Y",
                "pinyin": "yi lan",
                "lng": 121.754442,
                "lat": 24.759707,
                "provinceCode": null,
                
            },
            {
                "code": "711300",
                "name": "新竹",
                "fullname": "新竹县",
                "character": "X",
                "pinyin": "xin zhu",
                "lng": 121.002012,
                "lat": 24.839233,
                "provinceCode": null,
                
            },
            {
                "code": "711400",
                "name": "桃园",
                "fullname": "桃园市",
                "character": "T",
                "pinyin": "tao yuan",
                "lng": 121.213608,
                "lat": 24.982757,
                "provinceCode": null,
                
            },
            {
                "code": "711500",
                "name": "苗栗",
                "fullname": "苗栗县",
                "character": "M",
                "pinyin": "miao li",
                "lng": 120.884337,
                "lat": 24.696762,
                "provinceCode": null,
                
            },
            {
                "code": "711700",
                "name": "彰化",
                "fullname": "彰化县",
                "character": "Z",
                "pinyin": "zhang hua",
                "lng": 120.557479,
                "lat": 24.068523,
                "provinceCode": null,
                
            },
            {
                "code": "711900",
                "name": "嘉义",
                "fullname": "嘉义县",
                "character": "J",
                "pinyin": "jia yi",
                "lng": 120.624255,
                "lat": 23.434473,
                "provinceCode": null,
                
            },
            {
                "code": "712100",
                "name": "云林",
                "fullname": "云林县",
                "character": "Y",
                "pinyin": "yun lin",
                "lng": 120.480738,
                "lat": 23.664943,
                "provinceCode": null,
                
            },
            {
                "code": "712400",
                "name": "屏东",
                "fullname": "屏东县",
                "character": "P",
                "pinyin": "ping dong",
                "lng": 120.492005,
                "lat": 22.666716,
                "provinceCode": null,
                
            },
            {
                "code": "712500",
                "name": "台东",
                "fullname": "台东县",
                "character": "T",
                "pinyin": "tai dong",
                "lng": 121.113207,
                "lat": 22.764364,
                "provinceCode": null,
                
            },
            {
                "code": "712600",
                "name": "花莲",
                "fullname": "花莲县",
                "character": "H",
                "pinyin": "hua lian",
                "lng": 121.59729,
                "lat": 24.000674,
                "provinceCode": null,
                
            },
            {
                "code": "712700",
                "name": "澎湖",
                "fullname": "澎湖县",
                "character": "P",
                "pinyin": "peng hu",
                "lng": 119.58457,
                "lat": 23.552351,
                "provinceCode": null,
                
            }
        ]
    }
]

export {
    geo 
}