
const  zhCN = {}
const en= {}

// 中文
const zhFiles = require.context('./zh_CN', true, /\.js$/)
// 英文
const cnFiles = require.context('./en', true, /\.js$/)

zhFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = zhFiles(modulePath)
    zhCN[moduleName] = value.default
    return modules
}, {})

cnFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = cnFiles(modulePath)
    en[moduleName] = value.default
    return modules
}, {})


//导出所有翻译内容
export default {
    en,  zh: zhCN
}