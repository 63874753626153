<template>
  <el-container style="min-height: 100vh">
    <el-header>
      <Header @topicSelecedEvent="topicSeleced" />
    </el-header>

    <el-main>
      <Main :activeTopic="activeTopic" />
    </el-main>

    <el-footer>
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>
import Header from './layout/AppHeader.vue'
import Footer from './layout/AppFooter.vue'
import Main from './layout/AppMain.vue'
import bus from '@/bus'
import {isChinese} from "@/utils";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Main
  },
  data() {
    return {
      activeTopic: '首页'
    }
  },
  methods: {
    topicSeleced(topic) {
      this.activeTopic = topic

      bus.emit('NAV_MENU_CHANGE', topic === '商户')
    },

    windowWidth() {
      window.screenWidth = document.body.clientWidth
      bus.emit('SCREEN_WIDTH_CHANGE', window.screenWidth)
    }
  },
  created() {
    this.topicSeleced('首页')
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    bus.on('HOME_SELECT_SOLUTION', key => {
      this.topicSeleced('成功案例')
    })

    this.windowWidth()
    window.onresize = () => {
      return (() => {
        this.windowWidth()
        // window.screenWidth = document.body.clientWidth
        // bus.emit('SCREEN_WIDTH_CHANGE', window.screenWidth)
      })()
    }

    // 设置标题
    if (isChinese()) {
      document.title  ='任我充'
    } else {
      document.title = 'HT-ICHARGE'
    }

  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
}

.el-footer, .el-header, .el-main {
  padding: 0 !important;
  height: auto !important;
}


.el-main {
  overflow: hidden;
  max-width: 2560px;
  width: 100%;
  margin: 0 auto;
}
</style>
