import { createApp } from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import i18n from "@/lang/i18n";
import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

import App from './App.vue'

import '@/styles/index.css'

createApp(App)
.use(ElementPlus)
.use(VideoPlayer)
    .use(i18n)
.mount('#app')
