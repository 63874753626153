<template>
  <div class="product-en">
    <el-image class='banner-img' :src="bannerUrl" :fit="'contain'"></el-image>

    <template v-if="!isPhone">
      <!---第一个-->
      <div class="container">
        <div class="left">
          <el-image :src="product_1_url" class="img"/>
        </div>
        <div class="center">
          <div class="title">Integrated Two Connectors DC Charger</div>
          <div class="type-list">
            <div class="item">TYPE</div>
            <div class="item" :class="pChecked[0] ? 'checked' : 'unchecked'" @click="choose(0)">European standard
              60KW
            </div>
            <div class="item" :class="pChecked[1] ? 'checked' : 'unchecked'" @click="choose(1)">European standard
              120kW
            </div>
          </div>
          <div class="content-list">
            <div class="content-item">
              <div class="content-title">Efficient</div>
              <ul>
                <li>Multiple and simultaneous output.</li>
                <li>
                  {{ pChecked[0] ? 'Output up to 60kW in total, flexible and upgradable.' : 'Output up to 120kW in total, flexible and upgradable.' }}
                </li>
                <li>Wide voltage range: 200-1000V DC.</li>
              </ul>
            </div>
            <div class="content-item">
              <div class="content-title">Safe</div>
              <ul>
                <li>Emergency stop button for urgent situation.</li>
                <li>Multiple protection functions e.g.overvoltage protection, short-circuit protection etc.</li>
                <li>Suitable for both indoor and outdoor use.</li>
              </ul>
            </div>
            <div class="content-item">
              <div class="content-title">Convenient</div>
              <ul>
                <li>True color screen, high definition.</li>
                <li>Modular design, easy for O&M.</li>
                <li>Easy transportation and installation.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="circle" :class="pChecked[0] ? 'checked' : 'unchecked'" @click="choose(0)"></div>
          <div class="circle" :class="pChecked[1] ? 'checked' : 'unchecked'" @click="choose(1)"></div>
        </div>
      </div>


      <!--第二个-->
      <div class="container">
        <div class="center" style="margin-left:0 !important;">
          <div class="title">Integrated Three Connectors DC Charger</div>
          <div class="type-list">
            <div class="item">TYPE</div>
            <div class="item" :class="pChecked1[0] ? 'checked' : 'unchecked'" @click="choose(0,'pChecked1')">European
              standard 22-60kW
            </div>
            <div class="item" :class="pChecked1[1] ? 'checked' : 'unchecked'" @click="choose(1,'pChecked1')">European
              standard 22-120KW
            </div>
          </div>
          <div class="content-list">
            <div class="content-item">
              <div class="content-title">Efficient</div>
              <ul>
                <li>Multiple and simultaneous output.</li>
                <li>
                  {{ pChecked1[0] ? 'Output up to 60kW in total, flexible and upgradable' : 'Output up to 120kW in total, flexible and upgradable.' }}
                </li>
                <li>Wide voltage range: 200-1000V DC.</li>
              </ul>
            </div>
            <div class="content-item">
              <div class="content-title">Safe</div>
              <ul>
                <li>Emergency stop button for urgent situation.</li>
                <li>Multiple protection functions e.g. overvoltage protection,short-circuit protection etc.</li>
                <li>Suitable for both indoor and outdoor use.</li>
              </ul>
            </div>
            <div class="content-item">
              <div class="content-title">Convenient</div>
              <ul>
                <li>True color screen, high definition.</li>
                <li>Modular design, easy for O&M.</li>
                <li>Easy transportation and installation.</li>
              </ul>
            </div>

          </div>
        </div>
        <div class="left to-right">
          <el-image :src="product_2_url" class="img"/>
        </div>

        <div class="right">
          <div class="circle" :class="pChecked1[0] ? 'checked' : 'unchecked'"
               @click="choose(0,'pChecked1')"></div>
          <div class="circle" :class="pChecked1[1] ? 'checked' : 'unchecked'"
               @click="choose(1,'pChecked1')"></div>
        </div>
      </div>

      <!--第三个-->
      <div class="ac-container">
        <div class="title">AC Charger</div>
        <div class="type">TYPE</div>
        <div class="content-list">
          <div class="content-item">
            <div class="type-list">
              <div class="item ">European standard 7kW</div>
              <div class="item ">European standard 11kW</div>
            </div>
            <div class="item">
              <el-image :src="product_3_url" class="img"/>
              <div class="content">
                <div>
                  <div class="content-title">Intelligent</div>
                  <ul>
                    <li>Wireless communication(WiFi).</li>
                    <li>OCPP communication.</li>
                  </ul>
                </div>

                <div>
                  <div class="content-title">Safety</div>
                  <ul>
                    <li>Residual current protection.</li>
                    <li>Surge protection etc.</li>
                  </ul>
                </div>

                <div>
                  <div class="content-title">Flexible</div>
                  <ul>
                    <li>Type 2 charging cable.</li>
                    <li>RFlD authentication.</li>
                    <li>Wall-mount/Floor-stand installation.</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <div class="content-item">
            <div class="type-list">
              <div class="item checked">European standard 22kW</div>
            </div>
            <div class="item">
              <el-image :src="product_4_url" class="img-1"/>
              <div class="content">
                <div>
                  <div class="content-title">Intelligent</div>
                  <ul>
                    <li>Wireless communication(WiFi).</li>
                    <li>OCPP communication protocol with CMS.</li>
                  </ul>
                </div>

                <div>
                  <div class="content-title">Safety</div>
                  <ul>
                    <li>Residual current protection.</li>
                    <li>Surge protection etc.</li>
                  </ul>
                </div>

                <div>
                  <div class="content-title">Flexible</div>
                  <ul>
                    <li>Universal Type 2 socket, Type 2 charging cable optional.</li>
                    <li>Wall-mount/Floor-stand installation.</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </template>

    <!--移动端-->
    <ProductEnPhone v-else/>


  </div>
</template>

<script>
import bus from '@/bus'
import ProductEnPhone from "../views/ProductEnPhone.vue";

export default {
  name: 'product',
  components: {
    ProductEnPhone
  },
  methods: {
    choose(val, type = 'pChecked') {
      if (this[type][val]) return
      this[type].fill(false)
      this[type][val] = true
    }
  },
  data() {
    return {
      isPhone: false,
      pChecked: [true, false],
      pChecked1: [true, false],
      bannerUrl: process.env.VUE_APP_OSS_URL + '/product_banner_en.png',
      product_1_url: process.env.VUE_APP_OSS_URL + '/product/product_1.png',
      product_2_url: process.env.VUE_APP_OSS_URL + '/product/product_2.png',
      product_3_url: process.env.VUE_APP_OSS_URL + '/product/product_3.png',
      product_4_url: process.env.VUE_APP_OSS_URL + '/product/product_4.png',
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    bus.on('SCREEN_WIDTH_CHANGE', key => {
      this.isPhone = key <= 600
      if (this.isPhone) {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/phone/product_banner_en.png'
      } else {
        this.bannerUrl= process.env.VUE_APP_OSS_URL + '/product_banner_en.png'
      }
    })
  }
}
</script>

<style scoped>
</style>