<template>
    <template v-if="Array.isArray(item)">
        <div v-for="(qr, index) in item" :key="qr" :style="{ marginRight: index != (item.length - 1) ? distance + '%' : 0}" @click="onClick(qr)" class="qrcode-item">
            <div class="container-center">
                <el-image :src="qr.qrcode" :style="{ width: qrcodeSize + 'px', height: qrcodeSize + 'px'}"></el-image>
            </div>
            <div class="container-center qrcode-desc" :style="{ margin: imgDescDis + 'px 0', color: '#' + fontColor }">{{ qr.desc }}</div>
        </div>
    </template>
    <template v-else>
        <div class="container-center qrcode-item">
            <el-image :src="item.qrcode" :style="{ width: qrcodeSize + 'px', height: qrcodeSize + 'px'}"></el-image>
        </div>
        <div class="container-center qrcode-desc" :style="{ margin: imgDescDis + 'px 0', color: '#' + fontColor }">{{ item.desc }}</div>
    </template>
</template>

<script>
export default {
    name: 'qrcode-item',
    props: {
        // 展示项
        item: {
            type: [Object, Array]
        },
        // 间隙百分比
        distance: {
            type: Number,
            default: 0
        },
        // 图片大小
        qrcodeSize: {
            type: Number,
            default: 100
        },
        // 描述字体颜色
        fontColor: {
            type: String,
            default: '333'
        },
        // 描述与图片间隙
        imgDescDis: {
            type: Number,
            default: 20
        }
    },
    methods: {
        onClick(qr) {
            if (qr.toUrl) {
                window.open(qr.toUrl)
            }
        }
    }
}
</script>

<style scoped>

/* .qrcode-img {
  width: 117px;
  height: 117px;
} */

.qrcode-desc {
  font-size: 24px;
  font-weight: 400;
  /* color: #717171; */
  line-height: 30px;
  /* margin: 27px 0; */
}

</style>