<template>
  <footer class="footer">
    <div class="footer-context">
      <p class="margin-top-0" :style="{color:isChinese() ? '' :'#999999' }">{{ company }}
        <!-- 备案号：
       <a href="https://beian.miit.gov.cn/" style="color: #DEDEDE;text-decoration: none;" target="_blank">苏ICP备15008760号-3</a> -->
        <span class="footer-icp">{{ $t('copyRight.record') }}：<a href="https://beian.miit.gov.cn/"
                                                                 :style="{color:isChinese() ? '#DEDEDE' :'#999999' }"
                                                                 style="text-decoration: none"
                                                                 target="_blank">{{ companyICP || '--' }}</a></span>
      </p>
      <p>{{ isChinese() ? '电话：400-118-5655' : 'Email: icharge@hengtonggroup.com.cn' }}</p>
      <p v-if="needSecond">{{ $t('copyRight.companyName_1') }}</p>
      <p>{{ $t('copyRight.address') }}</p>
    </div>
  </footer>
</template>
<script>
import {isChinese} from "@/utils";

export default {
  name: 'app-footer',
  methods: {isChinese},
  data() {
    return {
      needSecond: true,
      company: this.$t('copyRight.companyName'),
      companyICP: this.$t('copyRight.companyICP')
    }
  },
  created() {
    // 根据域名显示内容
    console.log('hostname : ', window.location.hostname)
    const hn = window.location.hostname
    if (hn.indexOf('renwochong.cn') !== -1) {
      if (isChinese()) {
        this.company = '亨通慧充众联科技有限公司'
        this.companyICP = '沪ICP备15017026号-3'
      } else {
        this.company = 'HT-Icharge Technology Co., Ltd'
        // this.companyICP = this.$t('copyRight.companyICP_1')
      }
      // this.company = this.$t('copyRight.companyName_1')
      // this.companyICP = this.$t('copyRight.companyICP_1')
      this.needSecond = false
    }
  }
}
</script>
<style scoped>
.footer {
  width: 100%;
  height: 211px;
  background-color: #4E4E4E;
  text-align: center;
}

.footer:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  /* margin-right: -0.25em; */
}

.footer-context {
  /* width: 395px; */
  /* height: 103px; */
  display: inline-block;
  vertical-align: middle;
  color: #DEDEDE;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.footer-icp {
  margin-left: 10px;
}

.margin-top-0 {
  margin-top: 0;
}
</style>
