<template>
  <div class="corp">
    <div class="banner-container">
      <el-image class='banner-img' :src="bannerUrl" :fit="'contain'"></el-image>
      <el-button class="banner-btn" @click="corpLogin">企业登录</el-button>
    </div>
    
    <item-title :item="showItem[0]" />
    <div class="cnt-item-container">
      <div class="cnt-item left-top">
        <div :class="'cnt-item-category container-center ' + item.pos" v-for="item in cntItem[0]" :key="item">{{ item.title }}</div>
      </div>
      <div class="cnt-item right-top">
        <el-image :src="imgItem[0]" :fit="'contain'"></el-image>
      </div>
      <div class="cnt-item left-bottom">
        <el-image :src="imgItem[1]" :fit="'contain'"></el-image>
      </div>
      <div class="cnt-item right-bottom">
        <div :class="'cnt-item-category container-center ' + item.pos" v-for="item in cntItem[1]" :key="item">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/bus'
import ItemTitle from "../components/ItemTitle"
export default {
  name: 'corp',
  components: {
    ItemTitle
  },
  data() {
    return {
      bannerUrl: process.env.VUE_APP_OSS_URL + '/corp_banner.png',
      showItem: [
        {
          icon: require('../assets/corp/corp_web.png'),
          title: '企业管理后台'
        }
      ],

      cntItem: [
        [{
          title: '订单管理',
          pos: 'left-top'
        }, {
          title: '多维报表',
          pos: 'right-top'
        }, {
          title: '组织结构管理',
          pos: 'left-bottom'
        }, {
          title: '在线开票',
          pos: 'right-bottom'
        }],
        [{
          title: '授信管理',
          pos: 'left-top'
        }, {
          title: '在线充值',
          pos: 'right-top'
        }, {
          title: '充电卡管理',
          pos: 'left-bottom'
        }, {
          title: '车辆管理',
          pos: 'right-bottom'
        }]
      ],

      imgItem: [
        process.env.VUE_APP_OSS_URL + '/corp/img_item_1.png',
        process.env.VUE_APP_OSS_URL + '/corp/img_item_2.png'
      ]
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    bus.on('SCREEN_WIDTH_CHANGE', key => {
      let isPhone = key <= 600
      if (isPhone) {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/phone/corp_banner.png'
      } else {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/corp_banner.png'
      }
    })
  },
  methods: {

    corpLogin() {
      // let url = 'https://corp.renwochong.com/'
      window.open(process.env.VUE_APP_CORP)
    },

  }
}
</script>

<style scoped>
.corp, .banner-img {
  width: 100%;
}

.banner-container {
  width: 100%;
  position: relative;
}

.banner-btn {
  position: absolute;
  background: #77BDFD;
  box-shadow: 0px 2px 21px 0px #000;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFF;
  border: 1px solid #77BDFD;
}

.cnt-item-container {
  margin: 30px auto 100px;
  position: relative;
}

.cnt-item {
  position: relative;
  position: absolute;
}

.cnt-item-category {
  background: #EBF4F0;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2F4050;
  position: absolute;
}
</style>