<template>
  <main class="main">
    <transition name="el-fade-in-linear">
      <Home v-show="['首页','Home'].includes(activeTopic)" />
    </transition>

    <transition name="el-fade-in-linear">
      <Product v-show="['产品中心'].includes(activeTopic)" />
    </transition>

    <transition name="el-fade-in-linear">
      <ProductEn v-show="['Products'].includes(activeTopic)" />
    </transition>

    <transition name="el-fade-in-linear">
      <Support v-show="['Service And Support'].includes(activeTopic)" />
    </transition>

    <transition name="el-fade-in-linear">
      <Story v-show="['成功案例','Solutions'].includes(activeTopic)" />
    </transition>

    <transition name="el-fade-in-linear">
      <Commercial v-show="activeTopic === '商户'" />
    </transition>

    <transition name="el-fade-in-linear">
      <Corp v-show="activeTopic === '企业'" />
    </transition>

    <transition name="el-fade-in-linear">
      <AboutUs v-show="['关于我们','About Us'].includes(activeTopic)" />
    </transition>
  </main>
</template>

<script>
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import Story from '../views/Story.vue'
import Commercial from '../views/Commercial.vue'
import Corp from '../views/Corp.vue'
import AboutUs from '../views/AboutUs.vue'
import Support from '../views/support.vue'
import ProductEn from "../views/ProductEn.vue";

export default {
  name: 'app-main',
  components: {
    Home,
    Product,
    Story,
    Commercial,
    Corp,
    AboutUs,
    Support,
    ProductEn
  },
  props: {
    activeTopic: {
      type: String,
      default: '首页'
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style>
.main {
  width: 100%;
  height: auto;
  margin-top: 100px;
}
</style>