<template>
  <div class="support">
    <div class="title">6 GLOBAL SERVICE CENTERS</div>
    <div class="img" :style="{backgroundImage:`url(${isPhone ? pMapUrl : mapUrl})`}">


      <template v-for="(item,index) in list" :key="index">

        <el-tooltip
            placement="top-start"
            :effect="'dark'"
            trigger="hover"
        >
          <div class="item" :style="item.style"></div>
          <template #content>
            <template v-for="(item,index) in item.content" :key="index">
              <div :style="item.style">{{ item.title }}</div>
            </template>
          </template>
        </el-tooltip>
      </template>
    </div>
  </div>

</template>
<script>
import {ElTooltip} from "element-plus";
import bus from "@/bus";

export default {
  name: "support",
  components: {ElTooltip},
  data() {
    return {
      mapUrl: process.env.VUE_APP_OSS_URL + '/ht_map.png',
      pMapUrl: process.env.VUE_APP_OSS_URL + '/ht_map_phone.png',
      isPhone: false,
      list: [
        {
          content: [{
            title: `Germany`,
            style: 'font-size: 18px;font-weight: 400;'
          }, {
            title: `J-fiber Hengtong GmbH`,
            style: 'text-decoration:underline;font-weight: 300;font-size: 15px;line-height:25px'
          }, {
            title: `Im Semmicht 1 07751 Jena, Germany`,
            style: 'font-size: 15px;line-height:25px'
          }],
          style: {
            left: '49%',
            top: '21%'
          }
        },
        {
          content: [
            {
              title: 'Spain',
              style: 'font-size: 15px;font-weight: 400;'
            },
            {
              title: 'Cables de Comunicaciones Zaragoza S.L.',
              style: 'text-decoration:underline;font-weight: 300;font-size: 15px;line-height:25px'
            },
            {
              title: 'http://www.aberdare.co.za/',
              style: 'font-size: 15px;line-height:25px'
            },
            {
              title: 'Polígono de Malpica,calle D,n°8350016-Zaragoza,España',
              style: 'font-size: 15px;line-height:25px'
            }],
          style: {
            left: '46%',
            top: '31%'
          }
        },
        {
          content: [
            {title: 'Portugal', style: 'font-size: 15px;font-weight: 400;'},
            {
              title: 'Alcobre Condutores Electricos S.A.',
              style: 'text-decoration:underline;font-weight: 300;font-size: 15px;line-height:25px'
            },
            {
              title: 'http://www.alcobre.pt/',
              style: 'font-size: 15px;line-height:25px'
            },
            {
              title: 'Rua Eng° Ferreira Dias n.°71, Zona Industrial de Ovar,3880-402 Ovar - Portugal',
              style: 'font-size: 15px;line-height:25px'
            }],
          style: {
            left: '43%',
            top: '35%'
          }
        },
        {
          content: [
            {title: 'South Africa', style: 'font-size: 15px;font-weight: 400;'},
            {
              title: 'Aberdare Cables(PTY)Ltd',
              style: 'text-decoration:underline;font-weight: 300;font-size: 15px;line-height:25px'
            },
            {
              title: 'http://www.aberdare.co.za/',
              style: 'font-size: 15px;line-height:25px'
            },
            {
              title: '181A Barbara Road, Elandsfontein, 1410,Johannesburg, South Africa',
              style: 'font-size: 15px;line-height:25px'
            }],
          style: {
            left: '51%',
            top: '73%'
          }
        },
        {
          content: [
            {title: 'Indonesia', style: 'font-size: 15px;font-weight: 400;'},
            {
              title: 'PT VOKSEL ELECTRIC Tbk',
              style: 'text-decoration:underline;font-weight: 300;font-size: 15px;line-height:25px'
            },
            {
              title: 'http://www.voksel.co.id',
              style: 'font-size: 15px;line-height:25px'
            },
            {
              title: 'JI.Raya Narogoog Km.16, Cileungsi,Bogor 16820,Indonesia',
              style: 'font-size: 15px;line-height:25px'
            },
            {
              title: 'PT MAJU BERSAMA GEMILANG',
              style: 'text-decoration:underline;font-weight: 300;font-size: 15px;line-height:25px'
            },
            {
              title: 'Kawasan Industri Kendal,Jalan Tanjung Anom No.93, Kelurahan,Brang-song, Kecamatan,Kaliwungu, Kabupaten Kendal, Jawa Tengah',
              style: 'font-size: 15px;line-height:25px'
            }
          ],
          style: {
            left: '75%',
            top: '60%'
          }
        },
      ]
    }
  },
  mounted() {
    bus.on('SCREEN_WIDTH_CHANGE', key => {
      this.isPhone = key <= 600
    })
  }
}
</script>


<style scoped>

</style>