export default {
    param_1: 'Features',
    param_2: 'Solution',
    param_3: 'Partners',
    param_4: 'Cloud monitoring',
    param_5: 'Real time status monitoring, real-time alarm notifications. Charging trend visible.',
    param_6: 'Smart operation and maintenance',
    param_7: 'Instant alarm notification, intelligent operation and maintenance work order system,automatic reminder of inspection, and operation and maintenance system.',
    param_8: 'Cloud operations',
    param_9: 'Remote management of pile configuration, batch remote upgrade of equipment,fully automatic timed start stop task.',
    param_10: 'KA customized system',
    param_11: 'Tailored subsystems have been developed for VIP customers, where administrators can perform online recharge, account reconciliation personnel, vehicle management, and other operations.',
    param_12: 'Cloud management',
    param_13: 'Intelligent control of power in cloud based stations, multi format display on PC/mobile devices,black/white list+parking timeout combination control.',
    param_14: 'Interconnection',
    param_15: 'It can share the site of the platform to other Internet platforms;It is also possible to incorporate sites from other operator platforms into the system.',
    param_16: 'Smart operations',
    param_17: 'Prepaid/post paid customized , coupon/service fee discount adjustable. Open to the internet charging service platforms.',
    param_18: 'Friendly interactive interface',
    param_19: 'Simple and user-friendly  interface design, easy to operate and based on a deep understanding of uer habits. ',
    param_20: '公交场站解决方案',
    param_21: '物流场站解决方案',
    param_22: '大巴车解决方案',
    param_23: '公共场站解决方案',
    param_24: '商业住宅小区解决方案',
    param_25: '出租车/网约车解决方案',

    param_26: 'Charging Capacity',
    param_27: 'Carbon Emission Reduction',
    param_28: 'Oil Saving',
    param_29: 'ton',
    param_30: 'litre'
}