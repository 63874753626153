<template>
  <div class="commercial">
    <div class="banner-container">
      <el-image class='banner-img' :src="bannerUrl" :fit="'contain'"></el-image>
      <div class="banner-cnt-container">
        <div v-html="'成为合作伙伴，<br />我们将为您提供最优质的平台服务'" class="banner-cnt_title"></div>
        <el-button class="banner-cnt_btn" @click="intention">我有意向</el-button>
      </div>
		<div class="login-container">
			<div class="login">
				<div class="login-title">商户登录</div>
				<div class="login-content">
					<el-button class="login-button bg1" @click="commLogin(1,'manage')" id="manage">充电管理云</el-button>
					<div class="line"></div>
					<el-button  class="login-button bg2" @click="commLogin(2,'pv')" id="pv">光伏管理云</el-button>
					<div class="line"></div>
					<el-button class="login-button bg3"  @click="commLogin(3,'ess')" id="ess">储能管理云</el-button>
					<div class="line"></div>
					<el-button  class="login-button bg4" @click="commLogin(4,'mg')" id="mg">光储充管理云</el-button>
				</div>
			</div>
		</div>
<!--      <el-button class="banner-btn" @click="commLogin">商户登录</el-button>-->
      <el-button class="p_banner-btn" @click="intention">我有意向</el-button>
    </div>

    <item-title :item="showItem[0]" />
    <!-- 手机端显示 -->
    <div class="case-item-container">
      <div class="case-item" v-for="item in webCnt[0]" :key="item">{{ item }}</div>
      <div class="case-item" v-for="item in webCnt[2]" :key="item">{{ item }}</div>
    </div>
    <div class="charger-web-container container-center">
      <div class="charger-web-menu">
        <div :class="'web-img-item-category container-center '" v-for="item in webCnt[0]" :key="item">{{ item }}</div>
      </div>

      <div class="charger-web-img">
        <div :class="'web-img-item ' + item.pos" v-for="item in webCnt[1].uiList" :key="item">
          <el-image :src="item.img" :fit="'contain'"></el-image>
        </div>

        <el-image class="web-img-center" :src="webCnt[1].centerImg" :fit="'contain'"></el-image>
      </div>

      <div class="charger-web-menu">
        <div :class="'web-img-item-category container-center '" v-for="item in webCnt[2]" :key="item">{{ item }}</div>
      </div>
    </div>

    <item-title :item="showItem[1]" />
    <div>
      <div class="evse-mgm-tips container-center">{{ showItem[1].tips }}</div>
      <div class="container-center margin-top-55">
        <el-image class="evse-mgm-img" :src="showItem[1].cnt"></el-image>
      </div>
      <div class="evse-mgm-qrcode">
        <QRCodeItem :item="showItem[1].qrItem[0]" :qrcodeSize="qrcodeSize + 20" />
      </div>
    </div>

    <item-title :item="showItem[2]" />
    <div class="item-container ">
      <div class="applet-ui-container container-center">
        <div class="icon-container">
          <div class="icon-info" v-for="icon in iconList[0]" :key="icon"
              :style="icon.style" @click="selectIcon(0, icon)" @mouseenter="selectIcon(0, icon)">
            <img class="icon-img el-image__inner" :src="icon.active ? icon.iconActive : icon.icon" />
            <div :class="'icon-title ' + (icon.active ? 'icon-active-color' : 'icon-inactive-color')" >{{ icon.title }}</div>
          </div>
        </div>

        <div class="app-page-container container-center" style="">
          <el-image :src="phoneFrameworkImg"></el-image>
          <el-image class="app-page-img" v-for="img in phoneImgList" :key="img" :src="img" style="position: absolute;" v-show="img.indexOf(activePhoneImg) != -1"></el-image>
        </div>

        <div class="icon-container">
           <div class="icon-info" v-for="icon in iconList[1]" :key="icon"
              :style="icon.style" @click="selectIcon(1, icon)" @mouseenter="selectIcon(1, icon)">
            <img class="icon-img el-image__inner" :src="icon.active ? icon.iconActive : icon.icon" />
            <div :class="'icon-title ' + (icon.active ? 'icon-active-color' : 'icon-inactive-color')" >{{ icon.title }}</div>
          </div>
        </div>
      </div>

      <div class="margin-top-50 container-center qrcode-list">
        <QRCodeItem :item="showItem[2].qrItem" :distance="distance" :qrcodeSize="qrcodeSize" :fontColor="'717171'"/>
      </div>
    </div>

    <el-dialog
      title="填写意向信息"
      v-model="visible"
      width="50%"
      destroy-on-close
      :before-close="handleClose"
      center>
      <span class="dialog-tips">填写准确的信息，方便我们更快的找到您哦！</span>

      <el-form :model="ruleForm" :rules="rules" ref="intentionForm">
        <el-form-item class="intention-content intention-2-label intention-cnt-width" label="姓 名" prop="name" style="width: 50%;display: inline-block;">
          <el-input v-model="ruleForm.name" placeholder="请填写姓名"></el-input>
        </el-form-item>
        <el-form-item class="intention-content intention-4-label intention-cnt-width" label="联系电话" prop="phone" style="width: 50%;display: inline-block;">
          <el-input v-model="ruleForm.phone" placeholder="请填写联系电话"></el-input>
        </el-form-item>
        <el-form-item class="intention-content intention-2-label" label="城 市" prop="address">
          <el-cascader
            placeholder="试试搜索：上海市"
            v-model="ruleForm.address"
            :props="{ value: 'code', label: 'fullname'}"
            :options="options"
            filterable clearable></el-cascader>
        </el-form-item>
        <el-form-item class="p_margin-left intention-cnt-margin intention-content" label="您当前情况或期望" prop="context">
          <el-checkbox-group
            v-model="ruleForm.context">
            <el-checkbox v-for="op in expectOp" :label="op" :key="op" style="width: 42%;">{{op}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item class="intention-cnt-margin intention-4-label" label="您的想法" prop="mind">
          <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4}" v-model="ruleForm.mind"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submitIntentionForm" :disabled="submitDisabled">提 交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { geo } from "@/geo.js"
import http from '@/http'
import ItemTitle from "../components/ItemTitle"
import QRCodeItem from "../components/QRCodeItem"
import { ElMessage } from 'element-plus'
import bus from '@/bus'
export default {
  name: 'commercial',
  components: {
    ItemTitle,
    QRCodeItem
  },
  computed: {
    getAddress: function() {
      return this.ruleForm.address
    }
  },
  watch: {
    getAddress(val) {
      if (val) {
        this.ruleForm.province = val[0]
        this.ruleForm.city = val[1]
      }
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    bus.on('NAV_MENU_CHANGE', key => {
      if (key) {
        if (this.timer) {
          clearInterval(this.timer)
        }

        this.selectIconTimer()
      } else {
        clearInterval(this.timer)
      }
    })

    // eslint-disable-next-line no-unused-vars
    bus.on('SCREEN_WIDTH_CHANGE', key => {
      let isPhone = key <= 600
      if (isPhone) {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/phone/comm_banner.png'

        this.qrcodeSize = 50
        this.distance = 8
        this.showItem = [
          {
            icon: require('../assets/comm/phone/comm_web.png'),
            title: '充电管理后台'
          }, {
            icon: require('../assets/comm/phone/comm_evse.png'),
            title: '桩管家',
            tips: '移动化办公，随时随地处理场站问题',
            cnt: process.env.VUE_APP_OSS_URL + '/comm/evse_mgm.png',
            qrItem: [{
              qrcode: process.env.VUE_APP_OSS_URL + '/comm/evse_mgm_qrcode.png',
              desc: '微信扫一扫，场站管理掌控在手'
            }]
          }, {
            icon: require('../assets/comm/phone/comm_app.png'),
            title: '用户端扫码充电',
            qrItem: [{
              qrcode: process.env.VUE_APP_OSS_URL + '/comm/wx_lite.png',
              desc: '微信小程序'
            }, {
              qrcode: process.env.VUE_APP_OSS_URL + '/comm/ali_lite.png',
              desc: '支付宝小程序'
            }, {
              qrcode: process.env.VUE_APP_OSS_URL + '/comm/app_ios.png',
              desc: 'IOS-APP',
              toUrl: 'https://charge.renwochong.com/index.html?commId=34474&from=web'
            }, {
              qrcode: process.env.VUE_APP_OSS_URL + '/comm/app_android.png',
              desc: '安卓-APP',
              toUrl: 'https://charge.renwochong.com/index.html?commId=34474&from=web'
            }]
          }
        ]

        this.iconList = [
          [{
              active: true,
              icon: require('../assets/comm/icon_site_search.png'),
              iconActive: require('../assets/comm/icon_site_search_active.png'),
              title: '地图找桩',
              style: {
                // margin-left: 182px;
                marginLeft: '50px'
              },
              uiImg: 'ui_site_search'
            }, {
              active: false,
              icon: require('../assets/comm/icon_site_detail.png'),
              iconActive: require('../assets/comm/icon_site_detail_active.png'),
              title: '场站详情',
              style: {
                // margin-top: 52px;
                marginTop: '10px'
              },
              uiImg: 'ui_site_detail'
            }, {
              active: false,
              icon: require('../assets/comm/icon_pay_way.png'),
              iconActive: require('../assets/comm/icon_pay_way_active.png'),
              title: '充电结算方式',
              style: {
                // margin-left: 215px;margin-top: -21px;
                marginLeft: '58px',
                marginTop: '-30px'
              },
              uiImg: 'ui_pay_way'
            }, {
              active: false,
              icon: require('../assets/comm/icon_charger_monitor.png'),
              iconActive: require('../assets/comm/icon_charger_monitor_active.png'),
              title: '充电中数据监控',
              style: {
                // margin-left: 71px;margin-top: 79px;
                marginLeft: '20px',
                marginTop: '10px'
              },
              uiImg: 'ui_charger_monitor'
          }], [{
              active: false,
              icon: require('../assets/comm/icon_order_detail.png'),
              iconActive: require('../assets/comm/icon_order_detail_active.png'),
              title: '充电订单详情',
              style: {
                // margin-left: 43px;
                marginLeft: '26px'
              },
              uiImg: 'ui_order_detail'
            }, {
              active: false,
              icon: require('../assets/comm/icon_order_list.png'),
              iconActive: require('../assets/comm/icon_order_list_active.png'),
              title: '历史充电订单',
              style: {
                // margin-left: 223px;margin-top: 71px;
                marginLeft: '68px',
                marginTop: '10px'
              },
              uiImg: 'ui_order_list'
            }, {
              active: false,
              icon: require('../assets/comm/icon_invoice.png'),
              iconActive: require('../assets/comm/icon_invoice_active.png'),
              title: '线上申请开票',
              style: {
                // margin-top: -21px;
                marginTop: '-30px'
              },
              uiImg: 'ui_invoice'
            }, {
              active: false,
              icon: require('../assets/comm/icon_coupon.png'),
              iconActive: require('../assets/comm/icon_coupon_active.png'),
              title: '优惠券',
              style: {
                // margin-left: 161px;margin-top: 77px;
                marginLeft: '48px',
                marginTop: '10px'
              },
              uiImg: 'ui_coupon'
          }]
        ]
      } else {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/comm_banner.png'
      }
    })
  },
  data() {
    return {
      qrcodeSize: 117,
      distance: 12,
      timer: undefined,
      nowIcon: {
        idx: 0,
        uiImg: 'ui_site_search'
      },

      submitDisabled: false,
      visible: false,
      ruleForm: {
        name: '',
        phone: '',
        address: '',
        province: '',
        city: '',
        context: [],
        mind: ''
      },
      rules: {
        name: [{ required: true, message: '请填写您的名字', trigger: ['blur', 'change'] }],
        phone: [{ required: true, message: '请填写您的联系电话', trigger: ['blur', 'change'] }],
        // province, city
        address: [{ required: true, message: '请选择您的城市信息', trigger: ['blur', 'change'] }],
        // context
        context: [{ required: true, message: '请选择您当前情况或期望相符的描述', trigger: ['blur', 'change'] }],
        mind: [{ required: true, message: '请留下您宝贵的想法', trigger: ['blur', 'change'] }]
      },

      // 城市选项
      options: geo,

      // 现状或期望
      expectOp: ['我要投资基建', '我有充电需求的车辆资源', '我有建设充电站的场地资源', '我要充电站，想改善运营现状', '我有满足建设充电站的电力容量资源', '其他'],

      bannerUrl: process.env.VUE_APP_OSS_URL + '/comm_banner.png',

      showItem: [
        {
          icon: require('../assets/comm/comm_web.png'),
          title: '充电管理后台'
        }, {
          icon: require('../assets/comm/comm_evse.png'),
          title: '桩管家',
          tips: '移动化办公，随时随地处理场站问题',
          cnt: process.env.VUE_APP_OSS_URL + '/comm/evse_mgm.png',
          qrItem: [{
            qrcode: process.env.VUE_APP_OSS_URL + '/comm/evse_mgm_qrcode.png',
            desc: '微信扫一扫，场站管理掌控在手'
          }]
        }, {
          icon: require('../assets/comm/comm_app.png'),
          title: '用户端扫码充电',
          qrItem: [{
            qrcode: process.env.VUE_APP_OSS_URL + '/comm/wx-mgm.png',
            desc: '任我充微信公众号'
          }, {
            qrcode: process.env.VUE_APP_OSS_URL + '/comm/ali_lite.png',
            desc: '任我充支付宝小程序'
          }, {
            qrcode: process.env.VUE_APP_OSS_URL + '/comm/app_ios.png',
            desc: '任我充天下行IOS',
            toUrl: 'https://charge.renwochong.com/index.html?commId=34474&from=web'
          }, {
            qrcode: process.env.VUE_APP_OSS_URL + '/comm/app_android.png',
            desc: '任我充天下行安卓',
            toUrl: 'https://charge.renwochong.com/index.html?commId=34474&from=web'
          }]
        }
      ],

      // UI 页面图示
      phoneFrameworkImg: require('../assets/comm/phone_framework.png'),
      phoneImgList: [
        // require('../assets/comm/ui_site_search.png'),
        // require('../assets/comm/ui_site_detail.png'),
        // require('../assets/comm/ui_pay_way.png'),
        // require('../assets/comm/ui_coupon.png'),
        // require('../assets/comm/ui_invoice.png'),
        // require('../assets/comm/ui_order_list.png'),
        // require('../assets/comm/ui_order_detail.png'),
        // require('../assets/comm/ui_charger_monitor.png')
        process.env.VUE_APP_OSS_URL + '/comm/ui_site_search.png',
        process.env.VUE_APP_OSS_URL + '/comm/ui_site_detail.png',
        process.env.VUE_APP_OSS_URL + '/comm/ui_pay_way.png',
        process.env.VUE_APP_OSS_URL + '/comm/ui_coupon.png',
        process.env.VUE_APP_OSS_URL + '/comm/ui_invoice.png',
        process.env.VUE_APP_OSS_URL + '/comm/ui_order_list.png',
        process.env.VUE_APP_OSS_URL + '/comm/ui_order_detail.png',
        process.env.VUE_APP_OSS_URL + '/comm/ui_charger_monitor.png',

      ],
      activePhoneImg: 'ui_site_search',

      // uiImg: process.env.VUE_APP_OSS_URL + '/comm/ui_site_search.png',

      // 充电管理平台
      webCnt: [
        ['商户营收概览', '场站管理', '充电数据监控', '订单查询/汇总'],
        {
          centerImg: require('../assets/comm/computer_web.png'),
          uiList: [
            {
              img: process.env.VUE_APP_OSS_URL + '/comm/web_1.png',
              pos: 'left-top'
            }, {
              img: process.env.VUE_APP_OSS_URL + '/comm/web_2.png',
              pos: 'right-top'
            }, {
              img: process.env.VUE_APP_OSS_URL + '/comm/web_3.png',
              pos: 'left-bottom'
            }, {
              img: process.env.VUE_APP_OSS_URL + '/comm/web_4.png',
              pos: 'right-bottom'
            }
          ]
        },
        ['多维报表查看', '用户管理', '充电卡/车辆管理', '商户T+0实时转账']
      ],

      // UI/ICON
      iconList: [
        [{
            active: true,
            icon: require('../assets/comm/icon_site_search.png'),
            iconActive: require('../assets/comm/icon_site_search_active.png'),
            title: '地图找桩',
            style: {
              // margin-left: 182px;
              marginLeft: '182px'
            },
            uiImg: 'ui_site_search'
          }, {
            active: false,
            icon: require('../assets/comm/icon_site_detail.png'),
            iconActive: require('../assets/comm/icon_site_detail_active.png'),
            title: '场站详情',
            style: {
              // margin-top: 52px;
              marginTop: '10px'
            },
            uiImg: 'ui_site_detail'
          }, {
            active: false,
            icon: require('../assets/comm/icon_pay_way.png'),
            iconActive: require('../assets/comm/icon_pay_way_active.png'),
            title: '充电结算方式',
            style: {
              // margin-left: 215px;margin-top: -21px;
              marginLeft: '215px',
              marginTop: '-30px'
            },
            uiImg: 'ui_pay_way'
          }, {
            active: false,
            icon: require('../assets/comm/icon_charger_monitor.png'),
            iconActive: require('../assets/comm/icon_charger_monitor_active.png'),
            title: '充电中数据监控',
            style: {
              // margin-left: 71px;margin-top: 79px;
              marginLeft: '71px',
              marginTop: '26px'
            },
            uiImg: 'ui_charger_monitor'
        }], [{
            active: false,
            icon: require('../assets/comm/icon_order_detail.png'),
            iconActive: require('../assets/comm/icon_order_detail_active.png'),
            title: '充电订单详情',
            style: {
              // margin-left: 43px;
              marginLeft: '43px'
            },
            uiImg: 'ui_order_detail'
          }, {
            active: false,
            icon: require('../assets/comm/icon_order_list.png'),
            iconActive: require('../assets/comm/icon_order_list_active.png'),
            title: '历史充电订单',
            style: {
              // margin-left: 223px;margin-top: 71px;
              marginLeft: '223px',
              marginTop: '10px'
            },
            uiImg: 'ui_order_list'
          }, {
            active: false,
            icon: require('../assets/comm/icon_invoice.png'),
            iconActive: require('../assets/comm/icon_invoice_active.png'),
            title: '线上申请开票',
            style: {
              // margin-top: -21px;
              marginTop: '-30px'
            },
            uiImg: 'ui_invoice'
          }, {
            active: false,
            icon: require('../assets/comm/icon_coupon.png'),
            iconActive: require('../assets/comm/icon_coupon_active.png'),
            title: '优惠券',
            style: {
              // margin-left: 161px;margin-top: 77px;
              marginLeft: '161px',
              marginTop: '26px'
            },
            uiImg: 'ui_coupon'
        }]
      ]
    }
  },
  methods: {
    selectIconTimer() {
      let that = this
      this.timer = setInterval(() => {
        let idx = that.nowIcon.idx ? that.nowIcon.idx : 0
        var uiImg = that.nowIcon.uiImg ? that.nowIcon.uiImg : 'ui_site_search'

        let icon
        for (var i = 1; i < that.iconList[idx].length; i++) {
          if (that.iconList[idx][i - 1].uiImg.indexOf(uiImg) != -1) {
            icon = that.iconList[idx][i]
          }
        }

        if (!icon) {
          idx = idx === 0 ? 1 : 0
          icon = that.iconList[idx][0]
        }

        that.selectIcon(idx, icon)
      }, 2000)
    },

    selectIcon(idx, icon) {
      this.iconList.forEach(i => i.forEach(n => n.active = false))
      this.iconList[idx].forEach(i => i.active = i.title === icon.title)
      this.activePhoneImg = icon.uiImg

      this.nowIcon = {
        idx: idx,
        uiImg: icon.uiImg
      }
    },

    commLogin(val,id) {
	switch(val) {
		case 1:
			window.open(process.env.VUE_APP_MANAGER)
			break
		case 2:
			window.open(process.env.VUE_APP_PV)
			break
		case 3:
			window.open(process.env.VUE_APP_ESS)
			break
		case 4:
			window.open(process.env.VUE_APP_MG)

	}
	document.getElementById(id).blur()
      // let url = 'https://manage.renwochong.com/'
      // window.open(process.env.VUE_APP_MANAGER)
    },

    intention() {
      this.visible = true
    },

    submitIntentionForm() {
      this.submitDisabled = true
      this.$refs['intentionForm'].validate(valid => {
        if (valid) {
          // 提交意向(远程接口)
          let data = { ...this.ruleForm }
          http({
            url: '/ant/api/website/addCusExpect',
            method: 'post',
            data
          })
          .then(res => {
            console.log(res)
            ElMessage({
              message: '信息已经提交成功, 我们专员将尽快与您联系',
              type: 'success',
              duration: 3 * 1000
            })

            this.formReset()
          })
          .finally(() => {
            this.visible = false
            this.submitDisabled = false
          })
        } else {
          this.submitDisabled = false
          return false
        }
      })
    },

    handleClose(done) {
      this.formReset()
      done()
    },

    formReset() {
      this.$refs['intentionForm'].resetFields()
    }
  }
}
</script>

<style scoped>
.commercial, .banner-img {
  width: 100%;
}

.banner-container {
  width: 100%;
  position: relative;
}

.banner-cnt-container {
  position: absolute;
}

.banner-cnt_title {
  font-family: MF FangHei (Noncommercial);
  color: #FEFEFE;
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.51);
}

.p_banner-btn,
.banner-cnt_btn {
  background: #FF786A;
  box-shadow: 0px 1px 7px 0px #696969;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FEFEFE;
  border: 1px solid #FF786A;
  position: absolute;
  right: 0;
}

.banner-cnt_btn {
  top: 0;
}

.banner-btn {
  position: absolute;
  background: #6BD2A2;
  box-shadow: 0px 2px 21px 0px #696969;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFF;
  border: 1px solid #6BD2A2;
}

.evse-mgm-img {
  width: 51%;
  /* height: 540px; */
}

.evse-mgm-tips {
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999;
  line-height: 30px;
}

.applet-ui-container {
  width: 100%;
  height: 623px;
  background: #EBF4F0;
}

.ui-img {
  width: 271px;
  /* height: 560px; */
}

.icon-info {
  width: 166px;
  cursor: pointer;
  text-align: center;
}

.icon-img {
  width: 85px;
}

.icon-title {
  font-size: 21px;
}

.icon-active-color {
  color: #10CE74;
}

.icon-inactive-color {
  color: #BAC1BD;
}

.icon-container {
  height: 500px;
}

.dialog-tips {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FF9494;
  line-height: 72px;
}

.charger-web-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.charger-web-menu {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}

.charger-web-img {
  position: relative;
}

.web-img-item {
  position: relative;
  position: absolute;
  height: calc(100% / 2 - 10px);
  width: calc(100% / 2 - 12px);
}

.web-img-center {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)
}

.web-img-item-category {
  background: #EBF4F0;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2F4050;
}

.app-page-container {
  height: 100%;
  margin: 0 130px;
}

.p_banner-btn {
  display: none;
}

.case-item-container {
  display: none;
  width: 90%;
  font-size: 12px;
  margin: 0 auto;
  /* border: 1px solid red; */
  height: 81px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  line-height: 27px;
}

.case-item {
  float: left;
  /* width: calc(86% / 3); */
  width: 108px;
}

.case-item::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  /* transform: rotateZ(45deg)skew(30deg,30deg); */
  background: #56BE8E;
  margin-right: 2px;
}

.bg1{
	background: #56BE8E;
}
.bg2{
	background: #2DA4FF;
}
.bg3{
	background: #FC7D3F;
}
.bg4{
	background: #FF786A;
}
</style>
