export default {
    param_1: '我们的优势',
    param_2: '解决方案',
    param_3: '合作伙伴',
    param_4: '云监控',
    param_5: '实时状态监控、即时告警通知、<br />可视化趋势呈现',
    param_6: '智慧运维',
    param_7: '即时告警通知、智能运维工单系统、巡检到期<br />自动提醒、运维考勤系统',
    param_8: '云操作',
    param_9: '桩配置远程管理、设备批量远程升级、<br />全自动定时启停任务',
    param_10: '大客户<br />专属系统',
    param_11: '针对VIP客户、大企业客户，为其量身定做开发了子<br />系统，管理员可以在子系统进行在线充值、对账，<br />人员车辆管理等操作',
    param_12: '云管理',
    param_13: '云端场站功率智能控制、PC端/手机端多形式展现、<br />黑/白名单+停车超时组合管控',
    param_14: '渠道引流',
    param_15: '既可以分享平台的站点到其他互联网平台；<br />也可以将其他运营商平台的站点纳入到系统中来',
    param_16: '智慧运营',
    param_17: '预付费/后付费定制结算、优惠券/充送/服务费<br />折扣等营销工具、对接主流互联网充电服务平<br />台共享推广',
    param_18: '友好的<br />交互界面',
    param_19: '简单、人性化的交互界面设计，符合用户使用<br />习惯，上手即可懂、即可操作',
    param_20: '公交场站解决方案',
    param_21: '物流场站解决方案',
    param_22: '大巴车解决方案',
    param_23: '公共场站解决方案',
    param_24: '商业住宅小区解决方案',
    param_25: '出租车/网约车解决方案',

    param_26: '累计充电量',
    param_27: '累计碳减排',
    param_28: '节油量',
    param_29: '吨',
    param_30: '升'
}