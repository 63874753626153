<template>
  <header class="header">
    <div class="row">
      <!--logo-->
      <img class="logo-img" alt="" src="../assets/logo.png" v-if="isChinese()">
      <img class="logo-img-en" alt="" src="../assets/logo_en.png" v-else>
      <!--菜单-->
      <div class="item-container nav-menu-container">
        <template v-for="item in itemList" :key="item">
          <div class="item" v-if="item.isShow" @click="selectItem(item);$emit('topicSelecedEvent', item.title)">
            <span :class="item.active ? 'active-color' : 'normal-color'">{{ item.title }}</span>
            <div class="h-item-bottom_line" v-if="item.active"></div>
          </div>
        </template>
      </div>
      <!--折叠面板-->
      <div class="menu-dropdown">
        <el-tooltip
            placement="bottom"
            effect="dark"
            :width="200"
            trigger="click">
          <i class="el-icon-arrow-down el-icon-s-grid" style="font-size: 20px;"/>
          <template #content>
            <template v-for="item in itemList" :key="item">
              <div class="item" v-if="item.isShow"
                   @click="selectItem(item);$emit('topicSelecedEvent', item.title)">
                <span :class="item.active ? 'active-color' : 'normal-color'">{{ item.title }}</span>
              </div>
            </template>
          </template>
        </el-tooltip>
      </div>

      <!--语言切换-->
      <div class="lang" @click="toggleLang">
        <img src="../assets/lang.png" alt="" class="lang-img">
        <div class="title">{{ isChinese() ? 'EN' : '中文' }}</div>
      </div>

    </div>
  </header>
</template>
<script>
import bus from '@/bus'
import {isChinese} from "../utils";
import {ElTooltip} from "element-plus";

export default {
  name: 'app-header',
  components: {ElTooltip},
  data() {
    return {
      itemList: [
        {
          title: this.$t('menu.home'),
          active: true,
          isShow: true
        }, {

          title: this.$t('menu.product'),
          active: false,
          isShow: true
        }, {

          title: this.$t('menu.solutions'),
          active: false,
          isShow: true
        }, {

          title: this.$t('menu.service'),
          active: false,
          isShow: !isChinese()
        }, {

          title: '商户',
          active: false,
          isShow: isChinese()
        }, {

          title: '企业',
          active: false,
          isShow: isChinese()
        }, {
          title: this.$t('menu.aboutUs'),
          active: false,
          isShow: true
        }
      ],
    }
  },
  methods: {
    isChinese,
    selectItem(item) {
      this.itemList.forEach(i => {
        i.active = i.title === item.title
      })
    },
    // 语言切换
    toggleLang() {
      const lang = localStorage.getItem('lang') || 'zh'
      localStorage.setItem('lang', lang === 'zh' ? 'en' : 'zh')
      location.reload()
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    bus.on('HOME_SELECT_SOLUTION', key => {
      this.selectItem(this.itemList[2]);
    })
  }
}
</script>
<style scoped>
.header {
  width: 100%;
  background: #272727;

  color: #FFF;
  font-size: 20px;
}

:deep(.el-row) {
  height: 100%;
}

.lang {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lang .lang-img {
  width: 20px;
  height: 20px;
}

.lang .title {
  font-size: 13px;
  color: #FFFFFF;
  margin-left: 10px;
}


.item-container {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.item {
  cursor: pointer;
  font-size: 15px;
  line-height: 30px;
  padding: 0 15px;
}

.active-color {
  color: #80F401;
}

.normal-color {
  color: #FFF;
}

.nav-manu-btn {
  background-color: #56BE8E;
  border-color: #56BE8E;
}


.h-item-bottom_line {
  float: left;
  width: 90%;
}
</style>
